import { thunk } from 'redux-thunk'
import { combineReducers } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import { initCustomReducers } from './setup'

// Reducers
import coreReducer from './core/utils/reducer'

const customReducers = initCustomReducers()
const store = configureStore({
  reducer: combineReducers({
    core: coreReducer.reducer,
    ...customReducers
  }),
  middleware: (getDefaultMiddleware) => {
    return [thunk]
  }
})

export default store
