import { Checkbox, Col, Form, Input, Modal, Space, Spin, message } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import { createBooking, createNetCashTransaction, getNetCashTransaction, sendOTP } from '../../utils/utils'
import { handleError } from '../../../lib/utils'
import HomeVisitCheckin from './home-visit-checkin'
import { readConfig } from '../../../Admin/config/utils/utils'
import bookingsReducer from '../../utils/booking-reducer'
import { executeBooking, registerBooking } from '../../utils/bpm-utils'
import { Radio } from 'antd'
import { findAgiliteUser } from '../../../Auth/utils/utils'
import MobileNav from './mobile-nav'
import CustomRow from '../../../reusable-components/CustomRow'
import BookingSummary from './widgets/booking-summary'
import { deviceDetect } from 'react-device-detect'
import CustomButton from '../../../reusable-components/CustomButton'
import { handleVerifyAvailability } from '../../utils/lib'
import ServicesEnums from '../../../Admin/services/utils/enums'
import { readClinics } from '../../../Admin/clinics/utils/utils'

const BookingFinalize = ({
  prevStep,
  bookingDetails,
  setBookingDetails,
  nextStep,
  isHomeVisit,
  isVirtualVisit,
  patient,
  isReceptionBooking,
  setReceptionLoadIndicator,
  onAfterSuccessfulBooking
}) => {
  const dispatch = useDispatch()
  const payNowFormRef = useRef()
  // For checkin Questionairre
  const [checkinConfig, setCheckinConfig] = useState()
  const [currentSelection, setCurrentSelection] = useState()
  const [patientSelection, setPatientSelection] = useState([])
  const [patientInput, setPatientInput] = useState()
  const [questionarreLoading, setQuestionarreLoading] = useState(true)
  const [terms, setTerms] = useState()
  const [termsAgreedTo, setTermsAgreedTo] = useState(isReceptionBooking)
  const [additionalInfo, setAdditionalInfo] = useState()
  const [clientForm] = Form.useForm()
  const [displayTerms, setDisplayTerms] = useState()
  const [loading, setLoading] = useState()
  const authState = useSelector((state) => state.auth)
  const state = useSelector((state) => state)
  const serviceInfo =
    state.services.data.find((item) => item._id === bookingDetails.service) ||
    state.virtualServices.data.find((item) => item._id === bookingDetails.service)
  const [mainPatient, setMainPatient] = useState(patient)
  const [isDependant, setIsDependant] = useState(false)
  const [verifying, setVerifying] = useState(false)
  const [netCashTransaction, setNetCashTransaction] = useState()
  const [netCashTransactionSet, setNetCashTransactionSet] = useState(false)

  useEffect(() => {
    if (mainPatient) {
      if (!mainPatient.phoneNumber && !mainPatient.email) {
        handleFindMainPatient()
      }
    }

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (netCashTransaction && netCashTransaction.status === 'pending') {
      setNetCashTransactionSet(true)
    }
  }, [netCashTransaction])

  useEffect(() => {
    if (netCashTransactionSet) {
      payNowFormRef.current.submit()
    }
    // eslint-disable-next-line
  }, [netCashTransactionSet])

  const handleFindMainPatient = async () => {
    let tmpMainPatient = null
    try {
      tmpMainPatient = await findAgiliteUser({ dependants: { $in: [mainPatient._id] } })

      if (tmpMainPatient) {
        setIsDependant(true)
        setMainPatient(tmpMainPatient)
      }
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  const getCheckinConfig = async () => {
    let tmpConfig = await readConfig()
    setCheckinConfig(tmpConfig[0].checkin)
    setQuestionarreLoading(false)
  }

  useEffect(() => {
    getCheckinConfig()
    handleSetTerms()
    // eslint-disable-next-line
  }, [])

  const handleSetTerms = () => {
    const tmpTerms = authState.terms.find((i) => {
      if (i.linkedPractice) {
        return i.linkedPractice === bookingDetails.clinicTermsRef
      } else {
        return null
      }
    })
    if (tmpTerms) {
      setTerms(tmpTerms)
    } else {
      setTerms(authState.terms[0])
    }
  }

  const handleGetTransaction = async (id, intervalId) => {
    let response = null

    try {
      response = await getNetCashTransaction(id)

      if (response.status === 'success') {
        clearInterval(intervalId)
        confirmBooking()
      } else if (response.status === 'failed') {
        clearInterval(intervalId)
        message.error('Payment Failed. Please try again.', 10000)
      }

      setNetCashTransaction(response)
    } catch (e) {
      clearInterval(intervalId)
      message.error(handleError(e, true))
    }
  }

  const validateBooking = () => {
    if (isHomeVisit || isVirtualVisit) {
      if (!currentSelection) {
        setLoading(false)
        if (setReceptionLoadIndicator) {
          setReceptionLoadIndicator(false)
        }
        return message.error('Please select a reason for your visit')
      }

      switch (currentSelection.doctorsText) {
        case 'Sick':
          if (patientSelection.length < 1) {
            setLoading(false)
            if (setReceptionLoadIndicator) {
              setReceptionLoadIndicator(false)
            }
            return message.error('Please select at least one Symptom')
          }
          break
        case 'Injury':
          if (patientSelection.length < 1) {
            setLoading(false)
            setReceptionLoadIndicator(false)
            return message.error('Please select at least one Injury Type')
          }
          break
        case 'Skin':
          if (patientSelection.length < 1) {
            setLoading(false)
            if (setReceptionLoadIndicator) {
              setReceptionLoadIndicator(false)
            }
            return message.error('Please select at least one Skin Ailment Type')
          }
          break
        case 'Aesthetics':
          if (patientSelection.length < 1) {
            setLoading(false)
            if (setReceptionLoadIndicator) {
              setReceptionLoadIndicator(false)
            }
            return message.error('Please select at least one Interest')
          }
          break
        case 'Sports Medicine':
          if (patientSelection.length < 1) {
            setLoading(false)
            if (setReceptionLoadIndicator) {
              setReceptionLoadIndicator(false)
            }
            return message.error('Please select at least one Option')
          }
          break
        default:
          break
      }
    }

    if (isVirtualVisit) {
      handlePayment()
    } else {
      confirmBooking()
    }
  }

  const handlePayment = async () => {
    let transaction = null
    // let tmpSignature = null
    let tmpTransactionIntervalId = null

    try {
      transaction = await createNetCashTransaction(bookingDetails.userRef, serviceInfo.rate, serviceInfo.name)
      setNetCashTransaction(transaction)

      tmpTransactionIntervalId = setInterval(
        () => handleGetTransaction(transaction._id, tmpTransactionIntervalId),
        5000
      )
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  const confirmBooking = async () => {
    let tmpCheckinData = {}
    let otp = null
    let tmpBookings = []
    let tmpDashBoardBookings = []
    let processRecord = null
    let processUser = null
    let payload = JSON.parse(JSON.stringify(bookingDetails))
    let clinicData = null
    const bpmKey = clinicData?.bpmKey || 'booking_process'

    if (setReceptionLoadIndicator) {
      setReceptionLoadIndicator(false)
    }
    setLoading(true)

    if (isVirtualVisit) {
      let tmpResult = null
      tmpResult = await readClinics({ _id: bookingDetails.clinicRef }, isVirtualVisit)
      clinicData = tmpResult[0]
    } else {
      clinicData = state.clinics.data.find((i) => i._id === bookingDetails.clinicRef)
    }

    try {
      payload.medicalProfRef = payload.medicalProf._id
      payload = { ...payload, ...additionalInfo, status: { ...bookingDetails.status, checkedIn: true } }

      if (isHomeVisit || isVirtualVisit) {
        tmpCheckinData.ailment = currentSelection.doctorsText

        if (patientSelection.length > 0) {
          tmpCheckinData.patientSelection = patientSelection
        }

        if (patientInput) {
          tmpCheckinData.patientInput = patientInput
        }

        payload.checkinData = {
          ...tmpCheckinData
        }
      }

      if (!isHomeVisit) {
        if (isReceptionBooking) {
          otp = await sendOTP({ ...bookingDetails, ...mainPatient }, patient, isDependant, clinicData.name)
        } else {
          otp = await sendOTP({ ...bookingDetails, ...authState.agiliteUser }, patient, isDependant, clinicData.name)
        }

        payload.otp = otp
        setBookingDetails({ ...bookingDetails, ...payload })
      }

      if (isVirtualVisit) {
        payload.isVirtualVisit = true
      }

      // BPM
      if (patient) {
        processUser = `${authState.agiliteUser.firstName} ${authState.agiliteUser.lastName}`
        payload.userRef = patient._id
        setBookingDetails({ ...bookingDetails, ...payload })
      } else {
        processUser = `${authState.agiliteUser.firstName} ${authState.agiliteUser.lastName}`
      }

      processRecord = await registerBooking(bpmKey, processUser)
      processRecord = await executeBooking(bpmKey, processRecord.recordId, 'submit', processUser, processRecord.key)

      if (isHomeVisit && !isVirtualVisit) {
        // If it is a Home Visit and not a Virtual Consultation, we need to skip the checkin step
        processRecord = await executeBooking(bpmKey, processRecord.recordId, 'submit', processUser, processRecord.key)
      } else if (isVirtualVisit) {
        // If Virtual Consultation, we need to skip the checkin and Nurse step
        processRecord = await executeBooking(
          bpmKey,
          processRecord.recordId,
          'submit_to_doctor',
          processUser,
          processRecord.key
        )
      }

      let newBooking = await createBooking({
        ...payload,
        processRef: processRecord.recordId,
        status: processRecord.processStage
      })

      // Update Bookings State
      if (state.auth.agiliteUser.extraData.role.type === 'patient') {
        // Set temporary booking states to whatever it was previously plus the new booking
        tmpBookings = [...state.bookings.data, newBooking]
        tmpDashBoardBookings = [...state.bookings.dashboardData, newBooking]
        // Sort Bookings by date
        tmpBookings.sort(
          (a, b) =>
            new Date(dayjs(b.bookingDate).set('hours', b.startTime.slice(0, 2)).set('minutes', b.startTime.slice(-2))) -
            new Date(dayjs(a.bookingDate).set('hours', a.startTime.slice(0, 2)).set('minutes', a.startTime.slice(-2)))
        )
        // update the main bookings state for patient this will be in two plces:
        dispatch(bookingsReducer.actions.setRecords(tmpBookings))
        dispatch(bookingsReducer.actions.setDashboardRecords(tmpDashBoardBookings))
      } else {
        tmpDashBoardBookings = [...state.bookings.dashboardData, newBooking]
        dispatch(bookingsReducer.actions.setDashboardRecords(tmpDashBoardBookings))
      }

      message.success('Booking Successful')

      // Show next facet
      if (isReceptionBooking) {
        onAfterSuccessfulBooking()
      } else {
        nextStep()
      }
    } catch (e) {
      handleError(e)
      message.error('Unable to make booking')
    }

    if (setReceptionLoadIndicator) {
      setReceptionLoadIndicator(false)
    }
    setLoading(false)
  }

  const handleData = () => {
    setAdditionalInfo(clientForm.getFieldsValue())
  }

  const handleChange = (tag, checked) => {
    const nextSelectedTags = checked ? [...patientSelection, tag] : patientSelection.filter((t) => t !== tag)
    clientForm.setFieldsValue({
      patientSelection: []
    })
    setPatientSelection(nextSelectedTags)
  }

  return (
    <>
      {terms && !isReceptionBooking ? (
        <Modal
          title={state.clinics.data.find((i) => i._id === bookingDetails.clinicTermsRef).practiceName}
          open={displayTerms}
          onOk={() => {
            setDisplayTerms(false)
          }}
          onCancel={() => {
            setDisplayTerms(false)
          }}
        >
          <small>
            Last updated: <b>{dayjs(terms.dateModified).format('DD MMMM YYYY')}</b>
          </small>
          <div dangerouslySetInnerHTML={{ __html: terms.data }} />
        </Modal>
      ) : undefined}
      <Form
        onFinishFailed={() => {
          message.error('Please ensure all required information has been provided.')
        }}
        onFinish={() => {
          validateBooking()
        }}
        layout='vertical'
        form={clientForm}
        onFieldsChange={() => {
          handleData(clientForm.getFieldsValue())
        }}
      >
        <CustomRow className='basic-card' justify='center'>
          {isReceptionBooking ? undefined : (
            <Col span={24}>
              <CustomRow>
                <Col span={24}>
                  <BookingSummary
                    booking={bookingDetails}
                    patient={patient}
                    serviceInfo={serviceInfo}
                    isVirtualVisit={isVirtualVisit}
                  />
                </Col>
              </CustomRow>
            </Col>
          )}
          {serviceInfo.type !== ServicesEnums.labels.VITALITY_HEALTH_CHECK && !isVirtualVisit ? (
            <Col span={24}>
              <CustomRow>
                <Col span={24}>
                  <p>
                    <b>Please provide us with a brief reason for your booking:*</b>
                  </p>
                </Col>
                <Col span={24}>
                  <Form.Item
                    style={{ margin: 0 }}
                    rules={[{ required: true, message: 'Please provide a reasoning for your booking' }]}
                    name='chiefComplaint'
                  >
                    <Input
                      style={{ padding: 12 }}
                      placeholder='e.g I am feeling dizzy. I hit my head. I cut myself.'
                      maxLength={50}
                    />
                  </Form.Item>
                </Col>
              </CustomRow>
            </Col>
          ) : undefined}
          {!isVirtualVisit ? (
            <Col span={24}>
              <CustomRow>
                <Col span={24}>
                  <p>
                    <b>Select your method of payment:*</b>
                  </p>
                </Col>
                <Col span={24}>
                  <Form.Item
                    style={{ margin: 0 }}
                    rules={[{ required: true, message: 'Please select a payment method' }]}
                    name='paymentMethod'
                  >
                    <Radio.Group options={bookingDetails.paymentMethods} optionType='button' buttonStyle='solid' />
                  </Form.Item>
                </Col>
              </CustomRow>
            </Col>
          ) : undefined}
          <Col span={24}>
            {isHomeVisit || isVirtualVisit ? (
              <>
                {questionarreLoading ? (
                  <Spin />
                ) : (
                  <>
                    <HomeVisitCheckin
                      loading={loading}
                      handleChange={handleChange}
                      bookingService={serviceInfo.checkin?.length > 0 ? serviceInfo.checkin : checkinConfig}
                      currentSelection={currentSelection}
                      setCurrentSelection={setCurrentSelection}
                      patientSelection={patientSelection}
                      setPatientSelection={setPatientSelection}
                      patientInput={patientInput}
                      setPatientInput={setPatientInput}
                    />
                  </>
                )}
              </>
            ) : null}
          </Col>
          <Col span={24}>
            <CustomRow>
              {!isVirtualVisit && !isReceptionBooking ? (
                <Col span={24} style={{ marginBottom: 10 }}>
                  <>
                    <p>
                      <b>NOTE:</b>
                    </p>
                    <p>
                      If you do not <b>cancel</b> your appointment within <b>3 hours</b> of booking time, you will be
                      liable for a <b>R500</b> nurse and doctor's fee.
                    </p>
                  </>
                </Col>
              ) : undefined}
              {isReceptionBooking ? undefined : (
                <Col span={24}>
                  <center>
                    <Space wrap>
                      <Checkbox
                        onChange={(e) => {
                          setTermsAgreedTo(e.target.checked)
                        }}
                      ></Checkbox>{' '}
                      <div>
                        I Agree to the{' '}
                        <u
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setDisplayTerms(true)
                          }}
                        >
                          Terms & Conditions
                        </u>
                      </div>
                    </Space>
                  </center>
                </Col>
              )}
              {deviceDetect().isMobile ? undefined : (
                <>
                  <Col span={24}>
                    <center>
                      <CustomButton
                        text='Confirm'
                        type='secondary'
                        loading={loading || verifying}
                        style={{ width: '100%' }}
                        onClick={async () => {
                          if (!termsAgreedTo) return message.error('Please agree to the terms and conditions')
                          try {
                            await handleVerifyAvailability(bookingDetails, setVerifying)
                            clientForm.submit()
                          } catch (e) {
                            message.error(handleError(e))
                          }
                        }}
                      />
                    </center>
                  </Col>
                  {isReceptionBooking ? undefined : (
                    <Col span={24}>
                      <center>
                        <b
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            if (!loading && !verifying) {
                              prevStep()
                            }
                          }}
                        >
                          Back
                        </b>
                      </center>
                    </Col>
                  )}
                </>
              )}
            </CustomRow>
          </Col>
        </CustomRow>
      </Form>
      <MobileNav
        isFinalizeStep={true}
        loading={loading}
        bookingDetails={bookingDetails}
        prevStep={prevStep}
        nextStep={nextStep}
        finalizeForm={clientForm}
        finalizeFormDisabled={!termsAgreedTo}
        isVirtualVisit={isVirtualVisit}
      />
      <form
        action='https://paynow.netcash.co.za/site/paynow.aspx'
        method='post'
        name='payNowForm'
        target='_blank'
        ref={payNowFormRef}
        style={{ display: 'none' }}
      >
        <input id='m1' name='m1' type='hidden' value={authState.netCashConfig.service_key} />
        <input id='m2' name='m2' type='hidden' value={authState.netCashConfig.netcash_key} />
        <input id='m4' name='m4' type='hidden' value={netCashTransaction?._id} />
        <input id='p2' name='p2' type='hidden' value={`${state.auth.agiliteUser._id}-${netCashTransaction?._id}`} />
        <input id='p3' name='p3' type='hidden' value='Virtual Consultation' />
        <input id='p4' name='p4' type='hidden' value={serviceInfo.rate.toString()} />
        <input id='budget' name='Budget' type='hidden' value='N' />
        <input type='hidden' name='m5' value='' />
        <input type='hidden' name='m6' value='' />
        <input type='submit' value='Confirm' />
      </form>
    </>
  )
}

export default BookingFinalize
