import React, { useState, useEffect, useRef } from 'react'
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  theme,
  Select,
  Popconfirm,
  Switch,
  message,
  Space,
  Radio,
  Modal,
  Tooltip,
  Checkbox,
  Collapse,
  Table
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { geocodeByAddress } from 'react-google-places-autocomplete'
/* import { UploadOutlined } from '@ant-design/icons' */

import coreReducer from '../../../../core/utils/reducer'
import Theme from '../../../../core/utils/theme'
import Templates from '../utils/templates'
import GoogleAddress from '../../../reusable-components/GoogleAddress'
import { createClinic, readBPMProfiles, updateClinic } from '../utils/utils'
import { ContainerCard } from '../../../reusable-components/AgiliteCards'
import { allTrim, handleError, specialFieldValidation } from '../../../lib/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { sortBy } from 'lodash'
import CustomRow from '../../../reusable-components/CustomRow'
import CoreEnums from '../../../../core/utils/enums'
import BillingMacroForm from './billing-macro-form'

const ClinicsForm = ({ data, isNewRecord, refreshView, isWizard, handleWizardNextPrevious }) => {
  const dispatch = useDispatch()
  const coreState = useSelector((state) => state.core)
  const [loading, setLoading] = useState(false)
  const [deactivatePrompt, setDeactivatePrompt] = useState(false)
  const [specialFormState, setSpecialFormState] = useState([])
  const [physicalAddress, setPhysicalAddress] = useState(data?.physicalAddress)
  const inputRef = useRef(null)
  const [image, setImage] = useState(data?.clinicLogo ? data.clinicLogo : coreState.entity?.entityIcon)
  const [bpmProfiles, setBpmProfiles] = useState([])

  // Macros
  const [macros, setMacros] = useState(data?.billingMacros ? data.billingMacros : [])
  const [macro, setMacro] = useState({ isActive: true, name: '', items: [] })
  const [macroIndex, setMacroIndex] = useState(null)
  const [macroFormOpen, setMacroFormOpen] = useState(false)
  const [isNewMacro, setIsNewMacro] = useState(false)

  const [clientForm] = Form.useForm()

  useEffect(() => {
    const tmpSpecialFormState = []

    for (const [key, value] of Object.entries(data)) {
      tmpSpecialFormState.push({ key: key, value: value })
    }
    setSpecialFormState(tmpSpecialFormState)

    handleGetBpmProfiles()
    // eslint-disable-next-line
  }, [])

  const handleGetBpmProfiles = async () => {
    let tmpBpmProfiles = []

    try {
      tmpBpmProfiles = await readBPMProfiles()
      tmpBpmProfiles = sortBy(tmpBpmProfiles, ['name'])

      for (const item of tmpBpmProfiles) {
        item.label = item.data.name
        item.value = item.data.key
      }

      setBpmProfiles(tmpBpmProfiles)
    } catch (e) {}
  }

  const handleSubmit = async () => {
    let record = allTrim(clientForm.getFieldsValue())

    try {
      setLoading(true)

      if (isNewRecord) {
        await createClinic({ ...record, clinicLogo: image, billingMacros: macros })
      } else {
        await updateClinic({ _id: data._id, ...record, clinicLogo: image, billingMacros: macros })
      }

      closeTab()

      if (coreState.tourOpen) {
        dispatch(coreReducer.actions.nextTourStep())
      }
    } catch (e) {
      message.error(handleError(e, true))
    }

    setLoading(false)
  }

  const closeTab = () => {
    refreshView()
    dispatch(
      coreReducer.actions.closeTab({
        targetKey: isNewRecord ? `${CoreEnums.tabKeys.CLINICS}_new}` : `${CoreEnums.tabKeys.CLINICS}_${data._id}`,
        removeBreadcrumb: true
      })
    )
  }

  const { token } = theme.useToken()

  const confirmDeactivation = () => {
    clientForm.submit()
  }

  const handleIsActive = (value) => {
    if (data.isActive && !value) setDeactivatePrompt(true)
    else setDeactivatePrompt(false)
  }

  const handleGetAddress = async (address) => {
    try {
      const results = await geocodeByAddress(address)
      clientForm.setFieldValue(Templates.dataModel.physicalAddress.key, results[0].formatted_address)
      setPhysicalAddress(results[0].formatted_address)
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  const handleUploadClick = () => {
    let promptText = null
    let enableCancel = false

    if (data?.clinicLogo) {
      promptText = "Do you want Remove or Change the Clinic's Logo?"
      enableCancel = true
    } else {
      promptText = "Do you want Change the Clinic's Logo?"
      enableCancel = false
    }

    Modal.confirm({
      title: 'Confirmation',
      content: promptText,
      okText: 'Change',
      cancelText: 'Remove',
      onOk: () => inputRef.current?.click(),
      onCancel: () => {
        setImage(coreState.entity.entityIcon)
      },
      cancelButtonProps: { disabled: !enableCancel, danger: true },
      closable: true,
      maskClosable: true,
      className: token.themeControl
    })
  }

  const handleFileChange = async (e) => {
    let file = null

    try {
      if (!e.target.files) {
        return
      }

      file = await readBase64(e.target.files[0])
      setImage(file)
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  const readBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }

  const sectionHeadingStyle = {
    padding: 6,
    borderRadius: 6,
    background: token.colorSecondary,
    color: '#ffffff',
    textAlign: 'center'
  }

  return (
    <ContainerCard title={isNewRecord ? 'Register a New Clinic' : 'Viewing Clinic'}>
      <Row justify='center'>
        <Col xs={24} sm={24} md={20} lg={16} xl={14} xxl={12}>
          <CustomRow>
            <Col span={24}>
              <h2 style={sectionHeadingStyle}>{isNewRecord ? 'New Clinic' : `${data.name}`}</h2>
            </Col>
            <Col span={24}>
              <CustomRow className='basic-card' id='clinic_form'>
                <Col span={24}>
                  <Form
                    onFinish={handleSubmit}
                    name='form'
                    form={clientForm}
                    initialValues={{
                      ...Templates.dataTemplate(),
                      ...data
                    }}
                    layout='vertical'
                  >
                    <CustomRow justify='center'>
                      <Col span={24}>
                        <Collapse defaultActiveKey='general'>
                          <Collapse.Panel key='general' header='General Configuration'>
                            <Row justify='center'>
                              <Col>
                                <Tooltip title='Edit Clinic Logo'>
                                  <input
                                    type='file'
                                    ref={inputRef}
                                    onChange={handleFileChange}
                                    style={{ display: 'none' }}
                                  />
                                  <div style={{ display: 'grid' }}>
                                    {/* eslint-disable-next-line */}
                                    <img
                                      referrerPolicy='no-referrer'
                                      alt='User Image'
                                      style={{ gridColumn: 1, gridRow: 1, borderRadius: '5%', width: 128 }}
                                      src={image}
                                    />
                                    <div
                                      style={{
                                        gridColumn: 1,
                                        gridRow: 1,
                                        boxSizing: 'border-box',
                                        display: 'flex',
                                        justify: 'center',
                                        alignItems: 'center',
                                        width: 128,
                                        position: 'relative',
                                        zIndex: 1,
                                        opacity: 0.4,
                                        borderRadius: '5%',
                                        backgroundColor: token.colorBgMask,
                                        cursor: 'pointer'
                                      }}
                                      onClick={loading ? null : handleUploadClick}
                                    >
                                      <FontAwesomeIcon
                                        color={token.colorWhite}
                                        icon={faEdit}
                                        style={{ width: 128 }}
                                        size='2x'
                                      />
                                    </div>
                                  </div>
                                </Tooltip>
                              </Col>
                            </Row>
                            <Space wrap style={{ marginTop: 20, marginBottom: 10 }}>
                              {Templates.dataModel.isActive.label}
                              <Form.Item
                                style={{ margin: '0' }}
                                name={Templates.dataModel.isActive.key}
                                valuePropName='checked'
                              >
                                <Switch
                                  checkedChildren='Active'
                                  unCheckedChildren='Inactive'
                                  onChange={(e) => {
                                    handleIsActive(e)
                                  }}
                                />
                              </Form.Item>
                            </Space>
                            <br />
                            <Row justify='space-between'>
                              <Col>
                                <Space wrap style={{ marginBottom: '20px' }}>
                                  {Templates.dataModel.clinicBookings.label}
                                  <Form.Item
                                    style={{ margin: '0' }}
                                    name={Templates.dataModel.clinicBookings.key}
                                    valuePropName='checked'
                                  >
                                    <Switch checkedChildren='Yes' unCheckedChildren='No' />
                                  </Form.Item>
                                </Space>
                              </Col>
                              <Col>
                                <Space wrap style={{ marginBottom: '20px' }}>
                                  {Templates.dataModel.homeVisits.label}
                                  <Form.Item
                                    style={{ margin: '0' }}
                                    name={Templates.dataModel.homeVisits.key}
                                    valuePropName='checked'
                                  >
                                    <Switch checkedChildren='Yes' unCheckedChildren='No' />
                                  </Form.Item>
                                </Space>
                              </Col>
                            </Row>
                            <Form.Item
                              name={Templates.dataModel.name.key}
                              label={Templates.dataModel.name.label}
                              rules={[
                                {
                                  required: Templates.dataModel.name.required,
                                  message: Templates.dataModel.name.validationMsg
                                }
                              ]}
                            >
                              <Input
                                onChange={(e) => {
                                  specialFieldValidation(clientForm, specialFormState, setSpecialFormState, {
                                    key: 'name',
                                    event: e,
                                    validationConfig: {
                                      letters: { allowed: true, onlyCaps: false },
                                      numbers: false,
                                      spaces: true
                                    }
                                  })
                                }}
                                maxLength={50}
                                placeholder={Templates.dataModel.name.placeholder}
                              />
                            </Form.Item>
                            <Form.Item
                              name={Templates.dataModel.clinicType.key}
                              label={Templates.dataModel.clinicType.label}
                              rules={[
                                {
                                  required: Templates.dataModel.clinicType.required,
                                  message: Templates.dataModel.clinicType.validationMsg
                                }
                              ]}
                            >
                              <Radio.Group>
                                <Radio value='Medical Facility'>Medical Facility</Radio>
                                <Radio value='Third Party On-site Clinic'>Third Party On-site Clinic</Radio>
                              </Radio.Group>
                            </Form.Item>
                            <Form.Item
                              name={Templates.dataModel.paymentMethods.key}
                              label={Templates.dataModel.paymentMethods.label}
                              rules={[
                                {
                                  required: Templates.dataModel.paymentMethods.required,
                                  message: Templates.dataModel.paymentMethods.validationMsg
                                }
                              ]}
                            >
                              <Checkbox.Group valuePropName='checked'>
                                <Row>
                                  <Col span={24}>
                                    {coreState.entity.configs.paymentMethods.map((option) => {
                                      return (
                                        <div style={{ marginRight: 5 }}>
                                          <Checkbox value={option} /> {option}
                                        </div>
                                      )
                                    })}
                                  </Col>
                                </Row>
                              </Checkbox.Group>
                            </Form.Item>
                            <Form.Item
                              name={Templates.dataModel.bpmKey.key}
                              label={Templates.dataModel.bpmKey.label}
                              rules={[
                                {
                                  required: Templates.dataModel.bpmKey.required,
                                  message: Templates.dataModel.bpmKey.validationMsg
                                }
                              ]}
                            >
                              <Select
                                showSearch
                                placeholder='- Select a Business Process -'
                                optionFilterProp='children'
                                filterOption={(input, option) =>
                                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={bpmProfiles}
                              />
                            </Form.Item>
                            <Form.Item
                              name={Templates.dataModel.province.key}
                              label={Templates.dataModel.province.label}
                              rules={[
                                {
                                  required: Templates.dataModel.province.required,
                                  message: Templates.dataModel.province.validationMsg
                                }
                              ]}
                            >
                              <Select
                                showSearch
                                placeholder='- Select a Province -'
                                optionFilterProp='children'
                                filterOption={(input, option) =>
                                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={Templates.dataModel.province.options}
                              />
                            </Form.Item>
                            <Form.Item
                              name={Templates.dataModel.address.key}
                              label={Templates.dataModel.address.label}
                              rules={[
                                {
                                  required: Templates.dataModel.address.required,
                                  message: Templates.dataModel.address.validationMsg
                                }
                              ]}
                            >
                              <GoogleAddress
                                selectProps={{
                                  placeholder: data.address ? data.address : Templates.dataModel.address.placeholder,
                                  onChange: (e) => {
                                    if (e.label) {
                                      handleGetAddress(e.label)
                                    }

                                    clientForm.setFieldValue(Templates.dataModel.address.key, e.label)
                                    clientForm.setFieldValue(Templates.dataModel.place_id.key, e.value.place_id)
                                  }
                                }}
                              />
                            </Form.Item>
                            {physicalAddress ? (
                              <Form.Item
                                name={Templates.dataModel.physicalAddress.key}
                                label={Templates.dataModel.physicalAddress.label}
                                rules={[
                                  {
                                    required: Templates.dataModel.physicalAddress.required,
                                    message: Templates.dataModel.physicalAddress.validationMsg
                                  }
                                ]}
                              >
                                <Input.TextArea rows={2} readOnly />
                              </Form.Item>
                            ) : undefined}
                            <Form.Item
                              name={Templates.dataModel.practiceName.key}
                              label={Templates.dataModel.practiceName.label}
                              rules={[
                                {
                                  required: Templates.dataModel.practiceName.required,
                                  message: Templates.dataModel.practiceName.validationMsg
                                }
                              ]}
                            >
                              <Input
                                onChange={(e) => {
                                  specialFieldValidation(clientForm, specialFormState, setSpecialFormState, {
                                    key: 'practiceName',
                                    event: e,
                                    validationConfig: {
                                      letters: { allowed: true, onlyCaps: false },
                                      numbers: true,
                                      spaces: true,
                                      specialChars: true
                                    }
                                  })
                                }}
                                maxLength={50}
                                placeholder={Templates.dataModel.practiceName.placeholder}
                              />
                            </Form.Item>
                            <Form.Item
                              name={Templates.dataModel.practiceRegNumber.key}
                              label={Templates.dataModel.practiceRegNumber.label}
                              rules={[
                                {
                                  required: Templates.dataModel.practiceRegNumber.required,
                                  message: Templates.dataModel.practiceRegNumber.validationMsg
                                }
                              ]}
                            >
                              <Input
                                onChange={(e) => {
                                  specialFieldValidation(clientForm, specialFormState, setSpecialFormState, {
                                    key: 'practiceRegNumber',
                                    event: e,
                                    validationConfig: {
                                      letters: { allowed: true, onlyCaps: false },
                                      numbers: true,
                                      spaces: true,
                                      specialChars: true
                                    }
                                  })
                                }}
                                maxLength={50}
                                placeholder={Templates.dataModel.practiceRegNumber.placeholder}
                              />
                            </Form.Item>
                            <Form.Item
                              name={Templates.dataModel.practiceNumber.key}
                              label={Templates.dataModel.practiceNumber.label}
                              rules={[
                                {
                                  required: Templates.dataModel.practiceNumber.required,
                                  message: Templates.dataModel.practiceNumber.validationMsg
                                }
                              ]}
                            >
                              <Input
                                onChange={(e) => {
                                  specialFieldValidation(clientForm, specialFormState, setSpecialFormState, {
                                    key: 'practiceNumber',
                                    event: e,
                                    validationConfig: {
                                      letters: { allowed: true, onlyCaps: false },
                                      numbers: true,
                                      spaces: false,
                                      specialChars: true
                                    }
                                  })
                                }}
                                maxLength={50}
                                placeholder={Templates.dataModel.practiceNumber.placeholder}
                              />
                            </Form.Item>
                            <Space wrap style={{ marginTop: 20, marginBottom: 10 }}>
                              {Templates.dataModel.cgmEnabled.label}
                              <Form.Item
                                style={{ margin: '0' }}
                                name={Templates.dataModel.cgmEnabled.key}
                                valuePropName='checked'
                              >
                                <Switch checkedChildren='Yes' unCheckedChildren='No' />
                              </Form.Item>
                            </Space>
                            <br />
                            <Form.Item
                              name={Templates.dataModel.cgmPracticeId.key}
                              label={Templates.dataModel.cgmPracticeId.label}
                              rules={[
                                {
                                  required: Templates.dataModel.cgmPracticeId.required,
                                  message: Templates.dataModel.cgmPracticeId.validationMsg
                                }
                              ]}
                            >
                              <Input
                                onChange={(e) => {
                                  specialFieldValidation(clientForm, specialFormState, setSpecialFormState, {
                                    key: 'cgmPracticeId',
                                    event: e,
                                    validationConfig: {
                                      letters: { allowed: false, onlyCaps: false },
                                      numbers: true,
                                      spaces: false,
                                      specialChars: false
                                    }
                                  })
                                }}
                                maxLength={50}
                                placeholder={Templates.dataModel.cgmPracticeId.placeholder}
                              />
                            </Form.Item>
                            <Form.Item
                              name={Templates.dataModel.phoneNumber.key}
                              label={Templates.dataModel.phoneNumber.label}
                              rules={[
                                {
                                  required: Templates.dataModel.phoneNumber.required,
                                  message: Templates.dataModel.phoneNumber.validationMsg
                                }
                              ]}
                            >
                              <Input
                                onChange={(e) => {
                                  specialFieldValidation(clientForm, specialFormState, setSpecialFormState, {
                                    key: 'phoneNumber',
                                    event: e,
                                    validationConfig: {
                                      letters: { allowed: false, onlyCaps: false },
                                      numbers: true,
                                      spaces: false,
                                      specialChars: false
                                    }
                                  })
                                }}
                                maxLength={50}
                                placeholder={Templates.dataModel.phoneNumber.placeholder}
                              />
                            </Form.Item>
                            <Form.Item
                              name={Templates.dataModel.place_id.key}
                              label={Templates.dataModel.place_id.key}
                              hidden={true}
                            />
                          </Collapse.Panel>
                          <Collapse.Panel key='billing_macros' header='Billing Macros'>
                            <center>
                              <Button
                                type='primary'
                                style={{ marginBottom: 20 }}
                                onClick={() => {
                                  setMacro({ isActive: true, name: '', items: [] })
                                  setMacroFormOpen(true)
                                  setIsNewMacro(true)
                                  setMacroIndex(null)
                                }}
                              >
                                <Space>
                                  <FontAwesomeIcon icon={faAdd} style={{ color: '#fff' }} />
                                  <span>New Macro</span>
                                </Space>
                              </Button>
                            </center>
                            <Table
                              size='small'
                              dataSource={macros}
                              pagination={false}
                              bordered={true}
                              columns={[
                                {
                                  title: 'Name',
                                  dataIndex: 'name',
                                  key: 'name'
                                },
                                {
                                  title: 'Is Active',
                                  dataIndex: 'isActive',
                                  key: 'isActive',
                                  width: '15%',
                                  render: (text) => (text ? 'Yes' : 'No')
                                },
                                {
                                  title: 'Actions',
                                  key: 'actions',
                                  width: '20%',
                                  render: (text, record, index) => (
                                    <FontAwesomeIcon
                                      style={{ color: token.colorError }}
                                      icon={faTrash}
                                      onClick={(e) => {
                                        e.stopPropagation()
                                        const tmpMacros = [...macros]
                                        tmpMacros.splice(index, 1)
                                        setMacros(tmpMacros)
                                      }}
                                    />
                                  )
                                }
                              ]}
                              expandable={{
                                expandedRowRender: (record) => (
                                  <Table
                                    size='small'
                                    dataSource={record.items}
                                    columns={[
                                      { title: 'Type', dataIndex: 'type', key: 'type' },
                                      { title: 'Code', dataIndex: 'code', key: 'code' },
                                      { title: 'Name', dataIndex: 'name', key: 'name' },
                                      {
                                        title: 'ICD10',
                                        dataIndex: 'icd10',
                                        key: 'icd10',
                                        render: (text) => {
                                          return text.join(' - ')
                                        }
                                      },
                                      { title: 'QTY', dataIndex: 'qty', key: 'qty' }
                                    ]}
                                    pagination={false}
                                    bordered={true}
                                  />
                                ),
                                rowExpandable: (record) => record.items.length > 0
                              }}
                              onRow={(record, rowIndex) => {
                                return {
                                  onClick: () => {
                                    setMacro(record)
                                    setMacroFormOpen(true)
                                    setIsNewMacro(false)
                                    setMacroIndex(rowIndex)
                                  }
                                }
                              }}
                              rowKey={(record, index) => index}
                            />
                          </Collapse.Panel>
                        </Collapse>
                      </Col>
                      <Col>
                        <Form.Item noStyle>
                          <Button
                            style={{ backgroundColor: Theme.twitterBootstrap.danger, color: 'white' }}
                            onClick={closeTab}
                          >
                            Cancel
                          </Button>
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item noStyle>
                          {deactivatePrompt ? (
                            <Popconfirm
                              okText='Yes'
                              cancelText='No'
                              okButtonProps={{ style: { background: token.colorSuccess, color: 'white' } }}
                              cancelButtonProps={{ style: { background: token.colorError, color: 'white' } }}
                              title={<h3 style={{ color: token.colorWarning, margin: 0 }}>WARNING</h3>}
                              description='You have switched this clinic to be inactive. Are you sure you want to proceed?'
                              onConfirm={confirmDeactivation}
                            >
                              <Button
                                style={{
                                  backgroundColor: Theme.twitterBootstrap.success,
                                  color: 'white',
                                  marginRight: 10
                                }}
                                htmlType='submit'
                                loading={loading}
                              >
                                {isNewRecord ? 'Add Clinic' : 'Save Changes'}
                              </Button>
                            </Popconfirm>
                          ) : (
                            <Button
                              style={{
                                backgroundColor: Theme.twitterBootstrap.success,
                                color: 'white',
                                marginRight: 10
                              }}
                              htmlType='submit'
                              loading={loading}
                            >
                              {isNewRecord ? 'Add Clinic' : 'Save Changes'}
                            </Button>
                          )}
                        </Form.Item>
                      </Col>
                    </CustomRow>
                  </Form>
                </Col>
              </CustomRow>
            </Col>
          </CustomRow>
        </Col>
      </Row>
      <BillingMacroForm
        visible={macroFormOpen}
        setVisible={setMacroFormOpen}
        isNew={isNewMacro}
        macro={macro}
        setMacro={setMacro}
        macros={macros}
        setMacros={setMacros}
        index={macroIndex}
      />
    </ContainerCard>
  )
}

export default ClinicsForm
