import React, { useEffect, useState } from 'react'
import CustomRow from '../../reusable-components/CustomRow'
import ExaminationDataCapture from './examination-data-capture'
import dayjs from 'dayjs'

import { Button, Card, Col, Collapse, Row, Select, Space, Table, Tooltip, message, theme } from 'antd'
import { readClinicDataPoints } from '../../Super-Admin/Data Points/data-points-utils/data-point-utils'
import { handleError } from '../../lib/utils'
import { readDataPointTemplates } from '../../Data-Point-Templates/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChain, faChainBroken, faRefresh } from '@fortawesome/free-solid-svg-icons'
import AgiliteSkeleton from '../../reusable-components/AgiliteSkeleton'
import { StandardDataPointGraph } from '../examination-utils/standard-graph'
import ExaminationProceduresPerformed from './examination-procedures-performed'
import SymptomsCapture from './symptoms-capture'
import CorrectionsModal from './corrections-modal'

const PatientDataDisaply = ({
  fetchPatientData,
  loadingPatientData,
  patientDataEntries,
  setPatientDataEntries,
  otherDataEntries,
  currentTemplate,
  setCurrentTemplate,
  bookingWebSocket,
  userRef,
  bookingData
}) => {
  const [dataPoints, setDataPoints] = useState([])
  const [captureModalOpen, setCaptureModalOpen] = useState(false)
  const [dataTemplates, setDataTemplates] = useState([])

  const [dataPointsLoading, setDataPointsLoading] = useState(false)
  const [dataTemplatesLoading, setDataTemplatesLoading] = useState(false)

  const [expandedRowKeys, setExpandedRowKeys] = useState([])
  const [dataCorrectionTemplate, setDataCorrectionTemplate] = useState('')
  const [dataCorrectionData, setDataCorrectionData] = useState('')
  const [correctingData, setCorrectingData] = useState(false)

  useEffect(() => {
    if (!currentTemplate) {
      setCurrentTemplate(dataTemplates[0])
    }
    // eslint-disable-next-line
  }, [dataTemplates])

  useEffect(() => {
    fetchPatientData()
    // eslint-disable-next-line
  }, [currentTemplate])

  useEffect(() => {
    if (currentTemplate) {
      fetchDataPoints()
    } else {
      setDataPoints([])
    }
    // eslint-disable-next-line
  }, [currentTemplate])

  useEffect(() => {
    fetchDataPointTemplates()
    // eslint-disable-next-line
  }, [])

  const fetchDataPointTemplates = async () => {
    let tmpData = []
    const qry = {}

    setDataTemplatesLoading(true)
    try {
      qry.templateType = { $ne: 'clinical_report' }
      tmpData = await readDataPointTemplates(qry, true)
      setDataTemplates(tmpData)
    } catch (e) {
      message.error(handleError(e))
    }
    setDataTemplatesLoading(false)
  }

  const fetchDataPoints = async () => {
    setDataPointsLoading(true)

    const qry = {}
    let tmpDataPoints = []
    try {
      if (currentTemplate?.records) {
        qry.key = { $in: currentTemplate?.keys }
      }

      tmpDataPoints = await readClinicDataPoints(qry)
      setDataPoints(tmpDataPoints)
    } catch (e) {
      message.error(handleError(e))
    }

    setDataPointsLoading(false)
  }

  const groupData = (data) => {
    let tmpData = JSON.parse(JSON.stringify(data)) // .filter((i) => i.category === categoriesFilter)

    tmpData = tmpData.reduce((acc, record) => {
      const { key } = record
      if (!acc[key]) {
        acc[key] = { key, records: [] }
      }
      acc[key].records.push(record)
      return acc
    }, {})

    tmpData = Object.values(tmpData).map((item) => item)

    tmpData.forEach((dataPoint) => {
      dataPoint.records.sort((a, b) => new Date(b.dateCreated) - new Date(a.dateCreated))
    })

    return tmpData
  }

  const getDataPointLabel = (key, isSubfield, subfieldKey) => {
    let dataPointTarget = ''

    if (isSubfield) {
      let parentField = dataPoints.find((i) => i.key === key)
      dataPointTarget = parentField.subFields.find((i) => i.key === subfieldKey)
    } else {
      dataPointTarget = dataPoints.find((i) => i.key === key)
    }

    if (!dataPointTarget) return 'Unknown Data Point'

    return dataPointTarget.label
  }

  const hasGraph = (dataPointReference) => {
    let confirmed = false
    if (dataPointReference.subFields.length > 0) {
      confirmed = true
      dataPointReference.subFields.forEach((field) => {
        if (field.fieldType !== 'number') {
          confirmed = false
        }
      })
    } else {
      confirmed = dataPointReference.fieldType === 'number'
    }
    return confirmed
  }

  const groupSubfields = (subFieldData, dataTemplateReference) => {
    let groupedSubfields = []
    let subfieldRecords = subFieldData.records
    dataTemplateReference.subFields.forEach((subFieldEntry) => {
      let tmpData = []
      subfieldRecords.forEach((subfieldRecord) => {
        let targetDataPoint = subfieldRecord.value.find((i) => i.key === subFieldEntry.key)
        tmpData.push({
          ...subfieldRecord,
          value: targetDataPoint.value
        })
      })
      groupedSubfields.push({
        key: subFieldEntry.key,
        records: tmpData
      })
    })

    return groupedSubfields
  }

  const customRowClick = (row_props) => {
    const keyIndex = expandedRowKeys.indexOf(row_props.key)
    const newExpandedRowKeys = [...expandedRowKeys]
    if (keyIndex === -1) {
      newExpandedRowKeys.push(row_props.key)
    } else {
      newExpandedRowKeys.splice(keyIndex, 1)
    }
    setExpandedRowKeys(newExpandedRowKeys)
  }
  const { token } = theme.useToken()

  const handleNestedDataDisplay = (subFieldData, subFieldDataPointReference) => {
    console.log(subFieldDataPointReference, subFieldData, subFieldData.records[0]?.value)
    if (subFieldData.records[0]?.value !== null && Array.isArray(subFieldData.records[0]?.value)) {
      return (
        <Space>
          <span>{subFieldData.records[0]?.value.join(', ')}</span>
          <span style={{ opacity: 0.5 }}>{subFieldDataPointReference?.suffix}</span>
        </Space>
      )
    }
    return (
      <Space>
        <span>{subFieldData.records[0]?.value}</span>
        <span style={{ opacity: 0.5 }}>{subFieldDataPointReference?.suffix}</span>
      </Space>
    )
  }

  return (
    <CustomRow>
      {bookingData ? (
        <Col span={24}>
          <SymptomsCapture bookingData={bookingData} />
        </Col>
      ) : undefined}
      <Col span={24}>
        <Card
          size='small'
          title={
            <Space style={{ width: '100%', justifyContent: 'space-between' }}>
              <Space>
                <Button
                  disabled={loadingPatientData || dataPointsLoading || dataTemplatesLoading}
                  type='primary'
                  onClick={() => setCaptureModalOpen(true)}
                >
                  New Capture
                </Button>
                {/* <Select
                  style={{ width: 400 }}
                  placeholder='Filter by Category'
                  value={categoriesFilter}
                  options={[
                    { label: 'All', value: 'all' },
                    { label: 'Standard Clinical Readings', value: 'standard' },
                    { label: 'POC Results', value: 'poc' },
                    { label: 'Lab Results', value: 'lab' }
                  ]}
                  onChange={(value) => {
                    setCategoriesFilter(value)
                  }}
                /> */}
              </Space>
              <Space>
                <Tooltip
                  title={
                    bookingWebSocket.connected
                      ? 'Connected to Realtime Update Server'
                      : 'Disconnected from Realtime Update Server'
                  }
                >
                  <FontAwesomeIcon
                    icon={bookingWebSocket.connected ? faChain : faChainBroken}
                    size='sm'
                    color={bookingWebSocket.connected ? token.colorSuccess : token.colorError}
                    style={{ cursor: 'help' }}
                  />
                </Tooltip>
                <Select
                  showSearch
                  optionFilterProp='label'
                  value={JSON.stringify(currentTemplate)}
                  style={{ width: 450 }}
                  onChange={(template) => {
                    setCurrentTemplate(JSON.parse(template))
                  }}
                  options={dataTemplates.map((template) => ({ value: JSON.stringify(template), label: template.name }))}
                />
                <Button onClick={() => fetchPatientData()} type='primary' style={{ background: token.colorSuccess }}>
                  <FontAwesomeIcon icon={faRefresh} />
                </Button>
              </Space>
            </Space>
          }
        >
          <Row>
            <Col span={24}>
              {loadingPatientData || dataPointsLoading || dataTemplatesLoading ? (
                <AgiliteSkeleton />
              ) : (
                <>
                  <Collapse defaultActiveKey='1'>
                    <Collapse.Panel header='Template Clinical Readings' key='1'>
                      <Table
                        onRow={(row_props) => {
                          return {
                            onClick: () => {
                              customRowClick(row_props)
                            }
                          }
                        }}
                        dataSource={groupData(patientDataEntries)}
                        columns={[
                          {
                            title: 'Clinical Reading',
                            render: (data) => {
                              return getDataPointLabel(data.key)
                            }
                          },
                          {
                            title: 'Value',
                            render: (data) => {
                              const dataPointReference = dataPoints.find((i) => data.key === i.key)
                              if (dataPointReference.nestedSubFields) return 'Drop down to see historical entries.'
                              if (data.records[0]?.value !== null && Array.isArray(data.records[0]?.value)) {
                                return (
                                  <Space>
                                    <span>
                                      {data.records[0]?.value.map((i) => i.value).join(dataPointReference.delimiter)}
                                    </span>
                                    <span style={{ opacity: 0.5 }}>{dataPointReference?.suffix}</span>
                                  </Space>
                                )
                              } else {
                                return (
                                  <Space>
                                    <span>{data.records[0]?.value}</span>
                                    <span style={{ opacity: 0.5 }}>{dataPointReference?.suffix}</span>
                                  </Space>
                                )
                              }
                            }
                          },
                          {
                            title: 'Date',
                            render: (data) => {
                              return dayjs(data.records[0]?.dateCreated).format('DD MMM YYYY - HH:mm')
                            }
                          },
                          {
                            title: 'Actions',
                            render: (data) => {
                              return (
                                <Button
                                  type='primary'
                                  onClick={(e) => {
                                    const dataTemplateReference = dataPoints.find((i) => data.key === i.key)
                                    e.stopPropagation()
                                    setDataCorrectionTemplate(dataTemplateReference)
                                    setDataCorrectionData(data.records)
                                    setCorrectingData(true)
                                  }}
                                >
                                  Make Corrections
                                </Button>
                              )
                            }
                          }
                        ]}
                        pagination={false}
                        size='small'
                        bordered={true}
                        expandable={{
                          expandRowByClick: true,
                          expandedRowRender: (data) => {
                            const dataTemplateReference = dataPoints.find((i) => data.key === i.key)

                            if (hasGraph(dataTemplateReference)) {
                              return <StandardDataPointGraph data={data} dataTemplate={dataTemplateReference} />
                            } else {
                              if (dataTemplateReference.nestedSubFields) {
                                return (
                                  <Table
                                    onRow={(row_props) => {
                                      return {
                                        onClick: () => {
                                          customRowClick(row_props)
                                        }
                                      }
                                    }}
                                    size='small'
                                    dataSource={groupSubfields(data, dataTemplateReference)}
                                    columns={[
                                      {
                                        title: 'Data Point',
                                        render: (subFieldData) => {
                                          return getDataPointLabel(data.key, true, subFieldData.key)
                                        }
                                      },
                                      {
                                        title: 'Reading',
                                        render: (subFieldData) => {
                                          const subFieldDataPointReference = dataTemplateReference.subFields.find(
                                            (i) => subFieldData.key === i.key
                                          )
                                          return handleNestedDataDisplay(subFieldData, subFieldDataPointReference)
                                        }
                                      },
                                      {
                                        title: 'Date',
                                        render: (subFieldData) => {
                                          return dayjs(subFieldData.records[0]?.dateCreated).format(
                                            'DD MMM YYYY - HH:mm'
                                          )
                                        }
                                      }
                                    ]}
                                    pagination={false}
                                    bordered={true}
                                    expandable={{
                                      expandRowByClick: true,
                                      expandedRowRender: (subFieldData) => {
                                        return (
                                          <Table
                                            size='small'
                                            dataSource={subFieldData.records}
                                            columns={[
                                              {
                                                title: 'Reading',
                                                render: (readingData) => {
                                                  const subFieldDataPointReference =
                                                    dataTemplateReference.subFields.find(
                                                      (i) => subFieldData.key === i.key
                                                    )
                                                  if (readingData.value !== null && Array.isArray(readingData.value)) {
                                                    return (
                                                      <Space>
                                                        <span>{readingData.value.join('/')}</span>
                                                        <span style={{ opacity: 0.5 }}>
                                                          {subFieldDataPointReference?.suffix}
                                                        </span>
                                                      </Space>
                                                    )
                                                  } else {
                                                    return (
                                                      <Space>
                                                        <span>{readingData.value}</span>
                                                        <span style={{ opacity: 0.5 }}>
                                                          {subFieldDataPointReference?.suffix}
                                                        </span>
                                                      </Space>
                                                    )
                                                  }
                                                }
                                              },
                                              {
                                                title: 'Date',
                                                render: (subFieldData) => {
                                                  return dayjs(subFieldData.dateCreated).format('DD MMM YYYY - HH:mm')
                                                }
                                              }
                                            ]}
                                            pagination={false}
                                            bordered={true}
                                          />
                                        )
                                      }
                                    }}
                                  />
                                )
                              }
                              return (
                                <Table
                                  size='small'
                                  dataSource={data.records}
                                  columns={[
                                    {
                                      title: 'Reading',
                                      render: (readingData) => {
                                        const dataPointReference = dataPoints.find((i) => data.key === i.key)
                                        if (readingData.value !== null && Array.isArray(readingData.value)) {
                                          return (
                                            <Space>
                                              <span>{readingData.value.map((i) => i.value).join('/')}</span>
                                              <span style={{ opacity: 0.5 }}>{dataPointReference?.suffix}</span>
                                            </Space>
                                          )
                                        } else {
                                          return (
                                            <Space>
                                              <span>{readingData.value}</span>
                                              <span style={{ opacity: 0.5 }}>{dataPointReference?.suffix}</span>
                                            </Space>
                                          )
                                        }
                                      }
                                    },
                                    {
                                      title: 'Date',
                                      render: (data) => {
                                        return dayjs(data.dateCreated).format('DD MMM YYYY - HH:mm')
                                      }
                                    }
                                  ]}
                                  pagination={false}
                                  bordered={true}
                                />
                              )
                            }
                          }
                        }}
                      />
                    </Collapse.Panel>
                    <Collapse.Panel header='All Clinical Readings' key='2'>
                      <Table
                        onRow={(row_props) => {
                          return {
                            onClick: () => {
                              customRowClick(row_props)
                            }
                          }
                        }}
                        dataSource={groupData(otherDataEntries)}
                        columns={[
                          {
                            title: 'Clinical Reading',
                            render: (data) => {
                              return getDataPointLabel(data.key)
                            }
                          },
                          {
                            title: 'Value',
                            render: (data) => {
                              const dataPointReference = dataPoints.find((i) => data.key === i.key)
                              if (dataPointReference) {
                                if (dataPointReference.nestedSubFields) return 'Drop down to see historical entries.'
                                if (data.records[0]?.value !== null && Array.isArray(data.records[0]?.value)) {
                                  return (
                                    <Space>
                                      <span>
                                        {data.records[0]?.value.map((i) => i.value).join(dataPointReference.delimiter)}
                                      </span>
                                      <span style={{ opacity: 0.5 }}>{dataPointReference?.suffix}</span>
                                    </Space>
                                  )
                                } else {
                                  return (
                                    <Space>
                                      <span>{data.records[0]?.value}</span>
                                      <span style={{ opacity: 0.5 }}>{dataPointReference?.suffix}</span>
                                    </Space>
                                  )
                                }
                              }
                            }
                          },
                          {
                            title: 'Date',
                            render: (data) => {
                              return dayjs(data.records[0]?.dateCreated).format('DD MMM YYYY - HH:mm')
                            }
                          }
                        ]}
                        pagination={false}
                        size='small'
                        bordered={true}
                        expandable={{
                          expandRowByClick: true,
                          expandedRowRender: (data) => {
                            const dataTemplateReference = dataPoints.find((i) => data.key === i.key)

                            if (dataTemplateReference) {
                              if (hasGraph(dataTemplateReference)) {
                                return <StandardDataPointGraph data={data} dataTemplate={dataTemplateReference} />
                              } else {
                                if (dataTemplateReference.nestedSubFields) {
                                  return (
                                    <Table
                                      onRow={(row_props) => {
                                        return {
                                          onClick: () => {
                                            customRowClick(row_props)
                                          }
                                        }
                                      }}
                                      size='small'
                                      dataSource={groupSubfields(data, dataTemplateReference)}
                                      columns={[
                                        {
                                          title: 'Data Point',
                                          render: (subFieldData) => {
                                            return getDataPointLabel(data.key, true, subFieldData.key)
                                          }
                                        },
                                        {
                                          title: 'Reading',
                                          render: (subFieldData) => {
                                            const subFieldDataPointReference = dataTemplateReference.subFields.find(
                                              (i) => subFieldData.key === i.key
                                            )
                                            if (
                                              subFieldData.records[0]?.value !== null &&
                                              Array.isArray(subFieldData.records[0]?.value)
                                            ) {
                                              return (
                                                <Space>
                                                  <span>
                                                    {subFieldData.records[0]?.value.map((i) => i.value).join('/')}
                                                  </span>
                                                  <span style={{ opacity: 0.5 }}>
                                                    {subFieldDataPointReference?.suffix}
                                                  </span>
                                                </Space>
                                              )
                                            } else {
                                              return (
                                                <Space>
                                                  <span>{subFieldData.records[0]?.value}</span>
                                                  <span style={{ opacity: 0.5 }}>
                                                    {subFieldDataPointReference?.suffix}
                                                  </span>
                                                </Space>
                                              )
                                            }
                                          }
                                        },
                                        {
                                          title: 'Date',
                                          render: (subFieldData) => {
                                            return dayjs(subFieldData.records[0]?.dateCreated).format(
                                              'DD MMM YYYY - HH:mm'
                                            )
                                          }
                                        }
                                      ]}
                                      pagination={false}
                                      bordered={true}
                                      expandable={{
                                        expandRowByClick: true,
                                        expandedRowRender: (subFieldData) => {
                                          return (
                                            <Table
                                              size='small'
                                              dataSource={subFieldData.records}
                                              columns={[
                                                {
                                                  title: 'Reading',
                                                  render: (readingData) => {
                                                    const subFieldDataPointReference =
                                                      dataTemplateReference.subFields.find(
                                                        (i) => subFieldData.key === i.key
                                                      )
                                                    if (
                                                      readingData.value !== null &&
                                                      Array.isArray(readingData.value)
                                                    ) {
                                                      return (
                                                        <Space>
                                                          <span>{readingData.value.map((i) => i.value).join('/')}</span>
                                                          <span style={{ opacity: 0.5 }}>
                                                            {subFieldDataPointReference?.suffix}
                                                          </span>
                                                        </Space>
                                                      )
                                                    } else {
                                                      return (
                                                        <Space>
                                                          <span>{readingData.value}</span>
                                                          <span style={{ opacity: 0.5 }}>
                                                            {subFieldDataPointReference?.suffix}
                                                          </span>
                                                        </Space>
                                                      )
                                                    }
                                                  }
                                                },
                                                {
                                                  title: 'Date',
                                                  render: (subFieldData) => {
                                                    return dayjs(subFieldData.dateCreated).format('DD MMM YYYY - HH:mm')
                                                  }
                                                }
                                              ]}
                                              pagination={false}
                                              bordered={true}
                                            />
                                          )
                                        }
                                      }}
                                    />
                                  )
                                }
                                return (
                                  <Table
                                    size='small'
                                    dataSource={data.records}
                                    columns={[
                                      {
                                        title: 'Reading',
                                        render: (readingData) => {
                                          const dataPointReference = dataPoints.find((i) => data.key === i.key)
                                          if (readingData.value !== null && Array.isArray(readingData.value)) {
                                            return (
                                              <Space>
                                                <span>{readingData.value.map((i) => i.value).join('/')}</span>
                                                <span style={{ opacity: 0.5 }}>{dataPointReference?.suffix}</span>
                                              </Space>
                                            )
                                          } else {
                                            return (
                                              <Space>
                                                <span>{readingData.value}</span>
                                                <span style={{ opacity: 0.5 }}>{dataPointReference?.suffix}</span>
                                              </Space>
                                            )
                                          }
                                        }
                                      },
                                      {
                                        title: 'Date',
                                        render: (data) => {
                                          return dayjs(data.dateCreated).format('DD MMM YYYY - HH:mm')
                                        }
                                      }
                                    ]}
                                    pagination={false}
                                    bordered={true}
                                  />
                                )
                              }
                            }
                          }
                        }}
                      />
                    </Collapse.Panel>
                  </Collapse>
                </>
              )}
            </Col>
          </Row>
        </Card>
        {bookingData ? <ExaminationProceduresPerformed bookingData={bookingData} userRef={userRef} /> : undefined}
      </Col>
      <CorrectionsModal
        fetchPatientData={fetchPatientData}
        patientDataEntries={patientDataEntries}
        setPatientDataEntries={setPatientDataEntries}
        template={dataCorrectionTemplate}
        data={dataCorrectionData}
        setTemplate={setDataCorrectionTemplate}
      />
      <ExaminationDataCapture
        socket={null}
        modalOpen={captureModalOpen}
        setModalOpen={setCaptureModalOpen}
        dataTemplates={dataTemplates}
        bookingWebSocket={bookingWebSocket}
        bookingData={bookingData}
        userRef={userRef}
        handleRefresh={fetchPatientData}
      />
    </CustomRow>
  )
}

export default PatientDataDisaply
