import { FilterFilled } from '@ant-design/icons'
import { Tooltip } from 'antd'
import { sortBy } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faXmarkCircle } from '@fortawesome/free-regular-svg-icons'
import theme from '../../../../core/utils/theme'
import CoreEnums from '../../../../core/utils/enums'

const ClinicTemplates = {
  dataModel: {
    isActive: {
      label: 'Status:',
      key: 'isActive',
      required: false
    },
    clinicBookings: {
      label: 'Allow Clinic Bookings?',
      key: 'clinicBookings',
      required: false
    },
    homeVisits: {
      label: 'Allow Home Visits?',
      key: 'homeVisits',
      required: false
    },
    bpmKey: {
      label: 'Select the Business Process for this clinic:',
      key: 'bpmKey',
      required: true,
      validationMsg: 'Please select the clinic`s Business Process'
    },
    province: {
      label: 'Provide the province this clinic is situated in:',
      key: 'province',
      required: true,
      validationMsg: 'Please select the clinic`s province',
      options: Object.keys(CoreEnums.provinces).map((key) => {
        return {
          label: CoreEnums.provinces[key],
          value: CoreEnums.provinces[key]
        }
      })
    },
    name: {
      label: 'Provide the clinic`s name:',
      placeholder: 'e.g. Meta-Clinic Mill Park',
      key: 'name',
      required: true,
      validationMsg: 'Please provide the clinic`s name'
    },
    address: {
      label: 'Search for Clinic:',
      placeholder: 'e.g Meta-Clinic Cape Road, Mill Park',
      key: 'address',
      required: true,
      validationMsg: 'Please provide the clinic`s address'
    },
    physicalAddress: {
      label: 'Clinic Address:',
      placeholder: '',
      key: 'physicalAddress',
      required: false,
      validationMsg: ''
    },
    clinicType: {
      label: 'Clinic Type:',
      placeholder: '',
      key: 'clinicType',
      required: false,
      validationMsg: 'Please select a Clinic Type.'
    },
    paymentMethods: {
      label: 'Accepted Payment Methods:',
      placeholder: '',
      key: 'paymentMethods',
      options: [
        {
          value: 'Cash',
          label: 'Cash'
        },
        { value: 'Card', label: 'Card' },
        {
          value: 'Medical Aid',
          label: 'Medical Aid'
        }
      ],
      required: true,
      validationMsg: 'Please select atleast one payment method'
    },
    place_id: {
      key: 'place_id'
    },
    practiceName: {
      label: 'Practice Name:',
      placeholder: 'e.g. Dr Jane Doe Medical',
      required: true,
      key: 'practiceName'
    },
    practiceRegNumber: {
      label: 'Practice Registration Number:',
      placeholder: 'e.g. 2778 / 334 / 440',
      required: true,
      key: 'practiceRegNumber'
    },
    practiceNumber: {
      label: 'Practice Number:',
      placeholder: 'e.g. 278837741',
      required: true,
      key: 'practiceNumber'
    },
    cgmEnabled: {
      label: 'CGM Enabled:',
      key: 'cgmEnabled',
      required: false
    },
    cgmPracticeId: {
      label: 'CGM Practice ID:',
      placeholder: 'e.g. 278837741',
      required: true,
      key: 'cgmPracticeId'
    },
    phoneNumber: {
      label: 'Phone Number:',
      placeholder: 'e.g. 011555****',
      required: true,
      key: 'phoneNumber'
    }
  },

  dataTemplate: () => {
    return {
      isActive: true,
      clinicBookings: true,
      homeVisits: true,
      name: null,
      province: null,
      address: null,
      physicalAddress: null,
      clinicType: 'Medical Facility',
      place_id: null,
      practiceName: null,
      practiceRegNumber: null,
      practiceNumber: null,
      phoneNumber: null
    }
  },

  columnTemplate: (editRecord, filters) => {
    return [
      {
        title: 'Clinic Name',
        dataIndex: 'name',
        width: '20%',
        key: 'name',
        sorter: (a, b) => {
          return a.name.localeCompare(b.name)
        }
      },
      {
        title: 'Clinic Type',
        dataIndex: 'clinicType',
        key: 'clinicType',
        width: '15%',
        filters: filters.clinicType,
        onFilter: (value, record) => {
          return record.clinicType.indexOf(value) === 0
        },
        filterIcon: (
          <Tooltip title='Filter by Clinic Type'>
            <FilterFilled />
          </Tooltip>
        ),
        sorter: (a, b) => {
          return a.clinicType.localeCompare(b.clinicType)
        }
      },
      {
        width: '10%',
        title: 'Province',
        dataIndex: 'province',
        key: 'province',
        filterSearch: true,
        filters: sortBy(filters.province, ['text']),
        onFilter: (value, record) => {
          return record.province.indexOf(value) === 0
        },
        filterIcon: (
          <Tooltip title='Filter by Province'>
            <FilterFilled />
          </Tooltip>
        ),
        sorter: (a, b) => {
          return a.province.localeCompare(b.province)
        }
      },
      {
        title: 'Physical Address',
        dataIndex: 'physicalAddress',
        key: 'address',
        width: '30%'
      },
      {
        title: 'Clinic Bookings',
        dataIndex: 'clinicBookings',
        key: 'clinicBookings',
        width: '8%',
        filterMultiple: false,
        filters: [
          { text: 'Yes', value: true },
          { text: 'No', value: false }
        ],
        onFilter: (value, record) => {
          return record.clinicBookings === value
        },
        filterIcon: <FilterFilled />,
        render: (value) => {
          if (value === true)
            return (
              <center>
                <Tooltip title='Yes'>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    style={{ fontSize: 20, color: theme.twitterBootstrap.success }}
                  />
                </Tooltip>
              </center>
            )
          else
            return (
              <center>
                <Tooltip title='No'>
                  <FontAwesomeIcon
                    icon={faXmarkCircle}
                    style={{ fontSize: 20, color: theme.twitterBootstrap.danger }}
                  />
                </Tooltip>
              </center>
            )
        }
      },
      {
        title: 'Home Visits',
        dataIndex: 'homeVisits',
        key: 'homeVisits',
        width: '8%',
        filterMultiple: false,
        filters: [
          { text: 'Yes', value: true },
          { text: 'No', value: false }
        ],
        onFilter: (value, record) => {
          return record.homeVisits === value
        },
        filterIcon: <FilterFilled />,
        render: (value) => {
          if (value === true)
            return (
              <center>
                <Tooltip title='Yes'>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    style={{ fontSize: 20, color: theme.twitterBootstrap.success }}
                  />
                </Tooltip>
              </center>
            )
          else
            return (
              <center>
                <Tooltip title='No'>
                  <FontAwesomeIcon
                    icon={faXmarkCircle}
                    style={{ fontSize: 20, color: theme.twitterBootstrap.danger }}
                  />
                </Tooltip>
              </center>
            )
        }
      },
      {
        title: 'Status',
        dataIndex: 'isActive',
        key: 'isActive',
        width: '5%',
        render: (status) => {
          if (status === true)
            return (
              <center>
                <Tooltip title='Active'>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    style={{ fontSize: 20, color: theme.twitterBootstrap.success }}
                  />
                </Tooltip>
              </center>
            )
          else
            return (
              <center>
                <Tooltip title='Deactivated'>
                  <FontAwesomeIcon
                    icon={faXmarkCircle}
                    style={{ fontSize: 20, color: theme.twitterBootstrap.danger }}
                  />
                </Tooltip>
              </center>
            )
        },
        filterMultiple: false,
        filters: [
          {
            text: 'Active',
            value: true
          },
          {
            text: 'Inactive',
            value: false
          }
        ],
        onFilter: (value, record) => {
          return record.isActive === value
        },
        filterIcon: (
          <Tooltip title='Filter by Status'>
            <FilterFilled />
          </Tooltip>
        )
      }
    ]
  }
}

export default ClinicTemplates
