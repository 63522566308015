import Agilite from 'agilite'
import BookingEnums from './enums'
import dayjs from 'dayjs'
import { generateOTP } from '../../lib/utils'
import { readMedicalProfessionalUsers } from '../../Admin/medical-professionals/utils/utils'
import { readPatients } from '../../Admin/patients/utils/utils'
import axios from 'axios'
import { readClinics } from '../../Admin/clinics/utils/utils'
import { readServices } from '../../Admin/services/utils/utils'
import { readVirtualServices } from '../../Admin/virutal-services/utils/utils'
import { readSystemUsers } from '../../Admin/system-users/utils/utils'

const agilite = new Agilite({
  apiServerUrl: process.env.REACT_APP_AGILITE_API_URL,
  apiKey: process.env.REACT_APP_AGILITE_API_KEY
})

export const findOneBooking = (filter, options) => {
  return new Promise((resolve, reject) => {
    let response = null
    let queryOptions = null

    ;(async () => {
      try {
        if (options) {
          queryOptions = JSON.stringify(options)
        }

        response = await agilite.Connectors.execute(
          BookingEnums.profileKeys.BOOKINGS,
          BookingEnums.routeKeys.FIND_ONE,
          {
            filter: JSON.stringify(filter),
            page: null,
            pageLimit: null,
            options: queryOptions
          }
        )
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const readBookings = (filter, options, projection, page, pageLimit) => {
  return new Promise((resolve, reject) => {
    let response = null
    let queryOptions = null

    ;(async () => {
      try {
        if (options) {
          queryOptions = JSON.stringify(options)
        }

        response = await agilite.Connectors.execute(BookingEnums.profileKeys.BOOKINGS, BookingEnums.routeKeys.READ, {
          filter: JSON.stringify(filter),
          page,
          pageLimit,
          options: queryOptions,
          projection
        })
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const aggregateBookings = (pipeline) => {
  return new Promise((resolve, reject) => {
    let response = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          BookingEnums.profileKeys.BOOKINGS,
          BookingEnums.routeKeys.AGGREGATE,
          {
            pipeline: JSON.stringify(pipeline)
          }
        )
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const createBooking = (data) => {
  return new Promise((resolve, reject) => {
    let response = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute(BookingEnums.profileKeys.BOOKINGS, BookingEnums.routeKeys.CREATE, {
          data: JSON.stringify(data)
        })
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const updateBooking = (data, filter) => {
  return new Promise((resolve, reject) => {
    let response = null
    ;(async () => {
      try {
        response = await agilite.Connectors.execute(BookingEnums.profileKeys.BOOKINGS, BookingEnums.routeKeys.UPDATE, {
          filter: JSON.stringify(filter),
          data: JSON.stringify(data)
        })
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const sendOTP = (data, dependant, isDependant, clinicName) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        const otp = generateOTP(6)

        if (data.phoneNumber) {
          agilite.Connectors.execute('sms_portal', 'send', {
            data: JSON.stringify({
              messages: [
                {
                  content: `Hi ${data.firstName}.\nThis is your Booking Confirmation${
                    isDependant ? ` for ${dependant.firstName}` : ''
                  }.\n\nClinic: ${clinicName}\nDate: ${dayjs(data.bookingDate).format('YYYY-MM-DD')} ${
                    data.startTime
                  }-${
                    data.endTime
                  }\nOTP: ${otp}\n\nPlease arrive 15 Minutes before your booking time for screening purposes`,
                  destination: data.phoneNumber
                }
              ]
            })
          })
        }

        if (data.email) {
          agilite.Connectors.execute('sendgrid_new', 'send_dynamic', {
            apiKey: process.env.REACT_APP_SENDGRID_API_KEY,
            data: JSON.stringify({
              from: { email: 'noreply@meta-health.co.za' },
              personalizations: [
                {
                  to: [{ email: data.email }],
                  dynamic_template_data: {
                    firstName: data.firstName,
                    otp,
                    bookingDate: dayjs(data.bookingDate).format('DD MMM YYYY'),
                    startTime: data.startTime,
                    medicalProfName: `${data.medicalProf.firstName} ${data.medicalProf.lastName}`
                  }
                }
              ],
              template_id: 'd-ad0301d8918e484baa16ddaf4d57eed0'
            })
          })
        }

        resolve(otp)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const getBookings = async (state, qry, userRef, queryOptions = {}) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let filter = null
      let tmpBookings = []
      let medProfIds = []
      let patientIds = []
      let tmpMedicalProfessionals = []
      let tmpPatients = []

      try {
        if (!state.auth.completeEmailSetup && state.auth.agiliteUser) {
          filter = { userRef: userRef ? userRef : state.auth.agiliteUser._id, ...qry }

          tmpBookings = await readBookings(filter, queryOptions)
          medProfIds = tmpBookings.map((booking) => booking.medicalProfRef)
          patientIds = tmpBookings.map((booking) => booking.userRef)
          tmpMedicalProfessionals = await readMedicalProfessionalUsers({ _id: { $in: medProfIds } })
          tmpPatients = await readPatients({ _id: { $in: patientIds } })

          for (const booking of tmpBookings) {
            for (const medicalProf of tmpMedicalProfessionals) {
              if (booking.medicalProfRef === medicalProf._id) {
                booking.medicalProfName = medicalProf.firstName + ' ' + medicalProf.lastName
                booking.medicalProfImage = medicalProf.profileImage
              }
            }

            for (const patient of tmpPatients) {
              if (booking.userRef === patient._id) {
                booking.patientName = patient.firstName + ' ' + patient.lastName
              }
            }

            for (const clinic of state.clinics.data) {
              if (clinic._id === booking.clinicRef) {
                booking.clinicName = clinic.name
              }
            }

            for (const service of state.services.data) {
              if (service._id === booking.service) {
                booking.serviceName = service.name
                booking.serviceType = service.type
              }
            }

            for (const virtualService of state.virtualServices.data) {
              if (virtualService._id === booking.service) {
                booking.serviceName = virtualService.name
              }
            }
          }
        }

        resolve(tmpBookings)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const getAppointments = async (state, qry) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      const userIds = []
      const professionalIds = []
      let filter = null
      let tmpAppointments = []
      let tmpPatients = []
      let tmpMedicalProfessionals = []
      let clinics = []
      let services = []
      let virtualServices = []

      try {
        if (!state.auth.completeEmailSetup && state.auth.agiliteUser) {
          if (
            state.auth.agiliteUser.extraData.profession === 'nurse' ||
            state.auth.agiliteUser.extraData.role.type === 'reception' ||
            state.auth.agiliteUser.extraData.role.isManager ||
            state.auth.agiliteUser.extraData.role.type === 'admin'
          ) {
            filter = { ...qry }
          } else {
            // medicalProfRef: state.auth.agiliteUser._id
            filter = { ...qry }
          }

          tmpAppointments = await readBookings(filter)
          clinics = await readClinics()
          services = await readServices()
          virtualServices = await readVirtualServices()

          for (const booking of tmpAppointments) {
            if (booking.userRef) userIds.push(booking.userRef)
            if (booking.medicalProfRef) professionalIds.push(booking.medicalProfRef)
          }

          tmpPatients = await readSystemUsers({ _id: { $in: userIds } }, null, null, null, true)
          tmpMedicalProfessionals = await readMedicalProfessionalUsers({ _id: { $in: professionalIds } })

          for (const appointment of tmpAppointments) {
            for (const patient of tmpPatients) {
              if (appointment.userRef === patient._id) {
                appointment.patientName = patient.firstName + ' ' + patient.lastName
                appointment.patientRecord = patient
              }

              for (const clinic of clinics) {
                if (clinic._id === appointment.clinicRef) {
                  appointment.clinicName = clinic.name
                  appointment.clinicRecord = clinic
                }
              }
              const bookingService =
                services.find((i) => i._id === appointment.service) ||
                virtualServices.find((i) => i._id === appointment.service)
              appointment.serviceName = bookingService?.name ? bookingService?.name : 'Could Not Find Service'
              appointment.serviceType = bookingService?.type ? bookingService?.type : 'Could Not Find Service'
              appointment.serviceRecord = bookingService
            }

            for (const medicalProf of tmpMedicalProfessionals) {
              if (appointment.medicalProfRef === medicalProf._id) {
                appointment.medicalProfName = medicalProf.firstName + ' ' + medicalProf.lastName
              }
            }
          }
        }

        resolve(tmpAppointments)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const generateAgoraRtcToken = async (bookingId, userRef) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let response = null

      try {
        response = await axios.get(`${process.env.REACT_APP_NODE_RED_URL}/api/agora/rtcToken`, {
          headers: { 'channel-name': bookingId, 'user-ref': userRef }
        })
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const getNetCashTransaction = (id) => {
  return new Promise((resolve, reject) => {
    let response = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          BookingEnums.profileKeys.NETCASH_TRANSACTIONS,
          BookingEnums.routeKeys.FIND_BY_ID,
          {
            recordId: id
          }
        )
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const createNetCashTransaction = (userRef, amount, description, isSub) => {
  return new Promise((resolve, reject) => {
    let response = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          BookingEnums.profileKeys.NETCASH_TRANSACTIONS,
          BookingEnums.routeKeys.CREATE,
          {
            data: JSON.stringify({
              status: 'pending',
              timestamp: new Date().toISOString(),
              userRef,
              amount,
              description,
              isSubscription: isSub
            })
          }
        )
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const updateNetCashTransaction = (recordId) => {
  return new Promise((resolve, reject) => {
    let response = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          BookingEnums.profileKeys.NETCASH_TRANSACTIONS,
          BookingEnums.routeKeys.UPDATE,
          {
            recordId,
            data: JSON.stringify({
              status: 'success'
            })
          }
        )
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const getConsultationTypes = () => {
  return new Promise((resolve, reject) => {
    let response = null

    ;(async () => {
      try {
        response = await agilite.Keywords.getValuesByProfileKey('consultation_types')
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const readBillingRecords = (qry) => {
  return new Promise((resolve, reject) => {
    let response = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute('billing_records', 'read', { filter: JSON.stringify(qry) })
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const createBillingRecord = (data) => {
  return new Promise((resolve, reject) => {
    let response = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute('billing_records', 'create', { data: JSON.stringify(data) })
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const countBillingRecords = (filter) => {
  return new Promise((resolve, reject) => {
    let response = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute('billing_records', 'count', { filter: JSON.stringify(filter) })
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const updateBillingRecord = (recordId, data) => {
  return new Promise((resolve, reject) => {
    let response = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute('billing_records', 'update', {
          recordId,
          data: JSON.stringify(data)
        })
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const deleteBillingRecord = (recordId) => {
  return new Promise((resolve, reject) => {
    let response = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute('billing_records', 'delete', { recordId })
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}
