import { message } from 'antd'
import React, { useEffect, useState } from 'react'
import PatientDataDisplay from './examination-data-display'
import { handleError } from '../../lib/utils'
import { readClinicalEntries } from '../examination-utils/examination-lib'

const ExaminationWrapper = ({ bookingData, userRef }) => {
  const [bookingWebSocket, setBookingWebSocket] = useState({
    connected: false,
    socket: null
  })
  const [loadingPatientData, setLoadingPatientData] = useState(false)
  const [patientDataEntries, setPatientDataEntries] = useState([])
  const [otherDataEntries, setOtherDataEntries] = useState([])
  const [currentTemplate, setCurrentTemplate] = useState(null)

  useEffect(() => {
    if (bookingData) {
      const bookingSocket = new WebSocket(`${process.env.REACT_APP_NODE_RED_WS_URL}/bookings`)

      bookingSocket.onopen = () => {
        setBookingWebSocket({
          connected: true,
          socket: bookingSocket
        })
      }

      bookingSocket.onmessage = (event) => {
        if (JSON.parse(event.data).bookingRef === bookingData._id) {
          setPatientDataEntries((prev) => [...prev, ...JSON.parse(event.data).data])
        }
      }

      bookingSocket.onerror = (error) => {
        handleError(error, true)
      }

      return () => {
        bookingSocket.close()

        setBookingWebSocket({
          connected: false,
          socket: null
        })
      }
    }
    // eslint-disable-next-line
  }, [patientDataEntries])

  const fetchPatientData = async () => {
    let data = []
    let tmpOtherEntries = []
    let tmpPatientEntries = []

    setLoadingPatientData(true)

    try {
      data = await readClinicalEntries({
        userRef: bookingData ? bookingData.userRef : userRef
      })

      tmpPatientEntries = data.filter((entry) => currentTemplate?.keys?.includes(entry.key))
      tmpOtherEntries = data.filter((entry) => !currentTemplate?.keys?.includes(entry.key))

      setPatientDataEntries(tmpPatientEntries)
      setOtherDataEntries(tmpOtherEntries)
    } catch (e) {
      message.error(handleError(e, true))
    }

    setLoadingPatientData(false)
  }

  return (
    <>
      <PatientDataDisplay
        fetchPatientData={fetchPatientData}
        loadingPatientData={loadingPatientData}
        setLoadingPatientData={setLoadingPatientData}
        patientDataEntries={patientDataEntries}
        setPatientDataEntries={setPatientDataEntries}
        otherDataEntries={otherDataEntries}
        setOtherDataEntries={setOtherDataEntries}
        currentTemplate={currentTemplate}
        setCurrentTemplate={setCurrentTemplate}
        bookingWebSocket={bookingWebSocket}
        bookingData={bookingData}
        userRef={userRef}
      />
    </>
  )
}

export default ExaminationWrapper
