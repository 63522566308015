import CoreEnums from './core/utils/enums'

// Auth
import AuthReducer from './custom/Auth/utils/reducer'

// Home
import MetaClinicDashboard from './custom/Dashboard/meta-clinic-dashboard'

// Entities
import EntityWrapper from './custom/Admin/entities/components/entities-wrapper'

// Admin
import Clinics from './custom/Admin/clinics/components/clinics-wrapper'
import ClinicsReducer from './custom/Admin/clinics/utils/reducer'
import MedicalProfessionalReducer from './custom/Admin/medical-professionals/utils/reducer'

// Patient
import PatientsReducer from './custom/Admin/patients/utils/reducer'

//Services
import Services from './custom/Admin/services/components/services-wrapper'
import ServicesReducer from './custom/Admin/services/utils/reducer'

// System Users
import SystemUsers from './custom/Admin/system-users/components/system-users-container'
import SystemUsersReducer from './custom/Admin/system-users/utils/reducer'

// User
import Profile from './custom/Profile/components/Profile'

// Medical History
import MedicalHistoryWrapper from './custom/Medical Vault/medical-history/components/medical-history-wrapper'

import DiagnosisHistoryWrapper from './custom/Medical Vault/diagnosis-history/components/diagnosis-history-wrapper'
import BookingsWrapper from './custom/Bookings/components/booking-wrapper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBarChart,
  faBook,
  faBookMedical,
  faBusinessTime,
  faCalendar,
  faClinicMedical,
  faCogs,
  faCreditCard,
  faDatabase,
  faFaceSmile,
  faGear,
  faHeartPulse,
  faKitMedical,
  faList,
  faMoneyBillTransfer,
  faMortarBoard,
  faNewspaper,
  faPrescriptionBottleMedical,
  faTableCells,
  faUserDoctor,
  faUserGear,
  faUserGroup,
  faUsers
} from '@fortawesome/free-solid-svg-icons'

// Terms and Conditions
import TermsConditionsWrapper from './custom/Admin/terms-conditions/components/terms-conditions-wrapper'

// Availability
// import Availability from './custom/Bookings/availability/components/availability'

// Config
import Config from './custom/Admin/config/components'
import PatientExaminationForm from './custom/Bookings/components/booking-examination-wrapper'

// Bookings
import BookingsReducer from './custom/Bookings/utils/booking-reducer'
import AdminBookingsReducer from './custom/Admin/bookings/utils/reducer'
import BookingsListView from './custom/Admin/bookings/components/bookings-wrapper'
import Dependants from './custom/Profile/components/dependants/dependants-list-view'
import AvailabilityWrapper from './custom/Availability/components/availability-wrapper'

import PatientAnalLyticsWrapper from './custom/Analytics/Patients/patient-analytics-wrapper'
import {
  BarChartOutlined,
  BuildFilled,
  CalendarFilled,
  CalendarOutlined,
  FileAddOutlined,
  HomeOutlined,
  LineChartOutlined,
  ScheduleOutlined,
  TeamOutlined,
  UserOutlined,
  UsergroupAddOutlined
} from '@ant-design/icons'

import TodaysPatientsReducer from './custom/Dashboard/Employee/components/todays-patients/todays-patients-reducer'
import NewBooking from './custom/Bookings/components/booking-search-wrapper'
import MedicalHistoryReducer from './custom/Medical Vault/medical-history/utils/medical-history-reducer'
import EmployeeBookingsReducer from './custom/Bookings/utils/employee-bookings-reducer'
import BillingReducer from './custom/Bookings/utils/billing-reducer'
import DependentsReducer from './custom/Profile/components/dependants/utils/depentdents-reducer'
import DiagnosisHistoryReducer from './custom/Medical Vault/diagnosis-history/utils/diagnosis-history-reducer'
import coreReducer from './core/utils/reducer'
import EntitiesReducer from './custom/Admin/entities/utils/reducer'
import VirtualServicesWrapper from './custom/Admin/virutal-services/components/virtual-services-wrapper'
import virtualServicesReducer from './custom/Admin/virutal-services/utils/reducer'
import HealthTrackingEntriesView from './custom/Health-Tracking/entries/components/list-view'
import InventoryWrapper from './custom/Admin/Inventory/inventory-wrapper'
import SubscriptionsReducer from './custom/Subscriptions/utils/reducer'
import SubscriptionsList from './custom/Subscriptions/components/subscriptions-list'
import VitalLink from './custom/Vital-Link/components/vital-link'
import OccupationalHealthWrapper from './custom/Admin/occupational-health/occu-health-wrapper'
import CompContrReducer from './custom/Admin/occupational-health/companies-contractors/utils/reducer'
import CompContrWrapper from './custom/Admin/occupational-health/companies-contractors/components/comp-contr-wrapper'
import CustomDiagnosisWrapper from './custom/Admin/custom-diagnosis/components/custom-diagnosis-wrapper'
import ScriptsWrapper from './custom/Scripting/components/scripting-wrapper'
import AdminSubscriptionsList from './custom/Admin-Subscriptions/components/admin-subscriptions-list'
import AdminSubscriptionsReducer from './custom/Admin-Subscriptions/utils/reducer'
import AppointmentsCalendarReducer from './custom/Appointments-Calendar/utils/appointments-calendar-reducer'
import FullCalendarWrapper from './custom/Appointments-Calendar/full-calendar-wrapper'
import ProfileEnums from './custom/Profile/utils/enums'
import CRMWrapper from './custom/crm/crm-wrapper'
import DataPointsWrapper from './custom/Super-Admin/Data Points/data-points-wrapper'
import DataPointTemplateWrapper from './custom/Data-Point-Templates/components/wrapper'
import ClinicalReportingTemplatesWrapper from './custom/Super-Admin/Clinical Report Templates/clinical-reports-templates-wrapper'
import ClinicalAnalytics from './custom/Clinical-Analytics/components.js/clinical_analytics'

export const leftMenuItems = () => {
  return [
    {
      key: CoreEnums.tabKeys.HOME,
      label: CoreEnums.tabTitles.HOME,
      icon: <HomeOutlined />,
      visibleTo: [
        CoreEnums.userRoles.PATIENT,
        CoreEnums.userRoles.MEDICAL_PROFESSIONAL,
        CoreEnums.userRoles.RECEPTION,
        CoreEnums.userRoles.ADMIN,
        CoreEnums.userRoles.SUBSCRIPTION_ADMIN
      ]
    },
    {
      key: CoreEnums.tabKeys.CLINICAL_ANALYTICS,
      label: CoreEnums.tabTitles.CLINICAL_ANALYTICS,
      icon: <BarChartOutlined />,
      visibleTo: [CoreEnums.userProfessions.DOCTOR, CoreEnums.userRoles.ADMIN]
    },
    {
      key: CoreEnums.tabKeys.MY_PROFILE,
      label: CoreEnums.tabTitles.MY_PROFILE,
      icon: <UserOutlined />,
      visibleTo: [
        CoreEnums.userRoles.PATIENT,
        CoreEnums.userRoles.MEDICAL_PROFESSIONAL,
        CoreEnums.userRoles.RECEPTION,
        CoreEnums.userRoles.ADMIN
      ],
      children: [
        {
          key: CoreEnums.tabKeys.PERSONAL_DETAILS,
          label: CoreEnums.tabTitles.PERSONAL_DETAILS,
          icon: <FontAwesomeIcon icon={faUserGear} />,
          visibleTo: [
            CoreEnums.userRoles.PATIENT,
            CoreEnums.userRoles.MEDICAL_PROFESSIONAL,
            CoreEnums.userRoles.RECEPTION,
            CoreEnums.userRoles.ADMIN
          ]
        },
        {
          key: CoreEnums.tabKeys.SUBSCRIPTIONS,
          label: CoreEnums.tabTitles.SUBSCRIPTIONS,
          icon: <FontAwesomeIcon icon={faCreditCard} />,
          visibleTo: [CoreEnums.userRoles.PATIENT],
          new: true
        }
      ]
    },

    {
      key: CoreEnums.tabKeys.APPOINTMENTS_CALENDAR,
      label: CoreEnums.tabTitles.APPOINTMENTS_CALENDAR,
      icon: <ScheduleOutlined />,
      visibleTo: [
        // CoreEnums.userRoles.PATIENT,
        CoreEnums.userRoles.MEDICAL_PROFESSIONAL,
        CoreEnums.userRoles.RECEPTION,
        CoreEnums.userRoles.ADMIN
        // CoreEnums.userRoles.SUBSCRIPTION_ADMIN
      ]
    },
    // {
    //   key: CoreEnums.tabKeys.SCHEDULE_APPOINTMENT,
    //   label: CoreEnums.tabTitles.SCHEDULE_APPOINTMENT,
    //   icon: <ScheduleOutlined />,
    //   visibleTo: [CoreEnums.userRoles.RECEPTION]
    // },
    {
      key: CoreEnums.tabKeys.BILLING,
      label: CoreEnums.tabTitles.BILLING,
      icon: <FontAwesomeIcon icon={faMoneyBillTransfer} />,
      visibleTo: [CoreEnums.userRoles.RECEPTION]
    },
    {
      key: CoreEnums.tabKeys.MY_BOOKINGS,
      label: CoreEnums.tabTitles.MY_BOOKINGS,
      icon: <CalendarOutlined />,
      visibleTo: [CoreEnums.userRoles.PATIENT, CoreEnums.userRoles.MEDICAL_PROFESSIONAL, CoreEnums.userRoles.RECEPTION]
    },
    {
      key: CoreEnums.tabKeys.AVAILABILITY,
      label: CoreEnums.tabTitles.AVAILABILITY,
      icon: <CalendarFilled />,
      visibleTo: [CoreEnums.userRoles.MEDICAL_PROFESSIONAL],
      additionalValidatiors: [ProfileEnums.profileValidators.hasAvailabilityAccess]
    },
    {
      key: CoreEnums.tabKeys.MY_HEALTH,
      label: CoreEnums.tabTitles.MY_HEALTH,
      icon: <BarChartOutlined />,
      visibleTo: [CoreEnums.userRoles.PATIENT],
      children: [
        {
          key: CoreEnums.tabKeys.ANALYTICS,
          label: CoreEnums.tabTitles.ANALYTICS,
          icon: <FontAwesomeIcon icon={faBarChart} />,
          visibleTo: [CoreEnums.userRoles.PATIENT]
        },
        {
          key: CoreEnums.tabKeys.DIAGNOSIS_HISTORY,
          label: CoreEnums.tabTitles.DIAGNOSIS_HISTORY,
          icon: <FontAwesomeIcon icon={faBookMedical} />,
          visibleTo: [CoreEnums.userRoles.PATIENT]
        }
      ]
    },
    {
      key: CoreEnums.tabKeys.DEPENDANTS,
      label: CoreEnums.tabTitles.DEPENDANTS,
      icon: <UsergroupAddOutlined />,
      visibleTo: [CoreEnums.userRoles.PATIENT]
    },
    {
      key: CoreEnums.tabKeys.MEDICAL_HISTORY,
      label: CoreEnums.tabTitles.MEDICAL_HISTORY,
      icon: <FileAddOutlined />,
      visibleTo: [CoreEnums.userRoles.PATIENT]
    },
    {
      key: CoreEnums.tabKeys.HEALTH_TRACKING_ENTRIES,
      label: CoreEnums.tabTitles.HEALTH_TRACKING_ENTRIES,
      icon: <LineChartOutlined />,
      visibleTo: []
    },
    {
      key: CoreEnums.tabKeys.VITAL_LINK,
      label: CoreEnums.tabTitles.VITAL_LINK,
      new: true,
      icon: <FontAwesomeIcon icon={faHeartPulse} />,
      visibleTo: [CoreEnums.userRoles.PATIENT],
      propInEntity: CoreEnums.tabKeys.VITAL_LINK,
      enabledProp: 'enabled'
    },

    // ADMINISTRATIVE MENU ITEMS
    {
      key: CoreEnums.tabKeys.OCCUPATIONAL_HEALTH,
      label: CoreEnums.tabTitles.OCCUPATIONAL_HEALTH,
      icon: <FontAwesomeIcon icon={faBusinessTime} />,
      visibleTo: [CoreEnums.userRoles.ADMIN],
      children: [
        {
          key: CoreEnums.tabKeys.OCCUPATIONAL_HEALTH_DASHBOARD,
          label: CoreEnums.tabTitles.OCCUPATIONAL_HEALTH_DASHBOARD,
          icon: <FontAwesomeIcon icon={faUserGear} />,
          visibleTo: [CoreEnums.userRoles.ADMIN]
        },
        {
          key: CoreEnums.tabKeys.COMPANIES_CONTRACTORS,
          label: CoreEnums.tabTitles.COMPANIES_CONTRACTORS,
          icon: <FontAwesomeIcon icon={faCreditCard} />,
          visibleTo: [CoreEnums.userRoles.ADMIN]
        }
      ]
    },
    {
      key: CoreEnums.tabKeys.ADMIN_SUBSCRIPTIONS,
      label: CoreEnums.tabTitles.ADMIN_SUBSCRIPTIONS,
      icon: <FontAwesomeIcon icon={faCreditCard} />,
      visibleTo: [CoreEnums.userRoles.SUBSCRIPTION_ADMIN]
    },
    {
      key: CoreEnums.tabKeys.CLINIC_MANAGEMENT,
      label: <span id='clinic_management_category'>{CoreEnums.tabTitles.CLINIC_MANAGEMENT}</span>,
      icon: <FontAwesomeIcon icon={faClinicMedical} />,
      visibleTo: [CoreEnums.userRoles.ADMIN],
      children: [
        {
          key: CoreEnums.tabKeys.CLINICS,
          label: <span id='clinics_menu_item'>{CoreEnums.tabTitles.CLINICS}</span>,
          icon: <FontAwesomeIcon icon={faClinicMedical} />,
          visibleTo: [CoreEnums.userRoles.ADMIN]
        },
        {
          key: CoreEnums.tabKeys.SERVICES,
          label: <span id='services_menu_item'>{CoreEnums.tabTitles.SERVICES}</span>,
          icon: <FontAwesomeIcon icon={faList} />,
          visibleTo: [CoreEnums.userRoles.ADMIN]
        },
        {
          key: CoreEnums.tabKeys.AVAILABILITY,
          label: CoreEnums.tabTitles.AVAILABILITY,
          icon: <FontAwesomeIcon icon={faCalendar} />,
          visibleTo: [CoreEnums.userRoles.ADMIN]
        },
        {
          key: CoreEnums.tabKeys.BOOKINGS,
          label: CoreEnums.tabTitles.BOOKINGS,
          icon: <FontAwesomeIcon icon={faBook} />,
          visibleTo: [CoreEnums.userRoles.ADMIN]
        },
        {
          key: CoreEnums.tabKeys.INVENTORY,
          label: CoreEnums.tabTitles.INVENTORY,
          icon: <FontAwesomeIcon icon={faList} />,
          visibleTo: [CoreEnums.userRoles.ADMIN]
        },
        {
          key: CoreEnums.tabKeys.CUSTOM_DIAGNOSIS,
          label: CoreEnums.tabTitles.CUSTOM_DIAGNOSIS,
          icon: <FontAwesomeIcon icon={faKitMedical} />,
          visibleTo: [CoreEnums.userRoles.ADMIN]
        },
        {
          key: CoreEnums.tabKeys.DATA_POINT_TEMPLATES,
          label: CoreEnums.tabTitles.DATA_POINT_TEMPLATES,
          icon: <FontAwesomeIcon icon={faTableCells} />,
          visibleTo: [CoreEnums.userRoles.ADMIN, CoreEnums.userProfessions.DOCTOR]
        }
      ]
    },
    {
      key: CoreEnums.tabKeys.USER_MANAGEMENT,
      label: <span id='user_management_category'>{CoreEnums.tabTitles.USER_MANAGEMENT}</span>,
      icon: <FontAwesomeIcon icon={faUsers} />,
      visibleTo: [CoreEnums.userRoles.ADMIN, CoreEnums.userRoles.SUPERADMIN],
      children: [
        {
          key: CoreEnums.tabKeys.SYSTEM_USERS,
          label: <span id='system_users_menu_item'>{CoreEnums.tabTitles.SYSTEM_USERS}</span>,
          icon: <FontAwesomeIcon icon={faUsers} />,
          visibleTo: [CoreEnums.userRoles.ADMIN, CoreEnums.userRoles.SUPERADMIN]
        },
        {
          key: CoreEnums.tabKeys.MEDICAL_PROFESSIONALS,
          label: <span id='medical_professionals_menu_item'>{CoreEnums.tabTitles.MEDICAL_PROFESSIONALS}</span>,
          icon: <FontAwesomeIcon icon={faUserDoctor} />,
          visibleTo: [CoreEnums.userRoles.ADMIN, CoreEnums.userRoles.SUPERADMIN]
        },
        {
          key: CoreEnums.tabKeys.PATIENTS,
          label: <span id='patients_menu_item'>{CoreEnums.tabTitles.PATIENTS}</span>,
          icon: <TeamOutlined />,
          visibleTo: [
            CoreEnums.userRoles.ADMIN,
            CoreEnums.userRoles.RECEPTION,
            CoreEnums.userRoles.MEDICAL_PROFESSIONAL,
            CoreEnums.userRoles.SUPERADMIN
          ]
        }
      ]
    },

    {
      key: CoreEnums.tabKeys.PATIENTS,
      label: CoreEnums.tabTitles.PATIENTS,
      icon: <TeamOutlined />,
      visibleTo: [CoreEnums.userRoles.RECEPTION, CoreEnums.userRoles.MEDICAL_PROFESSIONAL]
    },
    {
      key: CoreEnums.tabKeys.SCRIPTING,
      label: CoreEnums.tabTitles.SCRIPTING,
      icon: <FontAwesomeIcon icon={faPrescriptionBottleMedical} />,
      visibleTo: [CoreEnums.userProfessions.DOCTOR]
    },
    {
      key: CoreEnums.tabKeys.ENTITIES,
      label: CoreEnums.tabTitles.ENTITIES,
      icon: <BuildFilled />,
      visibleTo: [CoreEnums.userRoles.SUPERADMIN]
    },

    {
      key: CoreEnums.tabKeys.VIRTUAL_SERVICES,
      label: CoreEnums.tabTitles.VIRTUAL_SERVICES,
      icon: <FontAwesomeIcon icon={faList} />,
      visibleTo: [CoreEnums.userRoles.SUPERADMIN]
    },

    {
      key: CoreEnums.tabKeys.SETTINGS,
      label: CoreEnums.tabTitles.SETTINGS,
      icon: <FontAwesomeIcon icon={faCogs} />,
      visibleTo: [CoreEnums.userRoles.ADMIN],
      children: [
        {
          key: CoreEnums.tabKeys.CONFIG,
          label: CoreEnums.tabTitles.CONFIG,
          icon: <FontAwesomeIcon icon={faGear} />,
          visibleTo: [CoreEnums.userRoles.ADMIN]
        },
        {
          key: CoreEnums.tabKeys.TERMS_AND_CONDITIONS,
          label: CoreEnums.tabTitles.TERMS_AND_CONDITIONS,
          icon: <FontAwesomeIcon icon={faNewspaper} />,
          visibleTo: [CoreEnums.userRoles.ADMIN]
        }
      ]
    },
    {
      key: CoreEnums.tabKeys.CRM,
      label: CoreEnums.tabTitles.CRM,
      icon: <FontAwesomeIcon icon={faFaceSmile} />,
      visibleTo: [CoreEnums.userRoles.RECEPTION]
    },
    {
      key: CoreEnums.tabKeys.DATA_POINTS,
      label: CoreEnums.tabTitles.DATA_POINTS,
      icon: <FontAwesomeIcon icon={faDatabase} />,
      visibleTo: [CoreEnums.userRoles.SUPERADMIN]
    },
    {
      key: CoreEnums.tabKeys.POC_DATA_POINTS,
      label: CoreEnums.tabTitles.POC_DATA_POINTS,
      icon: <FontAwesomeIcon icon={faDatabase} />,
      visibleTo: [CoreEnums.userRoles.SUPERADMIN]
    },
    {
      key: CoreEnums.tabKeys.LAB_DATA_POINTS,
      label: CoreEnums.tabTitles.LAB_DATA_POINTS,
      icon: <FontAwesomeIcon icon={faDatabase} />,
      visibleTo: [CoreEnums.userRoles.SUPERADMIN]
    },

    {
      key: CoreEnums.tabKeys.EXAMINATION_TEMPLATES,
      label: CoreEnums.tabTitles.EXAMINATION_TEMPLATES,
      icon: <FontAwesomeIcon icon={faDatabase} />,
      visibleTo: [CoreEnums.userRoles.MEDICAL_PROFESSIONAL, CoreEnums.userRoles.ADMIN, CoreEnums.userRoles.SUPERADMIN],
      children: [
        {
          key: CoreEnums.tabKeys.CLINICAL_REPORTING_TEMPLATES,
          label: CoreEnums.tabTitles.CLINICAL_REPORTING_TEMPLATES,
          icon: <FontAwesomeIcon icon={faDatabase} />,
          visibleTo: [CoreEnums.userRoles.SUPERADMIN]
        },
        {
          key: CoreEnums.tabKeys.DATA_POINT_TEMPLATES,
          label: CoreEnums.tabTitles.DATA_POINT_TEMPLATES,
          icon: <FontAwesomeIcon icon={faDatabase} />,
          visibleTo: [CoreEnums.userRoles.MEDICAL_PROFESSIONAL, CoreEnums.userRoles.ADMIN]
        }
      ]
    }
  ]
}

export const rightMenuItems = [
  {
    key: CoreEnums.tabKeys.CLINICS,
    label: (
      <>
        <FontAwesomeIcon icon={faClinicMedical} style={{ width: 50 }} /> {CoreEnums.tabTitles.CLINICS}
      </>
    )
  },
  {
    key: CoreEnums.tabKeys.SERVICES,
    label: (
      <>
        <FontAwesomeIcon icon={faMortarBoard} style={{ width: 50 }} /> {CoreEnums.tabTitles.SERVICES}
      </>
    )
  },
  {
    key: CoreEnums.tabKeys.AVAILABILITY,
    label: (
      <>
        <FontAwesomeIcon icon={faCalendar} style={{ width: 50 }} /> {CoreEnums.tabTitles.AVAILABILITY}
      </>
    )
  },
  {
    key: CoreEnums.tabKeys.SYSTEM_USERS,
    label: (
      <>
        <FontAwesomeIcon icon={faUserGear} style={{ width: 50 }} /> {CoreEnums.tabTitles.SYSTEM_USERS}
      </>
    )
  },
  {
    key: CoreEnums.tabKeys.MEDICAL_PROFESSIONALS,
    label: (
      <>
        <FontAwesomeIcon icon={faUserDoctor} style={{ width: 50 }} /> {CoreEnums.tabTitles.MEDICAL_PROFESSIONALS}
      </>
    )
  },
  {
    key: CoreEnums.tabKeys.PATIENTS,
    label: (
      <>
        <FontAwesomeIcon icon={faUserGroup} style={{ width: 50 }} /> {CoreEnums.tabTitles.PATIENTS}
      </>
    )
  },
  {
    key: CoreEnums.tabKeys.BOOKINGS,
    label: (
      <>
        <FontAwesomeIcon icon={faBook} style={{ width: 50 }} /> {CoreEnums.tabTitles.BOOKINGS}
      </>
    )
  },
  {
    key: CoreEnums.tabKeys.CONFIG,
    label: (
      <>
        <FontAwesomeIcon icon={faCogs} style={{ width: 50 }} /> {CoreEnums.tabTitles.CONFIG}
      </>
    )
  },
  {
    key: CoreEnums.tabKeys.TERMS_AND_CONDITIONS,
    label: (
      <>
        <FontAwesomeIcon icon={faNewspaper} style={{ width: 50 }} /> {CoreEnums.tabTitles.TERMS_AND_CONDITIONS}
      </>
    )
  }
]

export const generateContent = (key) => {
  window.scrollTo(0, 0)

  switch (key) {
    // Appointments Calendar
    case CoreEnums.tabKeys.APPOINTMENTS_CALENDAR:
      return {
        key,
        label: CoreEnums.tabTitles.APPOINTMENTS_CALENDAR,
        closable: false,
        children: <FullCalendarWrapper />
      }
    // Home
    case CoreEnums.tabKeys.HOME:
      return {
        key,
        label: CoreEnums.tabTitles.HOME,
        closable: false,
        children: <MetaClinicDashboard />
      }
    // Entities
    case CoreEnums.tabKeys.ENTITIES:
      return {
        key,
        label: CoreEnums.tabTitles.ENTITIES,
        closable: false,
        children: <EntityWrapper />
      }
    // Analytics
    case CoreEnums.tabKeys.ANALYTICS:
      return {
        key,
        label: CoreEnums.tabTitles.ANALYTICS,
        closable: false,
        children: <PatientAnalLyticsWrapper />
      }
    // Admin Bookings
    case CoreEnums.tabKeys.BOOKINGS:
      return {
        key,
        label: CoreEnums.tabTitles.BOOKINGS,
        closable: true,
        children: <BookingsListView />
      }
    // Clinics
    case CoreEnums.tabKeys.CLINICS:
      return {
        key,
        label: CoreEnums.tabTitles.CLINICS,
        closable: true,
        children: <Clinics />
      }
    // Occu-health dashboard
    case CoreEnums.tabKeys.OCCUPATIONAL_HEALTH_DASHBOARD:
      return {
        key,
        label: CoreEnums.tabTitles.OCCUPATIONAL_HEALTH_DASHBOARD,
        closable: true,
        children: <OccupationalHealthWrapper />
      }
    // Companies & Contractors
    case CoreEnums.tabKeys.COMPANIES_CONTRACTORS:
      return {
        key,
        label: CoreEnums.tabTitles.COMPANIES_CONTRACTORS,
        closable: true,
        children: <CompContrWrapper />
      }
    // Services
    case CoreEnums.tabKeys.SERVICES:
      return {
        key,
        label: CoreEnums.tabTitles.SERVICES,
        closable: true,
        children: <Services />
      }
    // Virtual Services
    case CoreEnums.tabKeys.VIRTUAL_SERVICES:
      return {
        key,
        label: CoreEnums.tabTitles.VIRTUAL_SERVICES,
        closable: true,
        children: <VirtualServicesWrapper />
      }
    // System Users
    case CoreEnums.tabKeys.SYSTEM_USERS:
      return {
        key,
        label: CoreEnums.tabTitles.SYSTEM_USERS,
        closable: true,
        children: <SystemUsers type='system' />
      }
    // Medical Professionals
    case CoreEnums.tabKeys.MEDICAL_PROFESSIONALS:
      return {
        key,
        label: CoreEnums.tabTitles.MEDICAL_PROFESSIONALS,
        closable: true,
        children: <SystemUsers type='medical' />
      }
    //Patients
    case CoreEnums.tabKeys.PATIENTS:
      return {
        key,
        label: CoreEnums.tabTitles.PATIENTS,
        closable: true,
        children: <SystemUsers type='patient' />
      }
    // Inventory
    case CoreEnums.tabKeys.INVENTORY:
      return {
        key,
        label: CoreEnums.tabTitles.INVENTORY,
        closable: true,
        children: <InventoryWrapper />
      }
    // Custom Diagnosis
    case CoreEnums.tabKeys.CUSTOM_DIAGNOSIS:
      return {
        key,
        label: CoreEnums.tabTitles.CUSTOM_DIAGNOSIS,
        closable: true,
        children: <CustomDiagnosisWrapper />
      }
    // My Bookings
    case CoreEnums.tabKeys.MY_BOOKINGS:
      return {
        key,
        label: CoreEnums.tabTitles.MY_BOOKINGS,
        closable: true,
        children: <BookingsWrapper />
      }
    // Scripts
    case CoreEnums.tabKeys.SCRIPTING:
      return {
        key,
        label: CoreEnums.tabTitles.SCRIPTING,
        closable: true,
        children: <ScriptsWrapper />
      }
    //Schedule Appointment
    case CoreEnums.tabKeys.SCHEDULE_APPOINTMENT:
      return {
        key,
        label: CoreEnums.tabTitles.SCHEDULE_APPOINTMENT,
        closable: true,
        children: <NewBooking isReceptionBooking={true} />
      }
    // Billing
    case CoreEnums.tabKeys.BILLING:
      return {
        key,
        label: CoreEnums.tabTitles.BILLING,
        closable: true,
        children: <BookingsWrapper isBilling />
      }
    // My Appointments
    case CoreEnums.tabKeys.MY_APPOINTMENTS:
      return {
        key,
        label: CoreEnums.tabTitles.MY_APPOINTMENTS,
        closable: true,
        children: <BookingsWrapper />
      }
    // Diagnosis History
    case CoreEnums.tabKeys.DIAGNOSIS_HISTORY:
      return {
        key,
        label: CoreEnums.tabTitles.DIAGNOSIS_HISTORY,
        closable: true,
        children: <DiagnosisHistoryWrapper />
      }
    // User
    case CoreEnums.tabKeys.PERSONAL_DETAILS:
      return {
        key,
        label: CoreEnums.tabTitles.PERSONAL_DETAILS,
        closable: true,
        children: <Profile />
      }
    case CoreEnums.tabKeys.SUBSCRIPTIONS:
      return {
        key,
        label: CoreEnums.tabTitles.SUBSCRIPTIONS,
        closable: true,
        children: <SubscriptionsList />
      }
    case CoreEnums.tabKeys.MEDICAL_HISTORY:
      return {
        key,
        label: CoreEnums.tabTitles.MEDICAL_HISTORY,
        closable: true,
        children: <MedicalHistoryWrapper isPatient />
      }
    // Terms and Conditions
    case CoreEnums.tabKeys.TERMS_AND_CONDITIONS:
      return {
        key,
        label: CoreEnums.tabTitles.TERMS_AND_CONDITIONS,
        closable: true,
        children: <TermsConditionsWrapper />
      }
    // Dependants
    case CoreEnums.tabKeys.DEPENDANTS:
      return {
        key,
        label: CoreEnums.tabTitles.DEPENDANTS,
        closable: true,
        children: <Dependants />
      }
    // Management
    case CoreEnums.tabKeys.MANAGEMENT:
      return {
        key,
        label: CoreEnums.tabTitles.MANAGEMENT,
        closable: true,
        children: <AvailabilityWrapper isManager={true} />
      }
    // Availability
    case CoreEnums.tabKeys.AVAILABILITY:
      return {
        key,
        label: CoreEnums.tabTitles.AVAILABILITY,
        closable: true,
        children: <AvailabilityWrapper />
      }
    // Config
    case 'config':
      return {
        key,
        label: 'Settings',
        closable: true,
        children: <Config />
      }
    // Booking Form
    case 'patient_examination_form':
      return {
        key,
        label: 'Patient Examination',
        closable: true,
        children: <PatientExaminationForm />
      }
    // Health Tracking Entries
    case CoreEnums.tabKeys.HEALTH_TRACKING_ENTRIES:
      return {
        key,
        label: CoreEnums.tabTitles.HEALTH_TRACKING_ENTRIES,
        closable: true,
        children: <HealthTrackingEntriesView />
      }
    // Vital Link
    case CoreEnums.tabKeys.VITAL_LINK:
      return {
        key,
        label: CoreEnums.tabTitles.VITAL_LINK,
        closable: true,
        children: <VitalLink />
      }
    case CoreEnums.tabKeys.ADMIN_SUBSCRIPTIONS:
      return {
        key,
        label: CoreEnums.tabTitles.ADMIN_SUBSCRIPTIONS,
        closable: true,
        children: <AdminSubscriptionsList />
      }
    case CoreEnums.tabKeys.CRM:
      return {
        key,
        label: CoreEnums.tabTitles.CRM,
        closable: true,
        children: <CRMWrapper />
      }

    case CoreEnums.tabKeys.DATA_POINTS:
      return {
        key,
        label: CoreEnums.tabTitles.DATA_POINTS,
        closable: true,
        children: <DataPointsWrapper type='standard' />
      }
    case CoreEnums.tabKeys.POC_DATA_POINTS:
      return {
        key,
        label: CoreEnums.tabTitles.POC_DATA_POINTS,
        closable: true,
        children: <DataPointsWrapper type='poc' />
      }
    case CoreEnums.tabKeys.LAB_DATA_POINTS:
      return {
        key,
        label: CoreEnums.tabTitles.LAB_DATA_POINTS,
        closable: true,
        children: <DataPointsWrapper type='lab' />
      }
    case CoreEnums.tabKeys.DATA_POINT_TEMPLATES:
      return {
        key,
        label: CoreEnums.tabTitles.DATA_POINT_TEMPLATES,
        closable: true,
        children: <DataPointTemplateWrapper />
      }
    case CoreEnums.tabKeys.CLINICAL_REPORTING_TEMPLATES:
      return {
        key,
        label: CoreEnums.tabTitles.CLINICAL_REPORTING_TEMPLATES,
        closable: true,
        children: <ClinicalReportingTemplatesWrapper />
      }

    case CoreEnums.tabKeys.CLINICAL_ANALYTICS:
      return {
        key,
        label: CoreEnums.tabTitles.CLINICAL_ANALYTICS,
        closable: true,
        children: <ClinicalAnalytics />
      }
    default:
      return {
        key,
        label: 'NOT FOUND',
        closable: true,
        children: (
          <center>
            <h1>This tab does not exist</h1>
          </center>
        )
      }
  }
}

export const initCustomReducers = () => {
  return {
    // Authentication && User State
    core: coreReducer.reducer,
    auth: AuthReducer.reducer,
    medicalHistory: MedicalHistoryReducer.reducer,
    subscriptions: SubscriptionsReducer.reducer,
    adminSubscriptions: AdminSubscriptionsReducer.reducer,

    // TODO: To be removed
    medicalProfessionals: MedicalProfessionalReducer.reducer,
    patients: PatientsReducer.reducer,

    // Configuration
    entities: EntitiesReducer.reducer,
    clinics: ClinicsReducer.reducer,
    compContr: CompContrReducer.reducer,
    services: ServicesReducer.reducer,
    virtualServices: virtualServicesReducer.reducer,

    // Users
    systemUsers: SystemUsersReducer.reducer,

    // Bookings
    bookings: BookingsReducer.reducer,
    adminBookings: AdminBookingsReducer.reducer,
    todaysPatients: TodaysPatientsReducer.reducer,
    employeeBookings: EmployeeBookingsReducer.reducer,
    billing: BillingReducer.reducer,
    dependents: DependentsReducer.reducer,
    appointmentsCalendar: AppointmentsCalendarReducer.reducer,

    // Medical Vault
    diagnosisHistory: DiagnosisHistoryReducer.reducer
  }
}
