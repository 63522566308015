import React, { useCallback, useEffect, useState } from 'react'
import {
  Col,
  Row,
  theme,
  Form,
  Input,
  Button,
  message,
  Modal,
  Popover,
  Select,
  Space,
  Tabs,
  Card,
  DatePicker
} from 'antd'
import { batch, useDispatch, useSelector } from 'react-redux'
import { PhoneAuthProvider, RecaptchaVerifier, updatePhoneNumber } from 'firebase/auth'
import { firebaseAuth } from '../../../..'
import { getCountries, updateAgiliteUser } from '../../../Auth/utils/utils'
import {
  allTrim,
  generateFormItem,
  getDateOfBirthFromID,
  handleError,
  hexToRGBA,
  specialFieldValidation
} from '../../../lib/utils'
import {
  MedPraxSchemePlanOptionSearch,
  MedPraxSchemePlanSearch,
  MedPraxSchemeSearch
} from '../../../Scripting/utils/utils'
import debounce from 'lodash/debounce'
import CustomRow from '../../../reusable-components/CustomRow'
import Templates from '../../../Profile/utils/templates'
import dayjs from 'dayjs'
import GoogleAddress from '../../../reusable-components/GoogleAddress'
import AgiliteSkeleton from '../../../reusable-components/AgiliteSkeleton'
import { ContainerCard } from '../../../reusable-components/AgiliteCards'
import CustomButton from '../../../reusable-components/CustomButton'
import systemUsersReducer from '../utils/reducer'
import coreReducer from '../../../../core/utils/reducer'
import PatientAnalytics from '../../../Analytics/Patients/patient-analytics'
import ReportList from '../../Reporting/report-list'
import ScriptsWrapper from '../../../Scripting/components/scripting-wrapper'
import PatientDependants from './patient-dependants'
import BeginExaminationModal from './begin-examination-modal'
import { BookingDataTemplate } from '../../../Bookings/utils/booking-data-template'
import CoreEnums from '../../../../core/utils/enums'
import PatientExaminationForm from '../../../Bookings/components/booking-examination-wrapper'
import { createBooking } from '../../../Bookings/utils/utils'
import FeatureButton from '../../../reusable-components/FeatureButton'
import EmployeeBookingsView from '../../../Bookings/components/Booking View Components/employee-bookings-view'
import { executeBooking, registerBooking } from '../../../Bookings/utils/bpm-utils'
import store from '../../../../store'
import FollowUpBookingCapture from '../../../Examination/components/follow-up-booking-capture'
import RefferalLetterCapture from '../../../Examination/components/referral-letter-capture'
import SickNoteCapture from '../../../Examination/components/sick-note-capture'
import ExaminationWrapper from '../../../Examination/components/examination-wrapper'
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NurseScreeningMedicalHistory from '../../../Bookings/components/Examination Components/nurse-examination-medical-history'
import ClinicalNotes from '../../../Examination/components/clinical-notes'

const EditPatientProfile = ({ dataId, refreshView, handleRootTabKey, isExamination }) => {
  const dispatch = useDispatch()
  const activeRecords = useSelector((state) => state.systemUsers.activeRecords)
  const dataIndex = activeRecords.findIndex((item) => item._id === dataId)

  const [examinationModalOpen, setExaminationModalOpen] = useState(false)
  const userData = activeRecords[dataIndex]
  const authState = useSelector((state) => state.auth)
  const [loading, setLoading] = useState(false)
  const [confirmationOpen, setConfirmationOpen] = useState(false)
  const [confirmationCode, setConfirmationCode] = useState(null)
  // eslint-disable-next-line
  const [verificationId, setVerificationId] = useState(null)
  const [submitDisabled, setSubmitDisabled] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [specialFormState, setSpecialFormState] = useState([
    { key: 'firstName', value: userData.firstName },
    { key: 'lastName', value: userData.lastName }
  ])
  const [residentVisitor, setResidentVisitor] = useState(userData?.country === 'ZA' ? 'resident' : 'visitor')
  const [country, setCountry] = useState('ZA')
  const [countries, setCountries] = useState([])
  const [countriesLoading, setCountriesLoading] = useState(false)
  const [countryObject, setCountryObject] = useState()

  // Medical Aid
  const [schemeLoading, setSchemeLoading] = useState(false)
  const [schemeData, setSchemeData] = useState([])
  const [plansLoading, setPlansLoading] = useState(false)
  const [plansData, setPlansData] = useState([])
  const [planOptionsLoading, setPlanOptionsLoading] = useState(false)
  const [planOptionData, setPlanOptionData] = useState([])

  // Actions Modal Open States
  const [medicalHistoryModalOpen, setMedicalHistoryModalOpen] = useState(false)
  const [clinicalNotesModalOpen, setClinicalNotesModalOpen] = useState(false)
  const [assessmentModalOpen, setAssessmentModalOpen] = useState(false)
  const [followUpModalOpen, setFollowUpModalOpen] = useState(false)
  const [referralModalOpen, setReferralModalOpen] = useState(false)
  const [sickNoteModalOpen, setSickNoteModalOpen] = useState(false)

  useEffect(() => {
    let tmpIndex = -1

    tmpIndex = countries.findIndex((e) => e.code === country)

    if (tmpIndex > -1) {
      setCountryObject(countries[tmpIndex])
    } else {
      setCountryObject(null)
    }

    // eslint-disable-next-line
  }, [country])

  useEffect(() => {
    async function handleLoginPhoneRecaptcha() {
      window.recaptchaVerifier = new RecaptchaVerifier(firebaseAuth, 'recaptcha-container', {
        size: 'invisible',
        callback: (response) => {
          setSubmitDisabled(false)
        },
        'expired-callback': (response) => {
          setSubmitDisabled(true)
          setErrorMessage('Verification Expired. Please reload the page')
        }
      })
    }

    handleLoginPhoneRecaptcha()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    handleGetCountries()
    window.scrollTo(0, 0)
    // eslint-disable-next-line
  }, [])

  const handleGetCountries = async () => {
    let tmpCountries = []

    setCountriesLoading(true)

    try {
      tmpCountries = await getCountries()
      setCountries(tmpCountries)
    } catch (e) {
      message.error(e)
    }

    setCountriesLoading(false)
  }

  const formatNumber = (phoneNumber) => {
    if (phoneNumber.charAt(0) === '0') {
      phoneNumber = `${countryObject ? countryObject.phoneCode : '+27'}${phoneNumber.slice(1, 10)}`
    } else {
      phoneNumber = `${countryObject ? countryObject.phoneCode : '+27'}${phoneNumber}`
    }

    return phoneNumber
  }

  const handleSubmit = async () => {
    const formData = allTrim(form.getFieldsValue())
    setLoading(true)

    try {
      if (!formData.phoneNumber && userData.phoneNumber) {
        setLoading(false)
        return message.error('You cannot remove patient cellphone number, please revise')
      }

      // Check if phone number has changed

      await handleSubmitExtended()
    } catch (e) {
      message.error(handleError(e, true))
    }
    setLoading(false)
  }

  const handleSubmitExtended = async () => {
    const formData = allTrim(form.getFieldsValue())
    let phoneNumber = form.getFieldValue('phoneNumber')

    try {
      if (phoneNumber) {
        phoneNumber = formatNumber(phoneNumber)
        form.setFieldValue('phoneNumber', phoneNumber.replace(countryObject ? countryObject.phoneCode : '+27', ''))
      }

      let newRecord = await updateAgiliteUser(userData._id, {
        ...userData,
        ...formData,
        phoneNumber,
        extraData: {
          ...userData.extraData,
          ...formData.extraData
        }
      })

      if (isExamination) {
        refreshView('success', newRecord)
      } else {
        refreshView()
        closeTab()
      }
      message.success('Profile updated successfully.')
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  const handleLoginPhoneCode = async () => {
    let phoneCredential = null

    setLoading(true)

    try {
      setConfirmationOpen(false)

      phoneCredential = PhoneAuthProvider.credential(verificationId, confirmationCode)
      await updatePhoneNumber(firebaseAuth.currentUser, phoneCredential)
      await handleSubmitExtended()
    } catch (e) {
      setConfirmationOpen(true)
      message.error(handleError(e, true))
    }

    setConfirmationCode('')
    setLoading(false)
  }

  const handleGetMedicalAids = async (query) => {
    let tmpData = []

    setSchemeLoading(true)

    try {
      if (query) {
        tmpData = await MedPraxSchemeSearch(query)
        setSchemeData(tmpData)
      } else {
        setSchemeData([])
      }
    } catch (e) {
      message.error(handleError(e, true))
    }

    setSchemeLoading(false)
  }

  const handleGetMedicalAidPlans = async (query) => {
    let tmpData = []

    setPlansLoading(true)

    try {
      if (query) {
        tmpData = await MedPraxSchemePlanSearch(query)
        setPlansData(tmpData)
      } else {
        setPlansData([])
      }
    } catch (e) {
      message.error(handleError(e, true))
    }

    setPlansLoading(false)
  }

  const handleGetMedicalAidPlanOptions = async (query) => {
    let tmpData = []

    setPlanOptionsLoading(true)

    try {
      if (query) {
        tmpData = await MedPraxSchemePlanOptionSearch(query)
        setPlanOptionData(tmpData)
      } else {
        setPlanOptionData([])
      }
    } catch (e) {
      message.error(handleError(e, true))
    }

    setPlanOptionsLoading(false)
  }

  // eslint-disable-next-line
  const debouncedSchemeFilter = useCallback(
    debounce((query) => {
      if (query) {
        handleGetMedicalAids(query)
      } else {
        setSchemeLoading(false)
      }
    }, 1000),
    []
  )

  const { token } = theme.useToken()
  const [form] = Form.useForm()

  const closeTab = () => {
    let tmpActiveRecords = activeRecords.concat()

    tmpActiveRecords = tmpActiveRecords.filter((i) => i._id !== dataId)

    refreshView()
    batch(() => {
      dispatch(systemUsersReducer.actions.setActiveRecords(tmpActiveRecords))
      dispatch(
        coreReducer.actions.closeTab({
          targetKey: `${handleRootTabKey()}_${dataId}`,
          removeBreadcrumb: true
        })
      )
    })
  }

  const beginAdhocScreening = async (data) => {
    // setLoading(true)
    let record = BookingDataTemplate(activeRecords[dataIndex], JSON.parse(data.clinic), {
      chiefComplaint: data.ailment,
      ailment: data.ailment,
      patientSelection: data.patientSelection,
      patientInput: data.patientInput,
      paymentMethod: 'No Appointment',
      service: authState.agiliteUser.extraData.services[0],
      processRef: null,
      status: null
    })
    let bpmProcess = null
    let processUser = `${authState.agiliteUser.firstName} ${authState.agiliteUser.lastName}`

    try {
      bpmProcess = await registerBooking(undefined, processUser)

      // New To Checkin
      bpmProcess = await executeBooking(undefined, bpmProcess.recordId, 'submit', processUser, bpmProcess.key)
      // Checkin To Screening
      bpmProcess = await executeBooking(undefined, bpmProcess.recordId, 'submit', processUser, bpmProcess.key)

      if (store.getState().auth.agiliteUser.extraData.profession === 'doctor') {
        // Screening To Diagnosis
        bpmProcess = await executeBooking(undefined, bpmProcess.recordId, 'submit', processUser, bpmProcess.key)
      }

      record.processRef = bpmProcess.recordId
      record.status = bpmProcess.processStage

      record = { ...record, ...(await createBooking(record)) }

      dispatch(
        coreReducer.actions.addTab({
          key: `${CoreEnums.tabKeys.MY_BOOKINGS}_${record._id}`,
          closable: true,
          label: `Booking: ${record.patientName}`,
          children: <PatientExaminationForm data={record} noAppointment={true} />
        })
      )
      setExaminationModalOpen(false)
    } catch (e) {
      message.error(handleError(e, true))
    }
    // setLoading(false)
  }

  const handleModalReset = () => {
    setFollowUpModalOpen(false)
    setReferralModalOpen(false)
    setSickNoteModalOpen(false)
  }

  return (
    <ContainerCard
      backFunction={isExamination ? null : () => closeTab()}
      title='Edit Patient Profile'
      style={{ paddingBottom: 12 }}
      headStyle={isExamination ? { display: 'none' } : {}}
    >
      {authState.agiliteUser.extraData.role.type === 'medical_professional' && !isExamination ? (
        <Card size='small' style={{ marginBottom: 12 }} type='inner'>
          <CustomRow>
            <Col span={24}>
              <Space wrap style={{ width: '100%' }}>
                <FeatureButton
                  background='linear-gradient(45deg, #2E7D32, #4CAF50)'
                  backgroundHover='#2E7D32'
                  text='NEW EXAMINATION'
                  onClick={() => setExaminationModalOpen(true)}
                />
                <FeatureButton
                  background='linear-gradient(45deg,  #FF3D00, #FF6F61)' // Unique coral gradient
                  backgroundHover='#FF3D00'
                  text='CLINICAL NOTES'
                  onClick={() => setClinicalNotesModalOpen(true)}
                />
                <FeatureButton
                  background='linear-gradient(45deg, #1E90FF, #00BFFF)' // Unique blue gradient
                  backgroundHover='#1E90FF'
                  text='MEDICAL HISTORY'
                  onClick={() => setMedicalHistoryModalOpen(true)}
                />
                <FeatureButton
                  background='linear-gradient(45deg, #DC143C, #FF7F50)'
                  backgroundHover='#DC143C'
                  text='ASSESSMENT'
                  onClick={() => setAssessmentModalOpen(true)}
                />
                <FeatureButton
                  background='linear-gradient(45deg, #50C878,  #40E0D0)'
                  backgroundHover='#50C878'
                  text='REFERRAL'
                  onClick={() => setReferralModalOpen(true)}
                />
                <FeatureButton
                  background='linear-gradient(45deg, #FF1493, #FF69B4)'
                  backgroundHover='#FF1493'
                  text='SICK NOTE'
                  onClick={() => setSickNoteModalOpen(true)}
                />
                <FeatureButton
                  background='linear-gradient(45deg, #FFA500, #FFD700'
                  backgroundHover='#FFA500'
                  text='FOLLOW-UP'
                  onClick={() => setFollowUpModalOpen(true)}
                />
              </Space>
            </Col>
          </CustomRow>
        </Card>
      ) : undefined}
      <Tabs hideAdd type='card'>
        <Tabs.TabPane key='patientProfile' tab='Patient Profile'>
          <CustomRow gutter={[8, 8]}>
            <Col span={24}>
              <Form
                onFinishFailed={(e) => {
                  message.error(e.errorFields[0].errors[0])
                }}
                onFinish={handleSubmit}
                disabled={loading}
                form={form}
                layout='vertical'
                initialValues={{
                  ...userData,
                  phoneNumber: userData.phoneNumber ? userData.phoneNumber.replace('+27', '') : null,
                  dateOfBirth: userData.dateOfBirth ? dayjs(userData.dateOfBirth) : null,
                  residentVisitor: residentVisitor,
                  medicalAid: {
                    name: userData.medicalAid?.name ? userData.medicalAid.name : undefined,
                    schemeCode: userData.medicalAid?.schemeCode ? userData.medicalAid.schemeCode : undefined,
                    plan: userData.medicalAid?.plan ? userData.medicalAid.plan : undefined,
                    planCode: userData.medicalAid?.planCode ? userData.medicalAid.planCode : undefined,
                    planOption: userData.medicalAid?.planOption ? userData.medicalAid.planOption : undefined,
                    planOptionCode: userData.medicalAid?.planOptionCode
                      ? userData.medicalAid.planOptionCode
                      : undefined,
                    number: userData.medicalAid?.number ? userData.medicalAid.number : '',
                    dependantNumber: userData.medicalAid?.dependantNumber ? userData.medicalAid.dependantNumber : ''
                  }
                }}
              >
                <CustomRow justify='end' gutter={[8, 8]}>
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <CustomRow gutter={[8, 8]}>
                      <Col span={12}>
                        <CustomRow className='basic-card' gutter={[8, 8]}>
                          <Col span={24}>
                            <h1 style={{ padding: 8, background: hexToRGBA(token.colorPrimary, 0.2) }}>
                              Patient Details
                            </h1>
                          </Col>
                          <Col span={24}>
                            <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', gap: 8 }}>
                              {generateFormItem(
                                Templates.dataModel.firstName,
                                form,
                                specialFormState,
                                setSpecialFormState
                              )}
                              {generateFormItem(
                                Templates.dataModel.lastName,
                                form,
                                specialFormState,
                                setSpecialFormState
                              )}
                            </div>
                          </Col>
                          <Col span={24}>
                            <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', gap: 8 }}>
                              {generateFormItem(Templates.dataModel.idNo, form, null, null, (idValue) => {
                                let dateOfBirth = getDateOfBirthFromID(idValue)
                                if (dateOfBirth) {
                                  form.setFieldValue('dateOfBirth', dayjs(dateOfBirth))
                                }
                              })}

                              <Form.Item label={<b style={{ fontSize: 15 }}>Date of Birth</b>} name='dateOfBirth'>
                                <DatePicker />
                              </Form.Item>
                              {generateFormItem(Templates.dataModel.gender, form, null, null)}
                              {generateFormItem(Templates.dataModel.race, form, null, null)}
                            </div>
                          </Col>
                          <Col span={24}>
                            <h1 style={{ padding: 8, background: hexToRGBA(token.colorPrimary, 0.2) }}>
                              Contact Details
                            </h1>
                          </Col>
                          <Col span={24}>
                            <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', gap: 8 }}>
                              <Form.Item
                                label={<b style={{ fontSize: 15 }}>Email</b>}
                                name='email'
                                style={{ marginTop: 0, marginBottom: 0, width: 'calc(50% - 4px)', minWidth: 200 }}
                              >
                                <Input placeholder='e.g. jane.doe@gmail.com' />
                              </Form.Item>
                              <Popover
                                placement='topRight'
                                content='One-time Pin will be sent to your Cellphone'
                                title={<span style={{ color: token.colorPrimary }}>Note</span>}
                                trigger='focus'
                              >
                                <Form.Item
                                  label={<b style={{ fontSize: 15 }}>Phone</b>}
                                  name='phoneNumber'
                                  style={{ marginTop: 0, marginBottom: 0, width: 'calc(50% - 4px)', minWidth: 200 }}
                                >
                                  <Input
                                    maxLength={15}
                                    onChange={(e) => {
                                      specialFieldValidation(form, specialFormState, setSpecialFormState, {
                                        key: 'phoneNumber',
                                        event: e,
                                        validationConfig: {
                                          letters: { allowed: false, onlyCaps: false },
                                          numbers: true,
                                          spaces: false
                                        }
                                      })
                                    }}
                                    addonBefore={`${countryObject ? countryObject.phoneCode : '+27'}`}
                                    placeholder='8288*****'
                                  />
                                </Form.Item>
                              </Popover>
                            </div>
                          </Col>

                          <Col span={24}>
                            <h1 style={{ padding: 8, background: hexToRGBA(token.colorPrimary, 0.2) }}>
                              Address Details
                            </h1>
                          </Col>
                          <Col span={24}>
                            <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', gap: 8 }}>
                              <Form.Item
                                style={{ marginTop: 0, marginBottom: 0, width: 'calc(50% - 4px)', minWidth: 200 }}
                                name='residentVisitor'
                                label={<b style={{ fontSize: 15 }}>RSA Resident / Visitor</b>}
                                rules={[{ required: true, message: 'Please if you are a RSA Resident or Visitor' }]}
                              >
                                <Select
                                  placeholder='RSA Resident or Visitor'
                                  options={[
                                    { label: 'RSA Resident', value: 'resident' },
                                    { label: 'Visiting From Abroad', value: 'visitor' }
                                  ]}
                                  defaultValue={residentVisitor}
                                  onChange={(value) => {
                                    if (value === 'resident') {
                                      setCountry('ZA')
                                    }

                                    setResidentVisitor(value)
                                  }}
                                />
                              </Form.Item>
                              <Form.Item
                                style={{ marginTop: 0, marginBottom: 0, width: 'calc(50% - 4px)', minWidth: 200 }}
                                name='country'
                                label={<b style={{ fontSize: 15 }}>Country of Residence</b>}
                                rules={[{ required: true, message: 'Please provide your Country Of Residence' }]}
                              >
                                <Select
                                  showSearch
                                  optionFilterProp='label'
                                  loading={countriesLoading}
                                  placeholder='e.g South Africa'
                                  options={countries.map((e) => {
                                    return {
                                      label: e.name,
                                      value: e.code
                                    }
                                  })}
                                  onChange={(value) => {
                                    setCountry(value)
                                  }}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={24}>
                            <Form.Item
                              label={<b style={{ fontSize: 15 }}>Residential Address</b>}
                              name='residentialAddress'
                            >
                              <GoogleAddress
                                selectProps={{
                                  placeholder: form.getFieldValue('residentialAddress')
                                    ? form.getFieldValue('residentialAddress')
                                    : 'Residential Address',
                                  onChange: (e) => {
                                    form.setFieldValue('residentialAddress', e.label)
                                  }
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <center>{errorMessage}</center>
                        </CustomRow>{' '}
                      </Col>
                      <Col span={12}>
                        <CustomRow className='basic-card' gutter={[8, 8]}>
                          <Col span={24}>
                            <h1 style={{ padding: 8, background: hexToRGBA(token.colorPrimary, 0.2) }}>
                              Medical Aid Details
                            </h1>
                          </Col>
                          <Col span={24}>
                            <Form.Item name={['medicalAid', 'schemeCode']} style={{ display: 'none' }}>
                              <Input />
                            </Form.Item>
                            <Form.Item name={['medicalAid', 'planCode']} style={{ display: 'none' }}>
                              <Input />
                            </Form.Item>
                            <Form.Item name={['medicalAid', 'planOptionCode']} style={{ display: 'none' }}>
                              <Input />
                            </Form.Item>
                            <Form.Item
                              requiredMark='optional'
                              label={
                                !residentVisitor || residentVisitor === 'resident' ? (
                                  <b style={{ fontSize: 15 }}>Medical Aid Name</b>
                                ) : (
                                  <b style={{ fontSize: 15 }}>Travel Insurance provier</b>
                                )
                              }
                              name={['medicalAid', 'name']}
                            >
                              {!residentVisitor || residentVisitor === 'resident' ? (
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder='Search for a Medical Aid...'
                                  disabled={false}
                                  onSearch={(value) => {
                                    debouncedSchemeFilter(value)
                                  }}
                                  onChange={(e, option) => {
                                    form.setFieldValue(
                                      ['medicalAid', 'schemeCode'],
                                      option?.data ? JSON.parse(option.data).code : ''
                                    )
                                    setSchemeData([])
                                  }}
                                  options={schemeData.map((i) => {
                                    return {
                                      label: i.name,
                                      value: i.name,
                                      data: JSON.stringify(i)
                                    }
                                  })}
                                  notFoundContent={
                                    schemeLoading ? (
                                      <AgiliteSkeleton skActive spinnerTip='Searching Schemes...' />
                                    ) : undefined
                                  }
                                />
                              ) : (
                                <Input placeholder='e.g. Discovery Health Scheme' />
                              )}
                            </Form.Item>
                            <Form.Item
                              requiredMark='optional'
                              label={
                                !residentVisitor || residentVisitor === 'resident' ? (
                                  <b style={{ fontSize: 15 }}>Medical Aid Plan</b>
                                ) : (
                                  <b style={{ fontSize: 15 }}>Travel Insurance Plan</b>
                                )
                              }
                              name={['medicalAid', 'plan']}
                            >
                              {!residentVisitor || residentVisitor === 'resident' ? (
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder='Select a Medical Aid Plan...'
                                  disabled={form.getFieldValue(['medicalAid', 'schemeCode']) ? false : true}
                                  onChange={(e, option) => {
                                    form.setFieldValue(
                                      ['medicalAid', 'planCode'],
                                      option?.data ? JSON.parse(option.data).code : ''
                                    )
                                    setPlansData([])
                                  }}
                                  onClick={() => {
                                    handleGetMedicalAidPlans(form.getFieldValue(['medicalAid', 'schemeCode']))
                                  }}
                                  options={plansData.map((i) => {
                                    return {
                                      label: i.option,
                                      value: i.option,
                                      data: JSON.stringify(i)
                                    }
                                  })}
                                  notFoundContent={
                                    plansLoading ? (
                                      <AgiliteSkeleton skActive spinnerTip='Searching Scheme Plans...' />
                                    ) : undefined
                                  }
                                />
                              ) : (
                                <Input placeholder='e.g. Coastal Saver' />
                              )}
                            </Form.Item>
                            <Form.Item
                              requiredMark='optional'
                              label={
                                !residentVisitor || residentVisitor === 'resident' ? (
                                  <b style={{ fontSize: 15 }}>Medical Aid Option</b>
                                ) : (
                                  <b style={{ fontSize: 15 }}>Travel Insurance Option</b>
                                )
                              }
                              name={['medicalAid', 'planOption']}
                            >
                              {!residentVisitor || residentVisitor === 'resident' ? (
                                <Select
                                  showSearch
                                  allowClear
                                  placeholder='Select a Medical Aid Plan Option...'
                                  disabled={form.getFieldValue(['medicalAid', 'planCode']) ? false : true}
                                  onChange={(e, option) => {
                                    form.setFieldValue(
                                      ['medicalAid', 'planOptionCode'],
                                      option?.data ? JSON.parse(option.data).code : ''
                                    )
                                    setPlanOptionData([])
                                  }}
                                  onClick={() => {
                                    handleGetMedicalAidPlanOptions(form.getFieldValue(['medicalAid', 'planCode']))
                                  }}
                                  options={planOptionData.map((i) => {
                                    return {
                                      label: i.option,
                                      value: i.option,
                                      data: JSON.stringify(i)
                                    }
                                  })}
                                  notFoundContent={
                                    planOptionsLoading ? (
                                      <AgiliteSkeleton skActive spinnerTip='Searching Scheme Plan Options...' />
                                    ) : undefined
                                  }
                                />
                              ) : (
                                <Input placeholder='e.g. Coastal Saver' />
                              )}
                            </Form.Item>
                            <Form.Item
                              requiredMark='optional'
                              label={
                                !residentVisitor || residentVisitor === 'resident' ? (
                                  <b style={{ fontSize: 15 }}>Medical Aid Number</b>
                                ) : (
                                  <b style={{ fontSize: 15 }}>Travel Insurance Number</b>
                                )
                              }
                              name={['medicalAid', 'number']}
                            >
                              <Input placeholder='e.g. 335***' />
                            </Form.Item>
                            <Form.Item
                              requiredMark='optional'
                              label={<b style={{ fontSize: 15 }}>Dependant Number</b>}
                              name={['medicalAid', 'dependantNumber']}
                            >
                              <Input placeholder='e.g. 01' />
                            </Form.Item>
                          </Col>
                          {userData?.mainMemberName ? (
                            <>
                              <Col span={24}>
                                <h1 style={{ padding: 8, background: hexToRGBA(token.colorPrimary, 0.2) }}>
                                  Main Member
                                </h1>
                              </Col>
                              <Col span={24}>
                                <Space direction='vertical'>
                                  <Space>
                                    <p style={{ width: 70 }}>
                                      <b>Name:</b>
                                    </p>
                                    <p>{userData.mainMemberName}</p>
                                  </Space>
                                  <Space>
                                    <p style={{ width: 70 }}>
                                      <b>ID:</b>
                                    </p>
                                    <p>{userData.mainMemberIdNo}</p>
                                  </Space>
                                  <Space>
                                    <p style={{ width: 70 }}>
                                      <b>Contact</b>
                                    </p>
                                    <p>{userData.tmpPhoneNumber}</p>
                                  </Space>
                                  <Space>
                                    <p style={{ width: 70 }}>
                                      <b>Email:</b>
                                    </p>
                                    <p>{userData.tmpEmail}</p>
                                  </Space>
                                </Space>
                              </Col>
                            </>
                          ) : undefined}
                        </CustomRow>
                      </Col>
                    </CustomRow>
                  </Col>
                  <Col>
                    <CustomButton
                      loading={loading}
                      onClick={() => (isExamination ? refreshView('cancel') : closeTab())}
                      text='CANCEL'
                    />
                  </Col>
                  <Col>
                    <CustomButton
                      text='SAVE CHANGES'
                      loading={loading}
                      style={{ background: token.colorSuccess, color: 'white', float: 'right' }}
                      onClick={() => {
                        form.submit()
                      }}
                      type='primary'
                    />
                  </Col>
                </CustomRow>
              </Form>
            </Col>
          </CustomRow>
        </Tabs.TabPane>
        {isExamination ? undefined : (
          <>
            <Tabs.TabPane key='dependants' tab='Dependants'>
              <PatientDependants mainMemeberDataIndex={dataIndex} userRef={userData._id} mainPatientInfo={userData} />
            </Tabs.TabPane>
            <Tabs.TabPane tab='Bookings' key='bookings'>
              <CustomRow>
                <Col span={24}>
                  <EmployeeBookingsView userRef={dataId} viewingPatientProfile={true} />
                </Col>
              </CustomRow>
            </Tabs.TabPane>
            {authState.agiliteUser.extraData.role.type === 'medical_professional' ? (
              <>
                <Tabs.TabPane tab='Scripts' key='scripts'>
                  <CustomRow>
                    <Col span={24}>
                      <ScriptsWrapper patientId={dataId} />
                    </Col>
                  </CustomRow>
                </Tabs.TabPane>
                <Tabs.TabPane tab='Analytics' key='analytics'>
                  <CustomRow>
                    <Col span={24}>
                      <PatientAnalytics userRef={dataId} />
                    </Col>
                  </CustomRow>
                </Tabs.TabPane>
                <Tabs.TabPane tab='Reports' key='reports'>
                  <CustomRow>
                    <Col span={24}>
                      <ReportList userRef={dataId} />
                    </Col>
                  </CustomRow>
                </Tabs.TabPane>
              </>
            ) : undefined}
          </>
        )}
      </Tabs>
      <Modal title='One-time Pin' footer={null} open={confirmationOpen} onCancel={() => setConfirmationOpen(false)}>
        <Row justify='center'>
          <Col>
            Please provide the One-time Pin sent to your Cellphone
            <Input
              value={confirmationCode}
              onChange={(e) => {
                if (/^[0-9]*$/g.test(e.target.value) === true) {
                  setConfirmationCode(e.target.value)
                }
              }}
            />
          </Col>
        </Row>
        <Row justify='space-between' style={{ marginTop: 20 }}>
          <Col>
            <Button
              disabled={submitDisabled}
              onClick={() => {
                const data = form.getFieldsValue()
                handleSubmit(data)
              }}
              type='primary'
            >
              Resend Code
            </Button>
          </Col>
          <Col>
            <Button
              disabled={submitDisabled}
              onClick={() => handleLoginPhoneCode()}
              type='primary'
              style={{ backgroundColor: token.colorSuccess }}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Modal>
      <div id='recaptcha-container'></div>
      <BeginExaminationModal
        examinationModalOpen={examinationModalOpen}
        setExaminationModalOpen={setExaminationModalOpen}
        beginAdhocExamination={beginAdhocScreening}
      />
      <Modal
        footer={false}
        maskClosable={false}
        closable={false}
        width={assessmentModalOpen ? 1920 : followUpModalOpen ? 500 : 950}
        open={
          followUpModalOpen ||
          sickNoteModalOpen ||
          referralModalOpen ||
          assessmentModalOpen ||
          clinicalNotesModalOpen ||
          medicalHistoryModalOpen
        }
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        onCancel={() => {
          handleModalReset()
        }}
        destroyOnClose
      >
        {assessmentModalOpen ? (
          <>
            <FontAwesomeIcon
              onClick={() => {
                setAssessmentModalOpen(false)
              }}
              style={{ float: 'right', cursor: 'pointer' }}
              icon={faXmarkCircle}
              color={token.colorError}
              fontSize={26}
            />
            <ExaminationWrapper userRef={userData._id} />
          </>
        ) : undefined}

        {followUpModalOpen ? (
          <FollowUpBookingCapture userRef={userData._id} setModalOpen={setFollowUpModalOpen} />
        ) : undefined}
        {referralModalOpen ? <RefferalLetterCapture setModalOpen={setReferralModalOpen} /> : undefined}
        {sickNoteModalOpen ? <SickNoteCapture setModalOpen={setSickNoteModalOpen} patient={userData} /> : undefined}
        {clinicalNotesModalOpen ? (
          <ClinicalNotes noBooking userRef={userData._id} setModalOpen={setClinicalNotesModalOpen} />
        ) : undefined}
        {medicalHistoryModalOpen ? (
          <NurseScreeningMedicalHistory noBooking setModalOpen={setMedicalHistoryModalOpen} userRef={userData._id} />
        ) : undefined}
      </Modal>
    </ContainerCard>
  )
}

export default EditPatientProfile
