import React, { useEffect, useState } from 'react'
import { getPatient } from '../../../Medical Vault/medical-history/utils/utils'
import MedicalHistoryForm from '../../../Medical Vault/medical-history/components/medical-history-form'
import { Button, Col, message, Row, theme } from 'antd'
import { handleError } from '../../../lib/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons'

const NurseScreeningMedicalHistory = ({
  userRef,
  setHasConfirmedMedicalHistory,
  setActiveTab,
  isPreviousBooking,
  refreshTrigger,
  noBooking,
  setModalOpen
}) => {
  const [data, setData] = useState()
  const [loading, setLoading] = useState(true)
  const [summary, setSummary] = useState(true)

  useEffect(() => {
    getMedicalHistory()
    // eslint-disable-next-line
  }, [summary, refreshTrigger])

  const getMedicalHistory = async () => {
    setLoading(true)
    try {
      const tmpData = await getPatient(userRef)
      setData(tmpData)
    } catch (e) {
      message.error(handleError(e))
    }
    setLoading(false)
  }
  const { token } = theme.useToken()
  return (
    <Row gutter={[0, 12]}>
      {noBooking ? (
        <Col span={24}>
          <FontAwesomeIcon
            size='2x'
            color={token.colorError}
            style={{ float: 'right', cursor: 'pointer' }}
            onClick={() => {
              setModalOpen(false)
            }}
            icon={faCircleXmark}
          />
        </Col>
      ) : undefined}
      <Col span={24}>
        <MedicalHistoryForm
          setActiveTab={setActiveTab}
          setHasConfirmedMedicalHistory={setHasConfirmedMedicalHistory}
          wrapperLoad={loading}
          refreshView={getMedicalHistory}
          setNotSaved={null}
          setHandleSubmit={null}
          data={data}
          userRef={userRef}
          isPreviousBooking={isPreviousBooking}
          isNurseExamination={true}
          summary={summary}
          setSummary={setSummary}
        />
      </Col>
    </Row>
  )
}

export default NurseScreeningMedicalHistory
