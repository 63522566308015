export const AssistantEnums = {
  routes: {
    INITIATE_THREAD: `${process.env.REACT_APP_NODE_RED_URL}/api/assistants/friendly-assistant/query`,
    RUN_THREAD: '',
    CONTINUE_THREAD: `${process.env.REACT_APP_NODE_RED_URL}/api/assistants/friendly-assistant/continue`,
    READ_THREAD: `${process.env.REACT_APP_NODE_RED_URL}/api/assistants/friendly-assistant/response`,
    CHECK_THREAD_STATUS: `${process.env.REACT_APP_NODE_RED_URL}/api/assistants/friendly-assistant/run-status`
  },
  assistant_ids: {
    REFERRAL_LETTER: 'asst_QzDnRXMu0Sqo6GYOvuYbYET6',
    CLINICAL_NOTES: 'asst_vb4ONL43qHqwZuTlLd1XGNzZ'
  }
}
