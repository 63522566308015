import { deviceDetect } from 'react-device-detect'

const CoreEnums = {
  localStorage: {
    IS_DARK_THEME: 'isDarkTheme',
    THEME_TOKEN: 'themeToken'
  },
  userRoles: {
    SUPERADMIN: 'super_admin',
    ADMIN: 'admin',
    SUBSCRIPTION_ADMIN: 'subscription_admin',
    MEDICAL_PROFESSIONAL: 'medical_professional',
    PATIENT: 'patient',
    RECEPTION: 'reception'
  },
  userProfessions: {
    NURSE: 'nurse',
    DOCTOR: 'doctor'
  },
  menuActions: {
    LEFT: 'left',
    RIGHT: 'right'
  },
  tabActions: {
    CHANGE: 'change',
    CLOSE: 'close'
  },
  menuTitles: {
    LEFT_MENU: 'Portal Menu',
    RIGHT_MENU: 'Administration'
  },
  errorMessages: {
    ENTITY_NOT_AUTH: 'You are not authorized to access this entity',
    UNKNOWN_ERROR: 'An unknown error has occurred. Please try again later.'
  },
  tabKeys: {
    // PROTOTYPE
    APPOINTMENTS_CALENDAR: 'appointmentsCalendar',
    DATA_POINTS: 'dataPoints',
    POC_DATA_POINTS: 'pocDataPoints',
    LAB_DATA_POINTS: 'labDataPoints',
    DATA_POINT_TEMPLATES: 'dataPointTemplates',
    CLINICAL_REPORTING_TEMPLATES: 'clinicalReportingTemplates',
    CARE_PLANS: 'carePlans',

    HOME: 'home',
    ENTITIES: 'entities',
    ANALYTICS: 'analytics',
    CLINICAL_ANALYTICS: 'clinicalAnalytics',
    MANAGEMENT: 'management',
    //Admin
    ADMIN_SUBSCRIPTIONS: 'admin_subscriptions',
    CLINIC_MANAGEMENT: 'clinicManagement',
    USER_MANAGEMENT: 'userManagement',
    SETTINGS: 'settings',
    OCCUPATIONAL_HEALTH: 'occupationalHealth',
    OCCUPATIONAL_HEALTH_DASHBOARD: 'occupational_health_dashboard',
    COMPANIES_CONTRACTORS: 'companiesContractors',
    CLINICS: 'clinics',
    SYSTEM_USERS: 'systemUsers',
    MEDICAL_PROFESSIONALS: 'medicalProfessionals',
    SERVICES: 'services',
    VIRTUAL_SERVICES: 'virtualServices',
    PATIENTS: 'patients',
    CONFIG: 'config',
    BOOKINGS: 'bookings',
    INVENTORY: 'inventory',
    CUSTOM_DIAGNOSIS: 'customDiagnosis',
    SCRIPTING: 'scripting',
    EXAMINATION_TEMPLATES: 'examinationTemplates',

    // User
    MY_BOOKINGS: 'myBookings',
    MY_APPOINTMENTS: 'myAppointments',
    DIAGNOSIS_HISTORY: 'diagnosisHistory',
    MY_PROFILE: 'myProfile',
    PERSONAL_DETAILS: 'personalDetails',
    SUBSCRIPTIONS: 'subscriptions',
    MEDICAL_HISTORY: 'medicalHistory',
    SIGN_OUT: 'signOut',
    HEALTH_TRACKING_ENTRIES: 'healthTrackingEntries',
    VITAL_LINK: 'vitalLink',
    MY_HEALTH: 'myHealth',

    // Reception
    BILLING: 'billing',
    SCHEDULE_APPOINTMENT: 'schedule_appointment',
    CRM: 'crm',

    // Terms and Conditions
    TERMS_AND_CONDITIONS: 'termsAndConditions',

    // Medical Professional
    AVAILABILITY: 'availability',

    // Dependents
    DEPENDANTS: 'dependants'
  },
  tabTitles: {
    // PROTOTYPE
    APPOINTMENTS_CALENDAR: 'Calendar',
    DATA_POINTS: 'Data Points',
    POC_DATA_POINTS: 'POC Data Points',
    LAB_DATA_POINTS: 'Lab Data Points',
    DATA_POINT_TEMPLATES: 'Data Point Templates',
    CLINICAL_REPORTING_TEMPLATES: 'Clinical Report Templates',
    CARE_PLANS: 'Care Plans',

    HOME: deviceDetect().isMobile ? 'Home' : 'Dashboard',
    ENTITIES: 'Entities',
    ANALYTICS: 'Analytics',
    CLINICAL_ANALYTICS: 'Clinical Analytics',
    MANAGEMENT: 'Management',
    //Admin
    ADMIN_SUBSCRIPTIONS: 'Meta-Link',
    CLINIC_MANAGEMENT: 'Clinic Management',
    USER_MANAGEMENT: 'User Management',
    SETTINGS: 'Settings',
    OCCUPATIONAL_HEALTH: 'Occupational Health',
    OCCUPATIONAL_HEALTH_DASHBOARD: 'Dashboard',
    COMPANIES_CONTRACTORS: 'Companies/Contractors',
    CLINICS: 'Clinics',
    SYSTEM_USERS: 'System Users',
    MEDICAL_PROFESSIONALS: 'Medical Professionals',
    SERVICES: 'Services',
    VIRTUAL_SERVICES: 'Virtual Services',
    PATIENTS: 'Patients',
    CONFIG: 'Configure',
    BOOKINGS: 'Bookings',
    INVENTORY: 'Inventory',
    CUSTOM_DIAGNOSIS: 'Diagnosis',
    SCRIPTING: 'Scripts',
    EXAMINATION_TEMPLATES: 'Examination Templates',

    // User
    MY_BOOKINGS: 'Appointments',
    MY_APPOINTMENTS: 'My Appointments',
    DIAGNOSIS_HISTORY: 'Diagnosis History',
    PERSONAL_DETAILS: 'Personal Details',
    MY_PROFILE: 'My Profile',
    SUBSCRIPTIONS: 'Subscriptions',
    MEDICAL_HISTORY: 'Medical History',
    SIGN_OUT: 'Sign Out',
    HEALTH_TRACKING_ENTRIES: 'Health Tracking',
    VITAL_LINK: 'Meta-Link (SOS)',
    MY_HEALTH: 'My Health',

    // Reception
    BILLING: 'Billing',
    SCHEDULE_APPOINTMENT: 'Schedule Appointment',
    CRM: 'CRM',

    // Medical Professional
    AVAILABILITY: 'Availability',

    // Terms and Conditions
    TERMS_AND_CONDITIONS: 'Terms and Conditions',

    // Dependents
    DEPENDANTS: 'Family Accounts'
  },
  bookingStatuses: {
    checkin: 'checkin',
    completed: 'completed',
    screening: 'screening',
    diagnosis: 'diagnosis',
    procedure: 'procedure',
    billing: 'reception',
    cancelled: 'cancelled',
    data_capture: 'data_capture'
  },
  provinces: {
    eastern_cape: 'Eastern Cape',
    free_state: 'Free State',
    gauteng: 'Gauteng',
    kwazulu_natal: 'KwaZulu-Natal',
    limpopo: 'Limpopo',
    mpumalanga: 'Mpumalanga',
    northern_cape: 'Northern Cape',
    north_west: 'North West',
    western_cape: 'Western Cape'
  }
}

export default CoreEnums
