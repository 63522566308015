import { QuestionCircleOutlined } from '@ant-design/icons'
import { faMicrophone, faMicrophoneSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Card, Col, Row, Space, Tooltip, theme } from 'antd'
import React from 'react'

const DictaphoneControls = ({ listening = false, startListening = () => {}, stopListening = () => {}, disabled }) => {
  const { token } = theme.useToken()

  return (
    <>
      {!listening ? (
        <Button disabled={listening || disabled} type='primary' onClick={startListening}>
          <FontAwesomeIcon icon={faMicrophone} />
        </Button>
      ) : (
        <Button disabled={!listening || disabled} type='primary' danger onClick={stopListening}>
          <FontAwesomeIcon icon={faMicrophoneSlash} />
        </Button>
      )}
    </>
  )
}

export default DictaphoneControls
