import React, { useCallback, useEffect, useState } from 'react'
import CustomRow from '../../reusable-components/CustomRow'
import { Button, Card, Col, Empty, Modal, Popconfirm, Radio, Row, Space, Spin, Tooltip, message, theme } from 'antd'
import { handleError, hexToRGBA } from '../../lib/utils'
import {
  createClinicalNote,
  groupData,
  readClinicalEntries,
  readClinicalNotes,
  readClinicalReportRecords,
  updateClinicalNote,
  whisperCreate
} from '../examination-utils/examination-lib'
import CustomButton from '../../reusable-components/CustomButton'
import dayjs from 'dayjs'
import DiagnosisCapture from './diagnosis-capture'
import debounce from 'lodash/debounce'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChainBroken,
  faCheckCircle,
  faChevronLeft,
  faChevronRight,
  faCircleXmark,
  faEarListen,
  faRefresh,
  faXmarkCircle
} from '@fortawesome/free-solid-svg-icons'
import SickNoteCapture from './sick-note-capture'
import RefferalLetterCapture from './referral-letter-capture'
import FollowUpBookingCapture from './follow-up-booking-capture'
import ReactQuill from 'react-quill'
import ExaminationWrapper from './examination-wrapper'
import { assistantInitiate } from '../../AI Tools/utils'
import { AssistantEnums } from '../../AI Tools/assitants-enums'
import { generateAIResponse } from '../../AI Tools/assistants-lib'
import FeatureButton from '../../reusable-components/FeatureButton'
import '../styling/notes.css'
import { readMedicalHistory } from '../../Admin/patients/utils/utils'
import ClinicalNotesTemplates from './clinical-notes-templates'
import Dictaphone from '../../lib/dictaphone'
import DictaphoneControls from '../../lib/dictaphone-controls'
import '../../Examination/examination-utils/style.css'
import VoiceRecorder from '../../reusable-components/VoiceRecorder'
import CustomLoadingIcon from '../../reusable-components/CustomLoadingIcon'

const ClinicalNotes = ({ bookingData, userRef, noBooking, setModalOpen }) => {
  const authState = useSelector((state) => state.auth)
  const [autoGeneratingNotes, setAutoGeneratingNotes] = useState(false)
  const [currentNote, setCurrentNote] = useState('')
  const [historicalNotes, setHistoricalNotes] = useState([])
  const [submittingNewNote, setSubmittingNewNote] = useState(false)
  const [historicalNotesLoading, setHistoricalNotesLoading] = useState(false)
  const [noteId, setNoteId] = useState(null)
  const [updatingNotes, setUpdatingNotes] = useState(false)
  const [fetchingError, setFetchingError] = useState('')
  const [updatingError, setUpdatingError] = useState('')
  const [assessmentModalOpen, setAssessmentModalOpen] = useState('')
  const [clinicalNotesTemplatesOpen, setClinicalNotesTemplatesOpen] = useState(false)
  const [referralModalOpen, setReferralModalOpen] = useState(false)
  const [sickNoteModalOpen, setSickNoteModalOpen] = useState(false)
  const [followUpModalOpen, setFollowUpModalOpen] = useState(false)
  const [showHistorical, setShowHistorical] = useState(false)
  const [currentHistroicalNoteIndex, setCurrentHistoricalNoteIndex] = useState(0)
  const [transcriptionAiListening, setTranscriptionAiListening] = useState(null)
  const [recordingTracker, setRecordingTracker] = useState(false)
  const [generatingAiTranscription, setGeneratingAiTranscription] = useState(false)
  const [creatingNote, setCreatingNote] = useState(false)

  const [isRecording, setIsRecording] = useState(false)
  const [tmpTranscript, setTmpTranscript] = useState('')

  useEffect(() => {
    handleReadHistoricalNotes()
    // eslint-disable-next-line
  }, [])

  const handleAutoGenerateNotes = async () => {
    let tmpContent = ''
    let tmpClinicalData = null
    let aiResponse = null
    let medicalHistory = null
    let clinicalReports = null
    const payload = {}

    setAutoGeneratingNotes(true)
    try {
      // Fetch clinical data
      tmpClinicalData = await readClinicalEntries({ userRef: bookingData?.userRef ? bookingData.userRef : userRef })
      medicalHistory = await readMedicalHistory({ userRef: bookingData?.userRef ? bookingData.userRef : userRef })
      clinicalReports = await readClinicalReportRecords({ bookingRef: bookingData._id })

      // Prep payload
      payload.patientDetails = bookingData?.patientRecord
      payload.clinicalData = groupData(tmpClinicalData)
      payload.medicalHistory = medicalHistory
      payload.clinicalReports = clinicalReports

      if (currentNote) {
        payload.existingContent = currentNote
      }

      // Contact AI
      const tmpData = await assistantInitiate(JSON.stringify(payload), AssistantEnums.assistant_ids.CLINICAL_NOTES)

      aiResponse = await generateAIResponse(tmpData)

      tmpContent = aiResponse?.data?.data[0]?.content[0]?.text?.value
    } catch (e) {
      message.error(handleError(e))
    }
    setAutoGeneratingNotes(false)
    setUpdatingNotes(true)
    setCurrentNote(tmpContent)
    debouncedNoteCapture(noteId, tmpContent)
  }

  useEffect(() => {
    if (!noBooking) {
      checkForExistingNote()
    }
    // eslint-disable-next-line
  }, [])

  const checkForExistingNote = async () => {
    setSubmittingNewNote(true)

    let existingNote = null
    let qry = {}
    try {
      qry.bookingRef = bookingData._id
      qry.createdBy = authState.agiliteUser._id
      existingNote = await readClinicalNotes(qry)
      if (existingNote[existingNote?.length - 1]) {
        setCurrentNote(existingNote[existingNote?.length - 1].content)
        setNoteId(existingNote[existingNote?.length - 1]._id)
      }
      setFetchingError('')
    } catch (e) {
      setFetchingError(handleError(e, true))
    }

    setSubmittingNewNote(false)
  }
  const handleUpdateNote = async (noteId, newNote) => {
    if (noteId) {
      try {
        await updateClinicalNote(noteId, newNote)
        setUpdatingError('')
      } catch (e) {
        setUpdatingError(handleError(e))
      }
    }
    setUpdatingNotes(false)
  }

  // eslint-disable-next-line
  const debouncedNoteCapture = useCallback(
    debounce((noteId, newNote) => {
      handleUpdateNote(noteId, newNote)
    }, 3000),
    []
  )

  const handleReadHistoricalNotes = async () => {
    const qry = {}
    let tmpData = {}

    setHistoricalNotesLoading(true)
    try {
      qry.userRef = bookingData?.userRef ? bookingData.userRef : userRef
      tmpData = await readClinicalNotes(qry)
      tmpData = tmpData.filter((note) => note._id !== noteId)
      tmpData = tmpData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      console.log('Historical Notes', tmpData)
      setHistoricalNotes(tmpData)
    } catch (e) {
      message.error(handleError(e, true))
    }
    setHistoricalNotesLoading(false)
  }

  const handleModalReset = () => {
    setFollowUpModalOpen(false)
    setReferralModalOpen(false)
    setSickNoteModalOpen(false)
  }

  useEffect(() => {
    setTmpTranscript('')
  }, [currentNote])

  const { token } = theme.useToken()

  const handleAiTranscription = async (file) => {
    let aiResponse = null
    let tmpContent = null
    setGeneratingAiTranscription(true)
    try {
      const response = await whisperCreate(file)

      // Contact AI
      const tmpData = await assistantInitiate(response, AssistantEnums.assistant_ids.CLINICAL_NOTES)

      aiResponse = await generateAIResponse(tmpData)
      tmpContent = aiResponse?.data?.data[0]?.content[0]?.text?.value

      setTranscriptionAiListening(false)
      setCurrentNote(tmpContent)
    } catch (error) {
      console.error('Error uploading file:', error)
    }
    setGeneratingAiTranscription(false)
  }

  const handleCreateNote = async () => {
    setCreatingNote(true)
    let newNote = null
    try {
      newNote = await createClinicalNote('', bookingData)
      setNoteId(newNote._id)
      setCurrentNote('')
    } catch (error) {
      message.error(handleError(error))
    }
    setCreatingNote(false)
  }

  // TODO: Create Error
  const isDisabled =
    autoGeneratingNotes ||
    updatingError ||
    fetchingError ||
    updatingNotes ||
    creatingNote ||
    updatingNotes ||
    submittingNewNote ||
    historicalNotesLoading
  return (
    <>
      <CustomRow>
        {noBooking ? (
          <Col span={24}>
            <FontAwesomeIcon
              size='2x'
              color={token.colorError}
              style={{ float: 'right', cursor: 'pointer' }}
              onClick={() => {
                setModalOpen(false)
              }}
              icon={faCircleXmark}
            />
          </Col>
        ) : undefined}
        {!noBooking ? (
          <Col span={24}>
            <DiagnosisCapture bookingData={bookingData} />
          </Col>
        ) : undefined}
        {noBooking ? undefined : (
          <Col span={24}>
            <Space>
              <Button
                style={{
                  background: 'linear-gradient(45deg, #13B1E1, #F14F8A)'
                }}
                disabled={isDisabled || showHistorical}
                type='primary'
                onClick={() => handleAutoGenerateNotes()}
              >
                Auto Generate (AI)
              </Button>

              {/* <Tooltip title='Dictate: Unavailable'>
          <Button disabled>
            <FontAwesomeIcon icon={faMicrophone} />
          </Button>
        </Tooltip> */}
              {isRecording ? (
                <Dictaphone
                  noteId={noteId}
                  handleUpdateNote={handleUpdateNote}
                  value={currentNote}
                  setValue={setCurrentNote}
                  tmpTranscript={tmpTranscript}
                  setTmpTranscript={setTmpTranscript}
                  setIsRecording={setIsRecording}
                  isRecording={isRecording}
                  disabled={isDisabled || showHistorical}
                />
              ) : (
                <DictaphoneControls
                  disabled={isDisabled || showHistorical}
                  startListening={() => setIsRecording(true)}
                />
              )}
              <Tooltip title='Transcribe (AI)'>
                <Button
                  disabled={isDisabled || showHistorical}
                  type='primary'
                  style={{
                    background: 'linear-gradient(45deg, #13B1E1, #F14F8A)'
                  }}
                  onClick={() => {
                    setTranscriptionAiListening(true)
                  }}
                >
                  <FontAwesomeIcon icon={faEarListen} />
                </Button>
              </Tooltip>

              <Button
                disabled={isDisabled || showHistorical}
                type='primary'
                style={{
                  background: 'linear-gradient(45deg, #13B1E1, #F14F8A)'
                }}
                onClick={() => setClinicalNotesTemplatesOpen(true)}
              >
                Templates
              </Button>
            </Space>
          </Col>
        )}
        <Col span={24} style={{ minHeight: '100%', position: 'relative' }}>
          <div style={{ width: '100%', display: 'grid', gridTemplateColumns: '1fr auto', gap: 12 }}>
            <Row>
              <Col span={24}>
                <CustomLoadingIcon
                  loading={autoGeneratingNotes || submittingNewNote || historicalNotesLoading}
                  loadingText={
                    creatingNote
                      ? 'Creating Note...'
                      : autoGeneratingNotes
                      ? 'AI is Generating Clinical Notes...'
                      : 'Loading Notes...'
                  }
                  content={
                    <Card
                      bodyStyle={!noteId ? { padding: 0 } : { padding: 0 }}
                      size='small'
                      title={
                        noBooking ? (
                          ''
                        ) : (
                          <Space>
                            Filter
                            <Radio.Group
                              value={showHistorical}
                              onChange={(e) => {
                                setShowHistorical(e.target.value)
                              }}
                              buttonStyle='solid'
                            >
                              <Radio.Button disabled={isDisabled} value={false}>
                                Current
                              </Radio.Button>
                              <Tooltip title={historicalNotes.length === 0 ? 'No Notes Captured to date' : ''}>
                                <Radio.Button value={true} disabled={isDisabled}>
                                  Historical
                                </Radio.Button>
                              </Tooltip>
                            </Radio.Group>
                            {noteId && !showHistorical ? (
                              <Tooltip
                                title={
                                  !currentNote ? "You can't add another note when your current note is empty." : ''
                                }
                              >
                                <Popconfirm
                                  overlayStyle={{ maxWidth: 450 }}
                                  onConfirm={() => {
                                    handleCreateNote()
                                  }}
                                  okText='I am sure'
                                  title='Add New Note'
                                  description='Are you sure? You will not be able to go back and edit your previous note after adding an additional note. Old notes can be accessed from the historical notes tab.'
                                >
                                  <Button
                                    type='primary'
                                    style={{ background: token.colorSuccess }}
                                    disabled={!currentNote || updatingNotes || creatingNote}
                                  >
                                    Add Additional Note
                                  </Button>
                                </Popconfirm>
                              </Tooltip>
                            ) : undefined}
                          </Space>
                        )
                      }
                      extra={
                        <>
                          {showHistorical || noBooking ? (
                            <Button
                              type='primary'
                              onClick={() => {
                                console.log('Notes', historicalNotes)
                                handleReadHistoricalNotes()
                              }}
                              style={{ background: token.colorSuccess }}
                            >
                              Sync Notes
                              <FontAwesomeIcon icon={faRefresh} />
                            </Button>
                          ) : (
                            <div>
                              {updatingNotes ? (
                                <Spin spinning />
                              ) : (
                                <>
                                  {showHistorical ? undefined : (
                                    <FontAwesomeIcon
                                      fontSize={20}
                                      icon={fetchingError || updatingError ? faChainBroken : faCheckCircle}
                                      color={fetchingError || updatingError ? token.colorError : token.colorSuccess}
                                    />
                                  )}
                                </>
                              )}
                            </div>
                          )}
                        </>
                      }
                    >
                      {showHistorical || noBooking ? (
                        <>
                          {historicalNotes?.length > 0 ? (
                            <div
                              style={{
                                display: showHistorical || noBooking ? '' : 'none'
                              }}
                            >
                              <div
                                style={{
                                  padding: '12px 15px',
                                  border: '1px solid #ccc',
                                  borderBottom: 0
                                }}
                              >
                                <center>
                                  <Space>
                                    <FontAwesomeIcon
                                      style={{
                                        cursor:
                                          currentHistroicalNoteIndex === historicalNotes.length - 1
                                            ? 'disabled'
                                            : 'pointer'
                                      }}
                                      fontSize={16}
                                      icon={faChevronLeft}
                                      onClick={() => {
                                        if (currentHistroicalNoteIndex === 0) {
                                          setCurrentHistoricalNoteIndex(historicalNotes.length - 1)
                                        } else {
                                          setCurrentHistoricalNoteIndex(currentHistroicalNoteIndex - 1)
                                        }
                                      }}
                                    />
                                    {currentHistroicalNoteIndex + 1} / {historicalNotes.length}
                                    <FontAwesomeIcon
                                      fontSize={16}
                                      icon={faChevronRight}
                                      style={{
                                        cursor:
                                          currentHistroicalNoteIndex === historicalNotes.length - 1
                                            ? 'disabled'
                                            : 'pointer'
                                      }}
                                      onClick={() => {
                                        if (currentHistroicalNoteIndex === historicalNotes.length - 1) {
                                          setCurrentHistoricalNoteIndex(0)
                                        } else {
                                          setCurrentHistoricalNoteIndex(currentHistroicalNoteIndex + 1)
                                        }
                                      }}
                                    />
                                  </Space>
                                </center>
                              </div>
                              <Row
                                gutter={[0, 24]}
                                style={{
                                  height: 600,
                                  overflow: 'scroll',
                                  alignContent: 'baseline',
                                  border: '1px solid #ccc',
                                  padding: '12px 15px'
                                }}
                              >
                                <Col span={24}>
                                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <div>
                                      <h4>
                                        Recorded by{' '}
                                        {historicalNotes[currentHistroicalNoteIndex]?.createdBy ===
                                        authState.agiliteUser._id
                                          ? ' You '
                                          : historicalNotes[currentHistroicalNoteIndex]?.createdByFullName}
                                        {dayjs(historicalNotes[currentHistroicalNoteIndex]?.createdAt).format(
                                          'DD MMMM YYYY'
                                        ) === dayjs().format('DD MMMM YYYY') ? (
                                          ' Today '
                                        ) : (
                                          <>
                                            {' '}
                                            on{' '}
                                            {dayjs(historicalNotes[currentHistroicalNoteIndex]?.createdAt).format(
                                              'DD MMMM YYYY'
                                            )}{' '}
                                          </>
                                        )}
                                        at{' '}
                                        {dayjs(historicalNotes[currentHistroicalNoteIndex]?.createdAt).format('HH:mm')}
                                      </h4>

                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: historicalNotes[currentHistroicalNoteIndex]?.content
                                        }}
                                      ></div>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          ) : (
                            <div>
                              <Empty description='There are currently no historical notes to show. Use the sync notes button to check for any changes.' />
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          {!noteId ? (
                            <Row gutter={[0, 12]}>
                              <Col span={24}>
                                <Empty description='You have no notes captured for this booking' />
                              </Col>
                              <Col span={24}>
                                <center>
                                  <Button
                                    type='primary'
                                    style={{ background: token.colorSuccess }}
                                    onClick={() => {
                                      handleCreateNote()
                                    }}
                                  >
                                    Add Note
                                  </Button>
                                </center>
                              </Col>
                            </Row>
                          ) : (
                            <ReactQuill
                              id={`${bookingData?._id}-notes-capture-area`}
                              style={{ display: showHistorical ? 'none' : '' }}
                              // style={{ opacity: fetchingError || updatingError ? 0.1 : 1 }}
                              readOnly={showHistorical || isRecording}
                              onChange={(e) => {
                                if (!isRecording && !creatingNote) {
                                  setUpdatingNotes(true)
                                  setCurrentNote(e)
                                  debouncedNoteCapture(noteId, e)
                                }
                              }}
                              value={currentNote + tmpTranscript}
                              // disabled={autoGeneratingNotes || submittingNewNote || fetchingError || updatingError}
                              // placeholder={autoGeneratingNotes ? 'Busy generating your clinical notes...' : 'Your notes here...'}
                              // rows={14}
                            />
                          )}
                        </>
                      )}
                    </Card>
                  }
                />

                {fetchingError || updatingError ? (
                  <div
                    style={{
                      height: '100%',
                      width: '100%',
                      background: hexToRGBA(token.colorError, 0.2),
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: 30
                    }}
                  >
                    <center>
                      <Space direction='vertical'>
                        <h2>Oops something went wrong.</h2>
                        <p>{fetchingError ? fetchingError : updatingError}</p>
                        <CustomButton
                          size='small'
                          text='Retry'
                          type='success'
                          onClick={() => {
                            if (fetchingError) {
                              setFetchingError('')
                              setSubmittingNewNote(true)
                              checkForExistingNote()
                            } else {
                              setUpdatingError('')
                              setUpdatingNotes(true)
                              handleUpdateNote(noteId, currentNote)
                            }
                          }}
                        />
                      </Space>
                    </center>
                  </div>
                ) : undefined}
              </Col>
            </Row>
            {!noBooking ? (
              <Row gutter={[0, 8]}>
                <Col span={24}>
                  <Space direction='vertical'>
                    <FeatureButton
                      background='linear-gradient(45deg, #DC143C, #FF7F50)'
                      backgroundHover='#DC143C'
                      text='ASSESSMENT'
                      onClick={() => setAssessmentModalOpen(true)}
                    />
                    <FeatureButton
                      background='linear-gradient(45deg, #50C878,  #40E0D0)'
                      backgroundHover='#50C878'
                      text='REFERRAL'
                      onClick={() => setReferralModalOpen(true)}
                    />
                    <FeatureButton
                      background='linear-gradient(45deg,#FF69B4, #FF1493)'
                      backgroundHover='#FF69B4'
                      text='SICK NOTE'
                      onClick={() => setSickNoteModalOpen(true)}
                    />
                    <FeatureButton
                      background='linear-gradient(45deg, #FFD700, #FFA500'
                      backgroundHover='#FFD700'
                      text='FOLLOW-UP'
                      onClick={() => setFollowUpModalOpen(true)}
                    />
                  </Space>
                </Col>
              </Row>
            ) : undefined}
          </div>
        </Col>

        <Modal
          footer={false}
          maskClosable={false}
          closable={false}
          width={assessmentModalOpen ? 1920 : followUpModalOpen ? 500 : 950}
          open={
            followUpModalOpen ||
            sickNoteModalOpen ||
            referralModalOpen ||
            assessmentModalOpen ||
            clinicalNotesTemplatesOpen
          }
          okButtonProps={{ style: { display: 'none' } }}
          cancelButtonProps={{ style: { display: 'none' } }}
          onCancel={() => {
            handleModalReset()
          }}
          destroyOnClose
        >
          {assessmentModalOpen ? (
            <>
              <FontAwesomeIcon
                onClick={() => {
                  setAssessmentModalOpen(false)
                }}
                style={{ float: 'right', cursor: 'pointer' }}
                icon={faXmarkCircle}
                color={token.colorError}
                fontSize={26}
              />
              <ExaminationWrapper bookingData={bookingData} userRef={userRef} />{' '}
            </>
          ) : undefined}

          {followUpModalOpen ? (
            <FollowUpBookingCapture setModalOpen={setFollowUpModalOpen} bookingData={bookingData} />
          ) : undefined}
          {referralModalOpen ? (
            <RefferalLetterCapture setModalOpen={setReferralModalOpen} bookingData={bookingData} />
          ) : undefined}
          {sickNoteModalOpen ? (
            <SickNoteCapture setModalOpen={setSickNoteModalOpen} bookingData={bookingData} />
          ) : undefined}
          {clinicalNotesTemplatesOpen ? (
            <ClinicalNotesTemplates
              setModalOpen={setClinicalNotesTemplatesOpen}
              setValue={setCurrentNote}
              setUpdatingNotes={setUpdatingNotes}
              debouncedNoteCapture={debouncedNoteCapture}
              noteId={noteId}
            />
          ) : undefined}
        </Modal>
      </CustomRow>
      <Modal
        destroyOnClose
        onCancel={() => {
          setTranscriptionAiListening(false)
        }}
        open={transcriptionAiListening}
        onOk={() => {
          handleAiTranscription()
        }}
      >
        <div
          style={{
            background: 'linear-gradient(45deg, #13B1E1, #F14F8A)',
            height: 400,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            borderRadius: 12
          }}
        >
          <FontAwesomeIcon
            className={recordingTracker ? 'pulse' : ''}
            fontSize={120}
            icon={faEarListen}
            color='#ffffff'
            style={{ position: 'absolute' }}
          />
          <div style={{ position: 'absolute' }}>
            <VoiceRecorder setRecordingTracker={setRecordingTracker} handleOnStop={handleAiTranscription} />
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ClinicalNotes
