import { faChain, faChainBroken, faRefresh, faTrash, faUpload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Card, Checkbox, Col, Row, Space, Tooltip, message, theme } from 'antd'
import React, { useEffect, useState } from 'react'
import AgiliteSkeleton from '../../reusable-components/AgiliteSkeleton'
import { useSelector } from 'react-redux'
import { handleError, hexToRGBA } from '../../lib/utils'
import { createProcedurePerformed, readProceduresPerformed } from '../examination-utils/examination-procedures-utils'
import dayjs from 'dayjs'
import { readProcedures } from '../../Admin/entities/utils/utils'
import { deleteFile, getFile, uploadFile } from '../../Admin/config/utils/utils'
import { countBillingRecords, createBillingRecord } from '../../Bookings/utils/utils'

const ExaminationProceduresPerformed = ({ bookingData, userRef }) => {
  const state = useSelector((state) => state)
  const [webSocket, setWebSocket] = useState({
    connected: false,
    socket: null
  })

  // Procedures Performed
  const [procedureList, setProcedureList] = useState([])
  const [proceduresPerformed, setProceduresPerformed] = useState([])
  const [proceduresPerformedLoading, setProceduresPerformedLoading] = useState(false)
  const [keywordProcedures, setKeywordProcedures] = useState([])

  const { token } = theme.useToken()

  useEffect(() => {
    fetchProceduresPerformed()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const proceduresPermormedSocket = new WebSocket(`${process.env.REACT_APP_NODE_RED_WS_URL}/procedures-performed`)

    proceduresPermormedSocket.onopen = () => {
      setWebSocket({
        connected: true,
        socket: proceduresPermormedSocket
      })
    }

    proceduresPermormedSocket.onerror = (error) => {
      handleError(error, true)
    }

    proceduresPermormedSocket.onmessage = (event) => {
      if (JSON.parse(event.data).bookingRef === bookingData._id) {
        setProceduresPerformed(JSON.parse(event.data).data)
      }
    }

    return () => {
      proceduresPermormedSocket.close()

      setWebSocket({
        connected: true,
        socket: proceduresPermormedSocket
      })
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setProcedureList(state.core.entity.configs.procedures ? state.core.entity.configs.procedures : [])
    handleGetKeywordProcedures()
    // eslint-disable-next-line
  }, [])

  const fetchProceduresPerformed = async () => {
    let tmpData = []

    setProceduresPerformedLoading(true)
    try {
      tmpData = await readProceduresPerformed({ bookingRef: bookingData._id, userRef })
      setProceduresPerformed(tmpData)
    } catch (e) {
      message.error(handleError(e))
    }
    setProceduresPerformedLoading(false)
  }

  const handleGetKeywordProcedures = async () => {
    try {
      let tmpData = []
      tmpData = await readProcedures()
      setKeywordProcedures(tmpData)
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  const handleProcedureChange = async (key, value, fileRef, fileName) => {
    try {
      const tmpProceduresPerformed = [...proceduresPerformed]
      let currentProcedureIndex = tmpProceduresPerformed.findIndex((i) => i.key === key)

      createProcedurePerformed({
        bookingRef: bookingData._id,
        createdBy: state.auth.agiliteUser.firstName + ' ' + state.auth.agiliteUser.lastName,
        dateCreated: dayjs(new Date()),
        fileRef,
        fileName,
        key,
        value,
        userRef
      })

      if (currentProcedureIndex !== -1) {
        tmpProceduresPerformed[currentProcedureIndex].value = value
        tmpProceduresPerformed[currentProcedureIndex].fileRef = fileRef
        tmpProceduresPerformed[currentProcedureIndex].fileName = fileName
      } else {
        tmpProceduresPerformed.push({
          key,
          value,
          fileRef,
          fileName
        })
      }

      setProceduresPerformed(tmpProceduresPerformed)
      webSocket.socket.send(JSON.stringify({ bookingRef: bookingData._id, data: tmpProceduresPerformed }))

      // Billing Macros
      const tmpBillingItems = []
      let tmpMacroIndex = -1

      if (bookingData.clinicRecord?.billingMacros) {
        tmpMacroIndex = bookingData.clinicRecord.billingMacros.findIndex((i) => i.name === key)
      }

      if (tmpMacroIndex !== -1 && value) {
        if (bookingData.clinicRecord.billingMacros[tmpMacroIndex].isActive) {
          bookingData.clinicRecord.billingMacros[tmpMacroIndex].items.forEach((item) => {
            tmpBillingItems.push({
              code: item.code,
              name: item.name,
              qty: item.qty,
              type: item.type,
              bookingRef: bookingData._id,
              procedureKey: key
            })
          })
        }
      }

      const recordCount = await countBillingRecords({ bookingRef: bookingData._id, procedureKey: key })

      if (recordCount.result === 0) {
        createBillingRecord(tmpBillingItems)
      }
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  const checkValue = (key) => {
    let returnValue = false

    if (proceduresPerformed.length > 0) {
      const procedureEntry = proceduresPerformed.find((i) => i.key === key)

      if (procedureEntry) {
        returnValue = procedureEntry.value
      } else {
        returnValue = false
      }
    } else {
      returnValue = false
    }

    return returnValue
  }

  const handleCheckFileUpload = (proc, index) => {
    let tmpIndex = -1

    tmpIndex = keywordProcedures.findIndex((i) => i.label === proc)

    if (tmpIndex !== -1) {
      if (keywordProcedures[tmpIndex].value === 'true') {
        const procedureEntry = proceduresPerformed.find((i) => i.key === proc)

        if (procedureEntry && procedureEntry.fileName && procedureEntry.fileRef) {
          return (
            <div key={index}>
              <Space>
                File: {/* eslint-disable-next-line */}
                <a
                  onClick={async () => {
                    try {
                      const fileResponse = await getFile(procedureEntry.fileRef)
                      const link = document.createElement('a')
                      const blob = new Blob([fileResponse])
                      const url = URL.createObjectURL(blob)
                      link.href = url
                      link.download = procedureEntry.fileName
                      link.click()
                      URL.revokeObjectURL(url)
                    } catch (e) {
                      message.error(handleError(e, true))
                    }
                  }}
                >
                  {procedureEntry.fileName}
                </a>
                <FontAwesomeIcon
                  onClick={() => {
                    deleteFile(procedureEntry.fileRef)
                    handleProcedureChange(proc, true, '', '')
                  }}
                  style={{ cursor: 'pointer' }}
                  color={token.colorError}
                  icon={faTrash}
                />
              </Space>
            </div>
          )
        } else {
          return (
            <div key={index}>
              <div onClick={() => document.getElementById('upload-file').click()}>
                <Space>
                  <FontAwesomeIcon icon={faUpload} color={token.colorPrimary} size='lg' />
                  {/* eslint-disable-next-line */}
                  <a>Upload File</a>
                </Space>
              </div>
              <input
                id='upload-file'
                style={{ display: 'none' }}
                type='file'
                multiple={false}
                onChange={async (e) => {
                  try {
                    const fileReader = new FileReader()

                    fileReader.onload = async (event) => {
                      try {
                        const arrayBuffer = event.target.result
                        const fileResponse = await handleFileUpload(e.target.files[0].name, arrayBuffer)
                        handleProcedureChange(proc, true, fileResponse._id, e.target.files[0].name)
                      } catch (e) {
                        message.error(handleError(e, true))
                      }
                    }

                    fileReader.readAsArrayBuffer(e.target.files[0])
                  } catch (e) {
                    message.error(handleError(e, true))
                  }
                }}
              />
            </div>
          )
        }
      } else {
        return null
      }
    } else {
      return null
    }
  }

  const handleFileUpload = async (name, file) => {
    let response = null
    try {
      response = await uploadFile(name, 'application/octet-stream', file, true)
      return response
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  return (
    <Card
      title='Procedures Performed'
      size='small'
      headStyle={{ background: hexToRGBA(token.colorPrimary, 0.2) }}
      style={{ marginTop: 10 }}
      extra={
        <Space>
          <Tooltip
            title={
              webSocket.connected ? 'Connected to Realtime Update Server' : 'Disconnected from Realtime Update Server'
            }
          >
            <FontAwesomeIcon
              icon={webSocket.connected ? faChain : faChainBroken}
              size='sm'
              color={webSocket.connected ? token.colorSuccess : token.colorError}
              style={{ cursor: 'help' }}
            />
          </Tooltip>
          <Button onClick={() => fetchProceduresPerformed()}>
            <Space>
              <FontAwesomeIcon icon={faRefresh} color={token.colorSuccess} />
              Refresh
            </Space>
          </Button>
        </Space>
      }
    >
      <Row>
        <Col span={24}>
          {proceduresPerformedLoading ? (
            <AgiliteSkeleton spinnerTip='Procedures Performed Loading...' />
          ) : (
            <>
              {procedureList.map((proc, index) => {
                return (
                  <Col span={12}>
                    <Row>
                      <Col span={10}>
                        <Checkbox
                          onChange={(e) => handleProcedureChange(proc, e.target.checked)}
                          className='custom-checkbox'
                          checked={checkValue(proc)}
                          style={{ marginTop: 5 }}
                        >
                          <span style={{ fontSize: 16 }}>{proc}</span>
                        </Checkbox>
                      </Col>
                      <Col span={14}>{handleCheckFileUpload(proc, index)}</Col>
                    </Row>
                  </Col>
                )
              })}
            </>
          )}
        </Col>
      </Row>
    </Card>
  )
}

export default ExaminationProceduresPerformed
