import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faVideoCamera, faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import { Button, Col, Modal, Popconfirm, Space, Tabs, message, notification, theme } from 'antd'

import coreReducer from '../../../core/utils/reducer'
import NurseScreeningMedicalHistory from './Examination Components/nurse-examination-medical-history'
import { ContainerCard } from '../../reusable-components/AgiliteCards'
import { extractCGMError, handleError } from '../../lib/utils'
import { getMainTitle, isValidStage } from '../utils/lib'
import { updateBooking, readBookings, readBillingRecords, generateAgoraRtcToken } from '../utils/utils'
import { executeBooking, getBookingState } from '../utils/bpm-utils'
import PatientInformationCard from './Examination Components/patient-information-card'
import PatientExaminationActionsCard from './Examination Components/patient-examination-actions-card'
import dayjs from 'dayjs'
import CustomRow from '../../reusable-components/CustomRow'
import CoreEnums from '../../../core/utils/enums'
import { findPreviousLatestBooking, updateAllBookingsState } from '../../lib/booking-utils'

import '../index.css'
import { readSystemUsers } from '../../Admin/system-users/utils/utils'
import NurseToDoctor from './Examination Components/nurse-to-docgor-modal'
// import { createInventoryActivity } from '../../Admin/Inventory/utils/utils'
import ScriptsWrapper from '../../Scripting/components/scripting-wrapper'
import AgiliteSkeleton from '../../reusable-components/AgiliteSkeleton'
import { cgmAuth, cgmPost } from '../cgm_billing/utils'
import Billing from './Examination Components/billing'
import ServicesEnums from '../../Admin/services/utils/enums'
import { findAgiliteUser } from '../../Auth/utils/utils'
import { isAdmin, isDoctor, isNurse } from '../../lib/profile-utils'
import ExaminationWrapper from '../../Examination/components/examination-wrapper'
import ClinicalNotes from '../../Examination/components/clinical-notes'
import ClinicalReporting from '../../Examination/components/clinical-reporting'
import FileArchive from '../../Examination/components/file-archive'
import EmployeeBookingsView from './Booking View Components/employee-bookings-view'
import { VirtualVisit } from './Examination Components/virtual-visit'
import PatientJourneyList from './Examination Components/patient-journey-list'

const PatientExaminationForm = ({ data, isPreviousBooking, workflowHistory, noAppointment }) => {
  const { token } = theme.useToken()
  const authState = useSelector((state) => state.auth)
  const state = useSelector((state) => state)
  const [bookingData, setBookingData] = useState(data)
  // const notes = useSelector((state) => state.bookings.notes)
  // eslint-disable-next-line
  const [hasConfirmedMedicalHistory, setHasConfirmedMedicalHistory] = useState()
  const [billing, setBilling] = useState(data.examinationData?.billing)
  const [billingItems, setBillingItems] = useState([])
  const [script, setScript] = useState(data.examinationData?.diagnosisData?.script || [])
  const [loading, setLoading] = useState(false)
  const [saving, setSaving] = useState(false)
  const [completing, setCompleting] = useState(false)
  const [joined, setJoined] = useState(false)
  const [activeTab, setActiveTab] = useState('patientProfile')
  const [agoraToken, setAgoraToken] = useState(null)
  const [agoraChannel, setAgoraChannel] = useState(null)
  const [agoraUid, setAgoraUid] = useState(null)
  const [videoBoxWidth, setVideoBoxWidth] = useState('100%')
  const [videoMinimized, setVideoMinimized] = useState(false)
  const [medProf, setMedProf] = useState(null)
  const [summary, setSummary] = useState(authState.agiliteUser.extraData.profession === 'doctor' ? true : false)
  const [reportDiagnosis, setReportDiagnosis] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [previousLatestBooking, setPreviousLatestBooking] = useState(null)
  const [previousDataLoading, setPreviousDataLoading] = useState(false)
  const [printingReport, setPrintingReport] = useState(false)
  const [billingRecordsLoading, setBillingRecordsLoading] = useState(false)
  const [billingSyncRequired, setBillingSyncRequired] = useState(false)

  // Billing Web Socket
  const [billingWebSocket, setBillingWebSocket] = useState({
    connected: false,
    socket: null
  })

  const dispatch = useDispatch()

  useEffect(() => {
    const bookingSocket = new WebSocket(`${process.env.REACT_APP_NODE_RED_WS_URL}/billing`)

    bookingSocket.onopen = () => {
      setBillingWebSocket({
        connected: true,
        socket: bookingSocket
      })
    }

    bookingSocket.onmessage = (event) => {
      if (JSON.parse(event.data).bookingRef === bookingData._id) {
        if (JSON.parse(event.data).userRef !== authState.agiliteUser._id) {
          setBillingSyncRequired(true)
        }
      }
    }

    bookingSocket.onerror = (error) => {
      handleError(error, true)
    }

    return () => {
      bookingSocket.close()

      setBillingWebSocket({
        connected: false,
        socket: null
      })
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    handleGetPatient()
    handleGetBillingRecords()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setBookingData(data)
  }, [data])

  const handleGetPatient = async () => {
    let tmpPatient = null
    try {
      tmpPatient = await findAgiliteUser({ _id: bookingData.patientRecord._id })
      setBookingData({ ...bookingData, patientRecord: tmpPatient })
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  const handleGetBillingRecords = async () => {
    let tmpBillingRecords = []

    setBillingRecordsLoading(true)
    try {
      tmpBillingRecords = await readBillingRecords({ bookingRef: data._id })
      setBillingItems(tmpBillingRecords)
    } catch (e) {
      message.error(handleError(e, true))
    }
    setBillingRecordsLoading(false)
  }

  useEffect(() => {
    const getMedProf = async () => {
      let users = null

      try {
        users = await readSystemUsers({ _id: data.medicalProfRef }, null, null, null, true)

        if (users.length > 0) {
          setMedProf(users[0])
        }
      } catch (e) {
        message.error(handleError(e, true))
      }
    }

    getMedProf()

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    handleGetPreviousLatestBooking()
    // eslint-disable-next-line
  }, [])

  const handleGetPreviousLatestBooking = async () => {
    let tmpData = null

    setPreviousDataLoading(true)

    try {
      tmpData = await findPreviousLatestBooking(data)
      setPreviousLatestBooking(tmpData)
    } catch (e) {
      message.error(handleError(e, true))
    }

    setPreviousDataLoading(false)
  }

  const closeTab = () => {
    dispatch(
      coreReducer.actions.closeTab({
        targetKey: state.core.tabNavigation.activeKey,
        removeBreadcrumb: true
      })
    )
  }

  const handleSave = async (isCompleteAction, recordData, isReport, generationFunction) => {
    let processRecord = null
    let newRecord = null

    if (isCompleteAction) {
      setCompleting(true)
    } else {
      setSaving(true)
    }

    setLoading(true)

    const nurseNotes = document.getElementById(`${data._id}-notes-capture-area-nurse`)?.value
    const doctorsNotes = document.getElementById(`${data._id}-notes-capture-area-doctor`)?.value
    const patientNotes = document.getElementById(`${data._id}-notes-capture-area-patient`)?.value

    try {
      // Notes Validation
      if (nurseNotes) {
        throw new Error('There are Nurse Clinical Notes that have not been saved. Please revise.')
      }

      if (doctorsNotes) {
        throw new Error('There are Clinical Notes that have not been saved. Please revise.')
      }

      if (patientNotes) {
        throw new Error('There are Patient Instruction Notes that have not been saved. Please revise.')
      }

      let latestRecordStatus = await readBookings({ _id: data._id })

      if (latestRecordStatus[0]?.status !== bookingData.status && !noAppointment) {
        throw new Error(`Unable to complete. ${data.status} may have been bypassed by another practitioner.`)
      }

      if (isCompleteAction) {
        processRecord = await getBookingState([data.processRef])

        const commonParams = [`${authState.agiliteUser.firstName} ${authState.agiliteUser.lastName}`, processRecord.key]

        switch (bookingData.status) {
          case CoreEnums.bookingStatuses.completed:
            // When the status is already completed, we know the doctor submitted updates
            processRecord = await executeBooking(
              bookingData.clinicRecord.bpmKey,
              data.processRef,
              'doctor_updates',
              ...commonParams
            )
            break
          case CoreEnums.bookingStatuses.screening:
            if (medProf.extraData.profession === 'nurse') {
              if (bookingData.isVirtualVisit) {
                // Submit to diagnosis
                processRecord = await executeBooking(
                  bookingData.clinicRecord.bpmKey,
                  data.processRef,
                  'submit',
                  ...commonParams
                )
              } else if (bookingData.serviceRecord?.type === ServicesEnums.labels.VITALITY_HEALTH_CHECK) {
                // Submit to Reception Data Capture
                processRecord = await executeBooking(
                  bookingData.clinicRecord.bpmKey,
                  data.processRef,
                  'submit_data_capture',
                  ...commonParams
                )
              } else {
                // Submit to Reception Billing
                processRecord = await executeBooking(
                  bookingData.clinicRecord.bpmKey,
                  data.processRef,
                  'submit_billing',
                  ...commonParams
                )
              }
            } else {
              // Submit to diagnosis
              processRecord = await executeBooking(
                bookingData.clinicRecord.bpmKey,
                data.processRef,
                'submit',
                ...commonParams
              )
            }

            break
          default:
            if (bookingData.nurseProcedureRequired && bookingData.status !== CoreEnums.bookingStatuses.procedure) {
              processRecord = await executeBooking(
                bookingData.clinicRecord.bpmKey,
                data.processRef,
                'nurse_procedure',
                ...commonParams
              )
            } else {
              processRecord = await executeBooking(
                bookingData.clinicRecord.bpmKey,
                data.processRef,
                'submit',
                ...commonParams
              )
            }
            break
        }
      }

      newRecord = await updateBooking(
        {
          status: isCompleteAction ? processRecord.processStage : bookingData.status,
          noCharge: bookingData.noCharge
        },
        { _id: data._id }
      )

      updateAllBookingsState(newRecord)

      if (isReport) {
        setPrintingReport(true)
        await generationFunction(newRecord)
      }

      if (isCompleteAction) {
        message.success('Changes submitted successfully.')
        closeTab()
      } else {
        message.success('Changes saved successfully.')
      }
    } catch (e) {
      message.error(handleError(e, true))
    }
    setPrintingReport(false)
    setSaving(false)
    setCompleting(false)
    setLoading(false)
  }

  useEffect(() => {
    if (bookingData.status === CoreEnums.bookingStatuses.procedure) {
      setHasConfirmedMedicalHistory(true)
    }
    // eslint-disable-next-line
  }, [])

  const startVirtualConsult = async () => {
    let agoraCreds = null

    try {
      agoraCreds = await generateAgoraRtcToken(data._id, 1)
      setAgoraToken(agoraCreds.token)
      setAgoraChannel(agoraCreds.channelName)
      setAgoraUid(agoraCreds.uid)
      setJoined(true)
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  const handleVideoBoxScroll = () => {
    if (joined && !videoMinimized) {
      const containerRef = document.getElementById('video-container')
      const elementRef = document.getElementById('video-box')
      const rect = containerRef.getBoundingClientRect()
      const top = rect.top

      setVideoBoxWidth(rect.width)

      if (top < 88) {
        // Scrolled
        containerRef.classList.add('container-fixed')
        elementRef.classList.add('video-fixed')
      } else if (top > 88) {
        containerRef.classList.remove('container-fixed')
        elementRef.classList.remove('video-fixed')
      }
    }
  }

  window.onscroll = () => {
    let videoBoxRef = document.getElementById('video-box')
    handleVideoBoxScroll()
    if (videoBoxRef) {
      if (videoBoxRef.classList.contains('video-fixed') && !videoBoxRef.classList.contains('draggable')) {
        videoBoxRef.classList.add('draggable')
        dragElement(videoBoxRef)
      } else {
        if (!videoBoxRef.classList.contains('video-fixed')) {
          videoBoxRef.classList.remove('draggable')
        }
      }
    }
  }

  const handleVideoMinimized = () => {
    const containerRef = document.getElementById('video-box')
    if (videoMinimized) {
      setVideoMinimized(false)
      containerRef.classList.remove('video-minimized')
      containerRef.classList.add('video-fixed')
    } else {
      setVideoMinimized(true)
      containerRef.classList.add('video-minimized')
    }
  }

  function dragElement(elmnt) {
    var pos1 = 0,
      pos2 = 0,
      pos3 = 0,
      pos4 = 24
    if (document.getElementById(elmnt.id + 'header')) {
      // if present, the header is where you move the DIV from:
      document.getElementById(elmnt.id + 'header').onmousedown = dragMouseDown
    } else {
      // otherwise, move the DIV from anywhere inside the DIV:
      elmnt.onmousedown = dragMouseDown
    }

    function dragMouseDown(e) {
      e = e || window.event
      e.preventDefault()
      // get the mouse cursor position at startup:
      pos3 = e.clientX
      pos4 = e.clientY
      document.onmouseup = closeDragElement
      // call a function whenever the cursor moves:
      document.onmousemove = elementDrag
    }

    function elementDrag(e) {
      e = e || window.event
      e.preventDefault()
      // calculate the new cursor position:
      pos1 = pos3 - e.clientX
      pos2 = pos4 - e.clientY
      pos3 = e.clientX
      pos4 = e.clientY
      // set the element's new position:
      elmnt.style.top = elmnt.offsetTop - pos2 + 'px'
      elmnt.style.left = elmnt.offsetLeft - pos1 + 'px'
    }

    function closeDragElement() {
      // stop moving when mouse button is released:
      document.onmouseup = null
      document.onmousemove = null
    }
  }

  const finalizeBilling = async () => {
    setLoading(true)
    setCompleting(true)

    const invoiceID = dayjs(new Date()).format('YYYYMMDDHHmmss').toString()
    let processRecord = await getBookingState([bookingData.processRef])
    // let inventoryItems = []
    let cgmAuthRes = null
    let cgmPostRes = null
    let patientRequiredFields = ['dateOfBirth', 'idNo', 'firstName', 'lastName', 'gender', 'residentialAddress']
    let validationFailed = false
    let patient = null

    try {
      // data.examinationData?.billing?.consumables.forEach((item) => {
      //   if (item.userRef) {
      //     inventoryItems.push(item)
      //   }
      // })

      patient = await findAgiliteUser({ _id: bookingData.patientRecord._id })

      if (bookingData.clinicRecord.cgmEnabled && !bookingData.noCharge) {
        // Validation
        for (const entry of patientRequiredFields) {
          if (!patient[entry]) {
            validationFailed = true
          }
        }

        if (validationFailed) {
          setLoading(false)
          setCompleting(false)
          return notification.error({
            message: 'Patient Profile Incomplete',
            description: 'Please ensure that the patient profile is complete before proceeding with billing.',
            duration: 0
          })
        }

        cgmAuthRes = await cgmAuth(bookingData.userRef)
        cgmPostRes = await cgmPost(
          { ...bookingData, patientRecord: patient },
          invoiceID,
          billingItems,
          cgmAuthRes.access_token,
          medProf
        )

        if (cgmPostRes) {
          let throwError = false
          if (cgmPostRes.accountRegistration.status === 'ERROR') {
            throwError = true
          }
          if (cgmPostRes.patientRegistration.status === 'ERROR') {
            throwError = true
          }
          if (cgmPostRes?.billPatient?.status === 'ERROR') {
            throwError = true
          }
          if (throwError) {
            throw new Error(extractCGMError(cgmPostRes))
          }
        }
      }

      // for (const item of inventoryItems) {
      //   await createInventoryActivity({
      //     createdAt: convertDateTimeSAST(dayjs()),
      //     quantity: item.quantity,
      //     type: 'out',
      //     createdBy: item.userRef,
      //     locationRef: data.clinicRef,
      //     bookingref: data._id,
      //     itemRef: item._id
      //   })
      // }

      // BPM
      processRecord = await executeBooking(
        bookingData.clinicRecord.bpmKey,
        bookingData.processRef,
        'submit',
        `${state.auth.agiliteUser.firstName} ${state.auth.agiliteUser.lastName}`,
        processRecord.key
      )
      let newRecord = await updateBooking(
        { status: processRecord.processStage, cgmInvoiceId: invoiceID },
        { _id: bookingData._id }
      )
      updateAllBookingsState(newRecord)
      message.success('Billing Completed')
      closeTab()
    } catch (e) {
      if (typeof e === 'string') {
        message.error(handleError(e, true, true), 10)
      } else {
        message.error(handleError(e, true), 10)
      }
    }

    setLoading(false)
    setCompleting(false)
  }

  // const refreshView = async () => {
  //   let tmpData = null
  //   setLoading(true)
  //   try {
  //     tmpData = await readBookings({ _id: bookingData._id })
  //     if (tmpData[0].status !== bookingData.status) {
  //       let newRecord = {
  //         ...bookingData,
  //         ...tmpData[0],
  //         examinationData: { ...bookingData.examinationData, ...tmpData[0].examinationData }
  //       }
  //       setBookingData(newRecord)
  //       updateAllBookingsState(newRecord)
  //     }
  //   } catch (e) {
  //     message.error(handleError(e, true))
  //   }
  //   setLoading(false)
  // }

  return (
    <ContainerCard
      bodyStyle={{ paddingTop: 0 }}
      title={
        isPreviousBooking
          ? 'Previous Booking: ' + dayjs(data.bookingDate).format('DD-MM-YYYY')
          : getMainTitle(bookingData.status)
      }
    >
      <div
        style={{
          boxShadow: '0px 0px 10px 0px #ccc',
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          marginBottom: 12,
          padding: 8
        }}
      >
        <Space>
          <p>Appointment</p>
          <FontAwesomeIcon icon={faChevronRight} />
          <p>
            {bookingData.patientRecord.firstName} {bookingData.patientRecord.lastName}
          </p>
          <FontAwesomeIcon icon={faChevronRight} />
          <p>
            {dayjs(bookingData.bookingDate).format('DD MMMM YYYY')} - {bookingData.startTime}
          </p>
        </Space>
        <FontAwesomeIcon
          onClick={() => closeTab()}
          style={{ cursor: 'pointer' }}
          icon={faXmarkCircle}
          fontSize={26}
          color={token.colorError}
        />
      </div>
      <CustomRow justify='center'>
        <Col span={24}>
          {bookingData.isVirtualVisit && !joined && authState?.agiliteUser?.extraData?.profession === 'doctor' ? (
            <CustomRow justify='center' style={{ marginTop: 20 }}>
              <Col>
                <Popconfirm
                  title='Confirmation'
                  description='Are you sure you want to start the Virtual Consultation?'
                  onConfirm={() => startVirtualConsult()}
                  okText='Yes'
                  cancelText='No'
                  okButtonProps={{ type: 'primary', style: { backgroundColor: token.colorSuccess } }}
                  cancelButtonProps={{ danger: true }}
                  disabled={loading}
                >
                  <Button disabled={loading} type='primary' style={{ height: 50 }}>
                    <Space>
                      <FontAwesomeIcon icon={faVideoCamera} /> Start Virtual Consultation
                    </Space>
                  </Button>
                </Popconfirm>
              </Col>
            </CustomRow>
          ) : undefined}
          {joined ? (
            <div
              style={{
                height: videoMinimized ? 0 : 500,
                width: '100%',
                borderRadius: '20px 20px 0 0',
                overflow: 'hidden',
                marginTop: 24
              }}
              id='video-container'
            >
              <div
                style={{
                  height: 500,
                  zIndex: 100,
                  width: videoBoxWidth
                }}
                id='video-box'
              >
                <VirtualVisit
                  handleVideoMinimized={handleVideoMinimized}
                  uid={agoraUid}
                  token={agoraToken}
                  channel={agoraChannel}
                  patient={false}
                  joined={joined}
                  setJoined={setJoined}
                />
              </div>
            </div>
          ) : undefined}
        </Col>
        <Col span={24}>
          <Tabs
            type='card'
            activeKey={activeTab}
            onChange={(key) => {
              setActiveTab(key)
              if (key === 'billing') {
                handleGetBillingRecords()
              }
            }}
            className='no-margin examination-screens'
          >
            <Tabs.TabPane tab='Patient Profile' key='patientProfile'>
              <PatientInformationCard
                data={bookingData}
                setData={setBookingData}
                previousDataLoading={previousDataLoading}
                previousData={previousLatestBooking}
              />

              {isNurse() || isDoctor() ? (
                <CustomRow justify='center' style={{ background: '#ffffff' }}>
                  <Col span={24}>
                    {/* <Modal
                  closable={false}
                  maskClosable={false}
                  footer={false}
                  width={750}
                  open={!summary}
                  onCancel={() => setSummary(true)}
                >
                  <NurseScreeningMedicalHistory
                    key='medhistAlwaysShow'
                    setActiveTab={setActiveTab}
                    summary={false}
                    isPreviousBooking={isPreviousBooking}
                    setSummary={setSummary}
                    editButton={summary}
                    setHasConfirmedMedicalHistory={setHasConfirmedMedicalHistory}
                    userRef={data.userRef}
                  />
                </Modal> */}
                    <NurseScreeningMedicalHistory
                      setActiveTab={setActiveTab}
                      isPreviousBooking={isPreviousBooking}
                      setHasConfirmedMedicalHistory={setHasConfirmedMedicalHistory}
                      userRef={data.userRef}
                    />
                  </Col>
                </CustomRow>
              ) : undefined}
            </Tabs.TabPane>
            <>
              {/* {isNurse() || isDoctor() ? (
                <Tabs.TabPane tab='Medical History' key='medicalHistory'>
                  <CustomRow justify='center' style={{ background: '#ffffff', paddingTop: 24, paddingBottom: 24 }}>
                    <Col
                      xs={24}
                      sm={24}
                      md={summary ? 24 : 20}
                      lg={summary ? 24 : 16}
                      xl={summary ? 24 : 14}
                      xxl={summary ? 24 : 12}
                    >
                      <NurseScreeningMedicalHistory
                        setActiveTab={setActiveTab}
                        summary={summary}
                        isPreviousBooking={isPreviousBooking}
                        setSummary={setSummary}
                        editButton={summary}
                        setHasConfirmedMedicalHistory={setHasConfirmedMedicalHistory}
                        userRef={data.userRef}
                      />
                    </Col>
                  </CustomRow>
                </Tabs.TabPane>
              ) : undefined} */}
              {/* {isNurse() ||
              (bookingData.status === CoreEnums.bookingStatuses.data_capture &&
                isReception() &&
                bookingData.serviceRecord.type === ServicesEnums.labels.VITALITY_HEALTH_CHECK) ? (
                <Tabs.TabPane
                  tab={bookingData.status === CoreEnums.bookingStatuses.procedure ? 'Procedure' : 'Physical Exam'}
                  key='checkin'
                >
                  <CustomRow style={{ background: '#ffffff', paddingTop: 24, paddingBottom: 24 }}>
                    <Col span={24}>
                      <PatientCheckin
                        saving={saving}
                        handleSave={handleSave}
                        testing={'worked'}
                        is18={handleCalculateAge(data) >= 18}
                        billingItems={billingItems}
                        setBillingItems={setBillingItems}
                        checkinForm={checkinForm}
                        data={{
                          ...bookingData,
                          proceduresPerformedFiles: bookingData.proceduresPerformedFiles
                            ? bookingData.proceduresPerformedFiles
                            : {}
                        }}
                        closeTab={closeTab}
                        isPreviousBooking={isPreviousBooking}
                        setData={setBookingData}
                        reportDiagnosis={reportDiagnosis}
                      />
                    </Col>
                  </CustomRow>
                </Tabs.TabPane>
              ) : undefined} */}
            </>
            {isNurse() || isDoctor() ? (
              <>
                {/* <Tabs.TabPane tab="Patient's Analytics" key='patientAnalytics'>
                  <CustomRow style={{ background: '#ffffff', paddingTop: 24, paddingBottom: 24 }}>
                    <Col span={24}>
                      <PatientAnalytics userRef={bookingData.userRef} gender={bookingData.patientRecord.gender} />
                    </Col>
                  </CustomRow>
                </Tabs.TabPane>{' '} */}
                <Tabs.TabPane tab='Assessment' key='diagnose'>
                  <CustomRow style={{ background: '#ffffff', paddingTop: 24, paddingBottom: 24 }}>
                    <Col span={24}>
                      <ExaminationWrapper bookingData={data} />
                    </Col>
                  </CustomRow>
                </Tabs.TabPane>
                <Tabs.TabPane tab='Clinical Notes' key='clinicalNotes'>
                  <CustomRow style={{ background: '#ffffff', paddingTop: 24, paddingBottom: 24 }}>
                    <Col span={24}>
                      <ClinicalNotes bookingData={data} />
                    </Col>
                  </CustomRow>
                </Tabs.TabPane>
                <Tabs.TabPane tab='Clinical Reporting' key='clinicalReporting'>
                  <CustomRow style={{ background: '#ffffff', paddingTop: 24, paddingBottom: 24 }}>
                    <Col span={24}>
                      <ClinicalReporting bookingData={data} />
                    </Col>
                  </CustomRow>
                </Tabs.TabPane>
              </>
            ) : null}
            {isDoctor() ? (
              <Tabs.TabPane tab='Scripting' key='scripts'>
                <CustomRow style={{ background: '#ffffff', paddingTop: 24, paddingBottom: 24 }}>
                  <Col span={24}>
                    <ScriptsWrapper patientId={bookingData.userRef} bookingRef={bookingData._id} />
                  </Col>
                </CustomRow>
              </Tabs.TabPane>
            ) : undefined}

            <Tabs.TabPane tab='Billing' key='billing'>
              <CustomRow justify='center' style={{ background: '#ffffff', paddingTop: 24, paddingBottom: 24 }}>
                <Col span={24}>
                  <Billing
                    isReception={authState.agiliteUser.extraData.role.type === 'reception'}
                    billing={billing}
                    billingItems={billingItems}
                    setBillingItems={setBillingItems}
                    bookingData={bookingData}
                    setBookingData={setBookingData}
                    handleGetBillingRecords={handleGetBillingRecords}
                    billingRecordsLoading={billingRecordsLoading}
                    setBillingSyncRequired={setBillingSyncRequired}
                    billingWebSocket={billingWebSocket}
                  />
                </Col>
              </CustomRow>
            </Tabs.TabPane>
            <Tabs.TabPane tab='File Archive' key='archive'>
              <CustomRow justify='center' style={{ background: '#ffffff', paddingTop: 24, paddingBottom: 24 }}>
                <Col span={24}>
                  <FileArchive bookingData={data} />
                </Col>
              </CustomRow>
            </Tabs.TabPane>
            {(isDoctor() || isAdmin()) && !isPreviousBooking ? (
              <Tabs.TabPane tab='Previous Bookings' key='previousBookings'>
                <CustomRow style={{ background: '#ffffff' }}>
                  <Col span={24}>
                    <EmployeeBookingsView userRef={bookingData.userRef} />
                  </Col>
                </CustomRow>
              </Tabs.TabPane>
            ) : null}
            <Tabs.TabPane tab='Patient Journey' key='patientJourney'>
              <CustomRow style={{ background: '#ffffff', paddingTop: 24, paddingBottom: 24 }}>
                <Col span={24}>
                  <PatientJourneyList workflowHistory={workflowHistory} />
                </Col>
              </CustomRow>
            </Tabs.TabPane>
          </Tabs>
        </Col>
        <Col span={24} style={{ marginBottom: 12 }}>
          <PatientExaminationActionsCard
            loading={loading}
            closeTab={closeTab}
            finalizeBilling={finalizeBilling}
            saving={saving}
            completing={completing}
            data={bookingData}
            noAppointment={noAppointment}
            handleSave={handleSave}
            doctorVisitRequired={
              bookingData.consultationRequired && authState.agiliteUser.extraData.profession === 'nurse'
            }
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            isPreviousBooking={isPreviousBooking}
            syncRequired={billingSyncRequired}
          />
        </Col>
      </CustomRow>
      {videoMinimized ? (
        <div
          onClick={() => {
            handleVideoMinimized()
          }}
          style={{
            position: 'fixed',
            right: 50,
            bottom: 50,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 75,
            width: 75,
            borderRadius: '50%',
            background: token.colorPrimary,
            cursor: 'pointer'
          }}
        >
          <FontAwesomeIcon icon={faVideoCamera} style={{ fontSize: 30, color: '#fff' }} />
        </div>
      ) : null}
      {modalOpen ? (
        <NurseToDoctor
          setData={setBookingData}
          data={bookingData}
          loading={loading}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          handleSave={handleSave}
        />
      ) : undefined}
      <Modal
        open={printingReport}
        maskClosable={false}
        closable={false}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <CustomRow>
          <Col span={24}>
            <AgiliteSkeleton skActive spinnerTip='Printing report...' />
          </Col>
        </CustomRow>
      </Modal>
    </ContainerCard>
  )
}

export default PatientExaminationForm
